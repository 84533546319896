// https://github.com/typescript-eslint/typescript-eslint/issues/2447

import { ISelectOption } from "lobbie";

// eslint-disable-next-line
export enum ENotificationType {
    STAFF_WELCOME = 0,
    PATIENT_WELCOME = 1,
    APPOINTMENT_CONFIRMATION = 2,
    APPOINTMENT_CHECKIN = 3,
    APPOINTMENT_RESCHEDULE = 4,
    APPOINTMENT_SCHEDULE_DATE = 5,
    APPOINTMENT_SCHEDULE_TIME = 6,
    APPOINTMENT_ROOM_READY = 7,
    PASSWORD_RESET = 8,
    APPOINTMENT_SCHEDULED = 9,
    APPOINTMENT_WAIT_APOLOGY = 10,
    SMS_CONFIRM_CODE = 11,
    EMAIL_CONFIRM_CODE = 12,
    APPOINTMENT_FORM_ADDED = 13,
    APPOINTMENT_OAM_OFFER = 14,
    APPOINTMENT_TELEHEALTH_LINK = 15,
    FORMS_ASSIGNMENT = 16,
    FORMS_NOTIFY_STAFF_ON_COMPLETE = 17,
    FORMS_ADDED_NO_APPOINTMENT = 18,
    SUPPORT_EMAIL = 19,
    APPOINTMENT_NOTIFY_STAFF_APPOINTMENT_SCHEDULED_VIA_SELF_SCHEDULING = 20,
    APPOINTMENT_CANCELED = 21,
    NPS_SURVEY = 22,
    DO_NOT_SEND = 23,
}

// eslint-disable-next-line
export enum ENotificationSendMethod {
    Email = 0,
    Sms = 1,
    EmailAndSms = 2,
    Paper = 3,
}

export const NOTIFICATION_SEND_METHOD_DESCRIPTIONS = {
    [ENotificationSendMethod.Email]: "Email",
    [ENotificationSendMethod.Sms]: "SMS Text Message",
    [ENotificationSendMethod.EmailAndSms]: "Email and SMS Text Message",
    [ENotificationSendMethod.Paper]: "Printed Copy",
};

export const NOTIFICATION_SEND_METHODS = [
    ENotificationSendMethod.Email,
    ENotificationSendMethod.Sms,
    ENotificationSendMethod.EmailAndSms,
    ENotificationSendMethod.Paper,
];

export const SEND_METHOD_OPTIONS = [
    {
        label: "Email and SMS/Text Message",
        value: ENotificationSendMethod.EmailAndSms,
    },
    {
        label: "Email Only",
        value: ENotificationSendMethod.Email,
    },
    {
        label: "Text Message Only",
        value: ENotificationSendMethod.Sms,
    },
] as ISelectOption[];

export const EMAIL_OPTIONS = [ENotificationSendMethod.Email, ENotificationSendMethod.EmailAndSms];
export const SMS_OPTIONS = [ENotificationSendMethod.Sms, ENotificationSendMethod.EmailAndSms];

export const isSendMethodEmail = (method: ENotificationSendMethod) => {
    return EMAIL_OPTIONS.includes(method);
};
export const isSendMethodSMS = (method: ENotificationSendMethod) => {
    return SMS_OPTIONS.includes(method);
};
