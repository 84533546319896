import { OpUnitType, QUnitType } from "dayjs";
import { ISelectOption } from "lobbie";

// const IS_DAYLIGHT_SAVINGS_TIME_IN_BROWSER: boolean = (() => {
//     const now = new Date();
//     const jan = new Date(now.getFullYear(), 0, 1);
//     const jul = new Date(now.getFullYear(), 6, 1);
//     const stdTimezoneOffset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
//     return now.getTimezoneOffset() < stdTimezoneOffset;
// })();

// https://stackoverflow.com/questions/6939685/get-client-time-zone-from-browser
export const BROWSER_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// https://stackoverflow.com/a/66180857/6410635
export const BROWSER_TIME_ZONE_SHORT = new Intl.DateTimeFormat(undefined, { timeZoneName: "short" })
    ?.formatToParts(new Date())
    ?.find((part) => part.type === "timeZoneName")?.value;
// export const BROWSER_TIME_ZONE_SHORT = dayjs().format("z")

export const MONTH_DAY_YEAR_FORMAT = "MM/DD/YYYY";
export const ANOTHER_MONTH_DAY_YEAR_FORMAT = "MMMM Do YYYY";
export const APPOINTMENT_DATE_FORMAT = MONTH_DAY_YEAR_FORMAT;
export const APPOINTMENT_TIME_FORMAT = "h:mma";
export const MONTH_DAY_YEAR_TIME_FORMAT = "MM/DD/YYYY HH:mm";

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const DEPRECATED_TO_TIMEZONES = {
    "us/alaska": "America/Anchorage",
    "us/aleutian": "America/Adak",
    "us/arizona": "America/Phoenix",
    "us/central": "America/Chicago",
    "us/east-indiana": "America/Indiana/Indianapolis",
    "us/eastern": "America/New_York",
    "us/hawaii": "Pacific/Honolulu",
    "us/indiana-Starke": "Indiana/Knox",
    "us/michigan": "America/Detroit",
    "us/mountain": "America/Denver",
    "us/pacific": "America/Los_Angeles",
    "us/samoa": "Pacific/Pago_Pago",
    universal: "Etc/UTC",
};

// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const TIMEZONES_TO_DEPRECATED = {
    "america/anchorage": "US/Alaska",
    "america/adak": "US/Aleutian",
    "america/phoenix": "US/Arizona",
    "america/chicago": "US/Central",
    "america/indiana/indianapolis": "US/East-Indiana",
    "america/new_york": "US/Eastern",
    "pacific/honolulu": "US/Hawaii",
    "indiana/knox": "US/Indiana-Starke",
    "america/detroit": "US/Michigan",
    "america/denver": "US/Mountain",
    "america/los_angeles": "US/Pacific",
    "pacific/pago_pago": "US/Samoa",
    "etc/utc": "Universal",
};

export const DATE_TIME_FORMATS: {
    Date: "MM/DD/YYYY";
    MonthDayYear: "MM/DD/YYYY";
    DateTimeAMPM: "MM/DD/YYYY - h:mm A";
    HourMinutesLowerCaseAMPMCondensed: "h:mma";

    ShortMonthShortDayLongYear: "MMM D, YYYY";
    ShortMonthShortDayLongYearTimeAMPM: "MMM D, YYYY - h:mm A";

    Scheduler: {
        ScheduleDateFormat: "M/D/YYYY";
        BlockDateTimeFormat: "MM/DD/YYYY - hh:mma";
        BlockTime12HFormat: "hh : mm";
        BlockTime12HFormatZoned: "hh : mm z";
        BlockTime12HFormatCondensed: "hh:mm";
        BlockTime12HFormatCondensedAMPM: "hh:mm A z";
        BlockTime12HFormatCondensedShortAMPM: "hh:mmA";
        BlockDateTime12HFormatCondensed: "M/D/YYYY hh:mm A";
        BlockDateTime12HFormatCondensedAMPM: "M/D/YYYY hh:mm A";
        BlockDateTime12HFormatCondensedAMPMFullYear: "MM/DD/YYYY hh:mm A";
        BlockDateTime24HFormatCondensedAMPM: "M-D-YYYY kk-mm";
        BlockDateTime12HFormatCondensedAMPMOffset: "M/D/YYYY hh:mm A Z";
        BlockTime24HFormat: "kk : mm";
        CalendarHeader: "MMMM  YYYY";
        ModalDateFormat: "MMMM DD";
        ModalStartEndFormat: "h:mm A z";
        Offset: "z";
    };
    Appointment: {
        DateFormat: "MM/DD/YYYY";
        DateSpacedFormat: "MM / DD / YYYY";
        DateFormatShortYear: "MM/DD/YYYY";
        DateFormatShortDayMonthYear: "M/D/YYYY";
        DateFormat2: "MMMM Do YYYY";
        DateFormat2Timezone: "MMMM Do YYYY, hh:mm A z";
        DateFormat2NoYear: "MMMM Do";
        TimeFormat: "h:mma z";
        TimeFormatNoZone: "h:mma";
        TimeFormatUpper: "h:mm A z";
        PatientHomeDateMonth: "MMM";
        PatientHomeDateDay: "D";
        PatientHomeTime: "h:mm A z";
        DateFormat3: "MMM. DD. YYYY";
    };
    EVV: {
        DateFormat: "MMMM DD, YYYY";
        DateFormatShortMonthDay: "MMM DD";
        //DateFormat2 is used to match IAppointment.appointmentDate format.
        DateFormat2: "M/D/YYYY";
        DateFormat3: "YYYYMMDD";
        DateFormat4: "MM / D / YYYY";
        DateFormat5: "MMMM Do, YYYY";
        TimeFormat: "h:mm a";
        TimeFormat2: "h:mm A";
        TimeFormat3: "HHmmss";
        DateTimeFormat: "MMM DD, h:mmA";
        DateYearTimeFormat: "MMMM Do YYYY, hh:mm A";
    };
    Analytics: {
        DateHeaderFormat: "MMM Do";
        DateXLabelFormat: "MMM D";
    };
} = {
    Date: "MM/DD/YYYY",
    MonthDayYear: "MM/DD/YYYY",
    DateTimeAMPM: "MM/DD/YYYY - h:mm A",
    HourMinutesLowerCaseAMPMCondensed: "h:mma",

    ShortMonthShortDayLongYear: "MMM D, YYYY",
    ShortMonthShortDayLongYearTimeAMPM: "MMM D, YYYY - h:mm A",

    Scheduler: {
        ScheduleDateFormat: "M/D/YYYY",
        BlockDateTimeFormat: "MM/DD/YYYY - hh:mma",
        BlockTime12HFormat: "hh : mm",
        BlockTime12HFormatZoned: "hh : mm z",
        BlockTime12HFormatCondensed: "hh:mm",
        BlockTime12HFormatCondensedAMPM: "hh:mm A z",
        BlockTime12HFormatCondensedShortAMPM: "hh:mmA",
        BlockDateTime12HFormatCondensed: "M/D/YYYY hh:mm A",
        BlockDateTime12HFormatCondensedAMPM: "M/D/YYYY hh:mm A",
        BlockDateTime12HFormatCondensedAMPMFullYear: "MM/DD/YYYY hh:mm A",
        BlockDateTime24HFormatCondensedAMPM: "M-D-YYYY kk-mm",
        BlockDateTime12HFormatCondensedAMPMOffset: "M/D/YYYY hh:mm A Z",
        BlockTime24HFormat: "kk : mm",
        CalendarHeader: "MMMM  YYYY",
        ModalDateFormat: "MMMM DD",
        ModalStartEndFormat: "h:mm A z",
        Offset: "z",
    },
    Appointment: {
        DateFormat: "MM/DD/YYYY",
        DateSpacedFormat: "MM / DD / YYYY",
        DateFormatShortYear: "MM/DD/YYYY",
        DateFormatShortDayMonthYear: "M/D/YYYY",
        DateFormat2: "MMMM Do YYYY",
        DateFormat2Timezone: "MMMM Do YYYY, hh:mm A z",
        DateFormat2NoYear: "MMMM Do",
        TimeFormat: "h:mma z",
        TimeFormatNoZone: "h:mma",
        TimeFormatUpper: "h:mm A z",
        PatientHomeDateMonth: "MMM",
        PatientHomeDateDay: "D",
        PatientHomeTime: "h:mm A z",
        DateFormat3: "MMM. DD. YYYY",
    },
    EVV: {
        DateFormat: "MMMM DD, YYYY",
        DateFormatShortMonthDay: "MMM DD",
        //DateFormat2 is used to match IAppointment.appointmentDate format.
        DateFormat2: "M/D/YYYY",
        DateFormat3: "YYYYMMDD",
        DateFormat4: "MM / D / YYYY",
        DateFormat5: "MMMM Do, YYYY",
        TimeFormat: "h:mm a",
        TimeFormat2: "h:mm A",
        TimeFormat3: "HHmmss",
        DateTimeFormat: "MMM DD, h:mmA",
        DateYearTimeFormat: "MMMM Do YYYY, hh:mm A",
    },
    Analytics: {
        DateHeaderFormat: "MMM Do",
        DateXLabelFormat: "MMM D",
    },
};

// https://github.com/typescript-eslint/typescript-eslint/issues/2447
// eslint-disable-next-line
export enum EDateRange {
    Week,
    Month,
    Quarter,
    Year,
}

export const DATE_UNITS: { [key: string]: OpUnitType | QUnitType } = {
    day: "day" as OpUnitType,
    week: "week" as OpUnitType,
    month: "month" as OpUnitType,
    quarter: "quarter" as QUnitType,
    year: "year" as OpUnitType,
};

export const AM_PM_SELECT_OPTION = (amPM: "AM" | "PM"): ISelectOption => ({
    value: amPM,
    label: amPM,
});

export type TDay =
    | "sunday"
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday";

// eslint-disable-next-line
export enum EDays {
    SUNDAY = "sunday",
    MONDAY = "monday",
    TUESDAY = "tuesday",
    WEDNESDAY = "wednesday",
    THURSDAY = "thursday",
    FRIDAY = "friday",
    SATURDAY = "saturday",
}

export const DAYS: TDay[] = [
    EDays.SUNDAY,
    EDays.MONDAY,
    EDays.TUESDAY,
    EDays.WEDNESDAY,
    EDays.THURSDAY,
    EDays.FRIDAY,
    EDays.SATURDAY,
];
