/** @format */

import { createReducer } from "@reduxjs/toolkit";
import {
    setLocationId,
    setLocations,
    setLocation,
    setMultiMultiLocations,
} from "../actions/locationActions";
import { IAppState } from "../store";

const initialState: any = {
    locationId: undefined,
};

export const locationReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setLocations, (state: IAppState, action) => {
            return {
                ...state,
                locations: action?.payload,
            };
        })
        .addCase(setLocation, (state: IAppState, action) => {
            return {
                ...state,
                location: action.payload,
            };
        })
        .addCase(setLocationId, (state: IAppState, action) => {
            return {
                ...state,
                locationId: action?.payload,
            };
        })
        .addCase(setMultiMultiLocations, (state: IAppState, action) => {
            return {
                ...state,
                multiLocations: action?.payload,
            };
        });
});
