import { useMemo } from "react";
import LobbieSpinner from "./LobbieSpinner";

const STYLE = {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "0 auto",
} as const;

const CenteredFullScreenLoadingSpinner = ({
    message,
    style,
}: {
    message?: string;
    style?: React.CSSProperties;
}) => {
    const _style = useMemo(
        () => ({
            ...STYLE,
            ...style,
        }),
        [style],
    );
    return (
        <div style={_style}>
            {message && <span className="blue">{message}</span>}
            <LobbieSpinner />
        </div>
    );
};

export default CenteredFullScreenLoadingSpinner;
