import axios, { AxiosError, AxiosResponse } from "axios";
import { IValidationResult } from "lobbie";
import ls from "localstorage-slim";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    ACCOUNT_PUBLIC_UUID,
    API_PREFIX,
    BASE_API_URL,
    BASE_AUTH_API_ROUTE,
    BASE_PUBLIC_API_ROUTE,
    BASE_SECURE_ROUTE,
    DEFAULT_ERROR_MESSAGE,
    LOBBIE_STORAGE,
} from "src/constants";
import { useCancellable } from "src/hooks/useCancellable";
import {
    HTTP_STATUS_CODES,
    handleError,
    handleHttpError,
    isEmptyObject,
    isFailedRequest,
    logDev,
    notify,
    withLobbieRefresh,
} from "src/utils";
import { useLobbieAccountPublicUuid } from "./accounts/accounts";
import { useIsSuperAdmin } from "./user";

// TODO: Add secure axios DELETE hooks

/*
 * ERROR HANDLING
 */

const handleAxiosError = (ex: AxiosError | Error) => {
    if (ex instanceof AxiosError) {
        if (ex?.response?.status === HTTP_STATUS_CODES.BAD_REQUEST && ex.response.statusText) {
            // 400, LobbieInvalidRequestException
            notify({
                level: "error",
                title: "Error using Lobbie",
                message: ex.response.statusText,
            });
        } else if (ex.response?.status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
            // 406, thrown by RecaptchaUtil.java
            console.error(ex);
            notify({
                level: "error",
                title: "Error confirming a recaptcha from your browser.",
                message: "Please try again. You may need to refresh the page.",
            });
        } else {
            handleHttpError(ex);
        }
    } else {
        console.error(ex);
    }
};

/*
 *
 * SECURE/SESSION METHODS
 *
 */

export const useAxiosGetter = (): TQueryRequest => {
    const isSuperAdmin = useIsSuperAdmin();
    const superAdminGet = useSuperAdminAxiosGet();
    const secureAxiosGet = useAxiosSecureGet();
    return useMemo(() => {
        if (isSuperAdmin) {
            return superAdminGet;
        } else {
            return secureAxiosGet;
        }
    }, [isSuperAdmin, superAdminGet, secureAxiosGet]);
};

export const useAxiosGet = <T>(
    route: string,
    options?: {
        notifyOnValidationResultFailure?: boolean;
        skipInitialRequest?: boolean;
        defaultValue?: T;
    },
) => {
    const getter = useAxiosGetter();
    const [items, setItems] = useState<T | undefined>(options?.defaultValue);
    const [isLoading, setLoading] = useState<boolean>(false);

    const get = useCallback(
        async (opts?: { route?: string; params?: Record<string, string | number | boolean> }) => {
            if (!route || route.includes("undefined")) {
                if (options?.defaultValue) {
                    setItems(options.defaultValue);
                }
                return options?.defaultValue;
            }

            setLoading(true);

            const endpoint = opts?.route || route;
            const params = opts?.params;
            const r = params
                ? `${endpoint}?${Object.keys(params)
                      .map((k) => `${k}=${params[k]}`)
                      .join("&")}`
                : endpoint;

            return getter(r)
                .then((response: AxiosResponse | void) => {
                    // 503 responses when backend is shutting down and db session is null or closed.
                    if (response && response.status === 503) {
                        return new Promise((resolve) => {
                            window.setTimeout(() => {
                                resolve(get({ route }));
                            }, 100);
                        });
                    }

                    setLoading(false);
                    const result = response && (response.data as T | IValidationResult);
                    if (!result) {
                        if (options?.defaultValue) {
                            setItems(options.defaultValue);
                        }
                        return options?.defaultValue;
                    } else if (isFailedRequest(result)) {
                        if (options?.notifyOnValidationResultFailure) {
                            notify({
                                level: "warning",
                                title: "Request failed.",
                                message:
                                    (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                            });
                        }
                        if (options?.defaultValue) {
                            setItems(options.defaultValue);
                        }
                        return options?.defaultValue || result;
                    } else {
                        setItems(result as T);
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                    if (options?.defaultValue) {
                        setItems(options.defaultValue);
                    }
                    return options?.defaultValue;
                });
        },
        [getter, route, options?.notifyOnValidationResultFailure, options?.defaultValue],
    );

    useEffect(() => {
        if (!options?.skipInitialRequest) {
            get().catch(console.error);
        }
    }, [get, options?.skipInitialRequest]);

    return { items, setItems, isLoading, setLoading, get };
};

export const useAxiosGetPublic = <T>(
    route: string,
    options?: { notifyOnValidationResultFailure?: boolean; skipInitialRequest?: boolean },
) => {
    const getter = useAxiosPublicGet();
    const [items, setItems] = useState<T | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const get = useCallback(
        async (opts?: { route?: string; params?: Record<string, string | number | boolean> }) => {
            if (!route || route.includes("undefined")) return;

            setLoading(true);

            const endpoint = opts?.route || route;
            const params = opts?.params;
            const r = params
                ? `${endpoint}?${Object.keys(params)
                      .map((k) => `${k}=${params[k]}`)
                      .join("&")}`
                : endpoint;
            return getter(r)
                .then((response: AxiosResponse | void) => {
                    // 503 responses when backend is shutting down and db session is null or closed.
                    if (response && response.status === 503) {
                        return new Promise((resolve) => {
                            window.setTimeout(() => {
                                resolve(get({ route }));
                            }, 100);
                        });
                    }

                    setLoading(false);
                    const result = response && (response.data as T | IValidationResult);
                    if (!result) {
                        return;
                    } else if (isFailedRequest(result)) {
                        if (options?.notifyOnValidationResultFailure) {
                            notify({
                                level: "warning",
                                title: "Request failed.",
                                message:
                                    (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                            });
                        } else {
                            return result;
                        }
                    } else {
                        setItems(result as T);
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [getter, route, options?.notifyOnValidationResultFailure],
    );

    useEffect(() => {
        if (!options?.skipInitialRequest) {
            get().catch(console.error);
        }
    }, [get, options?.skipInitialRequest]);

    return { items, setItems, isLoading, setLoading, get };
};

export const useAxiosGetBlobber = (): TQueryRequest => {
    const isSuperAdmin = useIsSuperAdmin();
    const superAdminGetBlob = useSuperAdminAxiosGetBlob();
    const secureGetBlob = useAxiosSecureGetBlob();
    return useMemo(() => {
        if (isSuperAdmin) {
            return superAdminGetBlob;
        } else {
            return secureGetBlob;
        }
    }, [isSuperAdmin, superAdminGetBlob, secureGetBlob]);
};

export const useAxiosPoster = () => {
    const isSuperAdmin = useIsSuperAdmin();
    const superAdminPost = useSuperAdminAxiosPost();
    const secureAxiosPost = useAxiosSecurePost();
    return useMemo(() => {
        if (isSuperAdmin) {
            return superAdminPost;
        } else {
            return secureAxiosPost;
        }
    }, [isSuperAdmin, superAdminPost, secureAxiosPost]);
};

export const useAxiosPost = <T extends Record<string, any>>(
    route: string,
    notifyOnValidationResultFailure?: boolean,
) => {
    const poster = useAxiosPoster();
    const [isLoading, setLoading] = useState<boolean>(false);

    const post = useCallback(
        async (data: Record<string, any> | undefined | null) => {
            if (!route || route.includes("undefined") || !data) {
                return;
            }

            setLoading(true);

            return poster(route, data)
                .then((response: AxiosResponse | void) => {
                    // 503 responses when backend is shutting down and db session is null or closed.
                    if (response && response.status === 503) {
                        return new Promise((resolve) => {
                            window.setTimeout(() => {
                                resolve(post(data));
                            }, 100);
                        });
                    }

                    setLoading(false);
                    const result = response && (response.data as T | IValidationResult);
                    if (!result) {
                        return;
                    } else if (isFailedRequest(result)) {
                        if (notifyOnValidationResultFailure) {
                            notify({
                                level: "warning",
                                title: "Request failed.",
                                message:
                                    (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                            });
                        } else {
                            return result;
                        }
                    } else {
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [poster, route, notifyOnValidationResultFailure],
    );

    return { isLoading, setLoading, post };
};

export const useAxiosSecureBlobPoster = () => {
    const isSuperAdmin = useIsSuperAdmin();
    const superAdminPost = useSuperAdminAxiosPostBlob();
    const secureAxiosPost = useAxiosSecurePostBlob();
    return useMemo(() => {
        if (isSuperAdmin) {
            return superAdminPost;
        } else {
            return secureAxiosPost;
        }
    }, [isSuperAdmin, superAdminPost, secureAxiosPost]);
};

export const useAxiosPutter = () => {
    const isSuperAdmin = useIsSuperAdmin();
    const superAdminPut = useSuperAdminAxiosPut();
    const secureAxiosPut = useAxiosSecurePut();
    return useMemo(() => {
        if (isSuperAdmin) {
            return superAdminPut;
        } else {
            return secureAxiosPut;
        }
    }, [isSuperAdmin, superAdminPut, secureAxiosPut]);
};

export const useAxiosPut = <T extends Record<string, any>>(
    route: string,
    notifyOnValidationResultFailure?: boolean,
) => {
    const putter = useAxiosPutter();
    const [isLoading, setLoading] = useState<boolean>(false);

    const put = useCallback(
        async (data: Record<string, any> | undefined | null) => {
            if (!route || route.includes("undefined") || !data) return;

            setLoading(true);

            return putter(route, data)
                .then((response: AxiosResponse | void) => {
                    // 503 responses when backend is shutting down and db session is null or closed.
                    if (response && response.status === 503) {
                        return new Promise((resolve) => {
                            window.setTimeout(() => {
                                resolve(put(data));
                            }, 100);
                        });
                    }

                    setLoading(false);
                    const result = response && (response.data as T | IValidationResult);
                    if (!result) {
                        return;
                    } else if (isFailedRequest(result)) {
                        if (notifyOnValidationResultFailure) {
                            notify({
                                level: "warning",
                                title: "Request failed.",
                                message:
                                    (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                            });
                        }
                    } else {
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [putter, route, notifyOnValidationResultFailure],
    );

    return { isLoading, setLoading, put };
};

type TPayload = Record<number, any> | Record<string, any> | FormData;

type TQueryRequest = (
    route: string,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>;

type TBodyRequest = (
    route: string,
    data: TPayload | null,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>;

export const useAxiosSecureGet = (): TQueryRequest => {
    const method = useAxiosSecureRequest("get") as TBodyRequest;

    const options = useMemo(() => ({}), []);
    return useCallback(
        (route: string, errorHandler?: (error: AxiosError) => void) => {
            return method(route, options, errorHandler);
        },
        [method, options],
    ) as TQueryRequest;
};

export const useAxiosSecureGetBlob = (): TQueryRequest => {
    const method = useAxiosSecureRequest("get", { responseType: "blob" }) as TBodyRequest;

    const options = useMemo(() => ({}), []);
    return useCallback(
        (route: string, errorHandler?: (error: AxiosError) => void) => {
            return method(route, options, errorHandler);
        },
        [method, options],
    ) as TQueryRequest;
};

export const useAxiosSecurePost = (): TBodyRequest => {
    const options = useMemo(() => ({}), []);
    return useAxiosSecureRequest("post", options) as TBodyRequest;
};

export const useAxiosSecurePostBlob = (): TBodyRequest => {
    const options = useMemo(() => ({ responseType: "blob" }), []);
    return useAxiosSecureRequest("post", options) as TBodyRequest;
};

export const useAxiosSecurePut = (): TBodyRequest => {
    const options = useMemo(() => ({}), []);
    return useAxiosSecureRequest("put", options) as TBodyRequest;
};

const TOKEN_ENDPOINTS = ["v1/forms/patient", "v1/shared/image", "v1/shared/file_uploads"];
/**
 * Used when a user has authenticated with Lobbie and has been granted a session
 *
 * @param method
 * @param options
 * @returns
 */
const useAxiosSecureRequest = (
    method: "put" | "post" | "get", // NOSONAR
    options?: Record<string, string>,
): TQueryRequest | TBodyRequest => {
    // * Forces a page refresh at 3:00 AM each night

    const makeCancellable = useCancellable();
    return useCallback(
        (route_: string, data: TPayload | null, errorHandler?: (error: AxiosError) => void) => {
            withLobbieRefresh();

            const _errorHandler = errorHandler || handleAxiosError;

            //Was randomly replacing spaces in url strings which would effect parameters
            const route = route_; //.replace(/\s/g, "");
            const opts = { withCredentials: true, ...options };

            // * ************************************************************
            // * WARNING: Axios handles the below automatically.
            // * WARNING: ****** IF THIS IS SET THE REQUEST WILL FAIL ******
            // * ************************************************************
            // if (data instanceof FormData) {
            //     (opts as Record<string, any>)["headers"] = { "Content-Type": "Multipart/Form-Data" };
            // }

            const url = (() => {
                if (route.startsWith(BASE_API_URL)) {
                    return route;
                } else {
                    return (
                        `${BASE_API_URL}/${BASE_SECURE_ROUTE}` +
                        (route.startsWith("/") ? route : `/${route}`)
                    );
                }
            })();

            if (TOKEN_ENDPOINTS.some((endpoint) => url.includes(endpoint))) {
                const token = ls.get(LOBBIE_STORAGE.Local.Patient.Forms.Token, {
                    encrypt: true,
                }) as string;
                if (token && !isEmptyObject(token)) {
                    if ("headers" in opts) {
                        (opts.headers as Record<string, string>)["X-Lobbie-Token"] = token;
                    } else {
                        (opts as Record<string, any>)["headers"] = {
                            "X-Lobbie-Token": token,
                        };
                    }
                }
            }

            const request =
                data === null
                    ? () =>
                          axios.request({
                              method,
                              url,
                              signal: AbortSignal.timeout(Number(options?.timeout || 1000 * 120)), // 2 minutes
                              ...opts,
                          })
                    : () =>
                          axios.request({
                              method,
                              url,
                              data,
                              signal: AbortSignal.timeout(Number(options?.timeout || 1000 * 120)), // 2 minutes
                              ...opts,
                          });

            return makeCancellable(request().catch(_errorHandler), () =>
                logDev(`Canceled Axios SECURE ${method.toUpperCase()} to route -`, url),
            );
        },
        [method, options, makeCancellable],
    );
};

/*
 *
 * PUBLIC METHODS
 *
 */

export const useAxiosPublicGet = (): ((
    route: string,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const options = useMemo(() => ({}), []);
    const method = useAxiosPublicRequest("get", options);
    return useCallback(
        (route: string, errorHandler?: (error: AxiosError) => void) => {
            return method(route, options, errorHandler);
        },
        [method, options],
    );
};

export const useAxiosPublicPost = (): ((
    route: string,
    data: TPayload,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const options = useMemo(() => ({}), []);
    return useAxiosPublicRequest("post", options);
};

export const useAxiosPublicPut = (): ((
    route: string,
    data: TPayload,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const options = useMemo(() => ({}), []);
    return useAxiosPublicRequest("put", options);
};

export const useAxiosPublicPostWrapper = <T extends Record<string, any>>(
    route: string,
    notifyOnValidationResultFailure?: boolean,
) => {
    const poster = useAxiosPublicPost();
    const [isLoading, setLoading] = useState<boolean>(false);
    const post = useCallback(
        async (data: Record<string, any> | undefined | null) => {
            if (!route || route.includes("undefined") || !data) return;
            setLoading(true);
            return poster(route, data)
                .then((response: AxiosResponse | void) => {
                    // 503 responses when backend is shutting down and db session is null or closed.
                    if (response && response.status === 503) {
                        return new Promise((resolve) => {
                            window.setTimeout(() => {
                                resolve(post(data));
                            }, 100);
                        });
                    }

                    setLoading(false);
                    const result = response && (response.data as T | IValidationResult);
                    if (!result) {
                        return;
                    } else if (isFailedRequest(result)) {
                        if (notifyOnValidationResultFailure) {
                            notify({
                                level: "warning",
                                title: "Request failed.",
                                message:
                                    (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                            });
                        }
                    } else {
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [poster, route, notifyOnValidationResultFailure],
    );
    return { isLoading, setLoading, post };
};

/**
 * Use when a user has NOT authenticated with Lobbie and been given a session.
 * Calls recaptcha first.
 *
 * @param method
 * @param options
 * @returns
 */
const useAxiosPublicRequest = (
    method: "put" | "post" | "get",
    options: Record<string, string> = {},
): ((
    route: string,
    data: TPayload | null,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const makeCancellable = useCancellable();

    return useCallback(
        async (
            route_: string,
            data: TPayload | null,
            errorHandler?: (error: AxiosError) => void,
        ) => {
            withLobbieRefresh();

            const route = route_.replace(/\s/g, ""); // remove all whitespace
            const opts = {
                withCredentials: true,
                ...options,
            };
            if (data instanceof FormData) {
                (opts as Record<string, any>)["headers"] = {
                    "content-type": "multipart/form-data",
                };
                (opts as Record<string, any>)["timeout"] = 0; // infinite, default?
            }

            if (data === null) {
                data = opts; // eslint-disable-line
            }

            const _errorHandler = errorHandler || handleAxiosError;

            let url = (() => {
                if (
                    route.includes(BASE_AUTH_API_ROUTE) ||
                    route.includes("api/admin") ||
                    route.includes("api/action/") ||
                    route.includes("forgotPassword")
                ) {
                    if (route.startsWith(BASE_API_URL)) {
                        return route;
                    } else {
                        return BASE_API_URL + (route.startsWith("/") ? route : `/${route}`);
                    }
                } else if (
                    route.startsWith(BASE_API_URL) &&
                    route.includes(BASE_PUBLIC_API_ROUTE)
                ) {
                    return route;
                } else if (
                    route.startsWith(BASE_API_URL) &&
                    !route.includes(BASE_PUBLIC_API_ROUTE)
                ) {
                    return route.replace(BASE_API_URL, `${BASE_API_URL}/${BASE_PUBLIC_API_ROUTE}`);
                } else if (
                    !route.startsWith(BASE_API_URL) &&
                    route.includes(BASE_PUBLIC_API_ROUTE)
                ) {
                    return `${BASE_API_URL}/${route.startsWith("/") ? route.slice(1) : route}`;
                } else {
                    return `${BASE_API_URL}/${BASE_PUBLIC_API_ROUTE}${
                        route.startsWith("/") ? route : "/" + route
                    }`;
                }
            })();

            if (url.includes(`/${BASE_PUBLIC_API_ROUTE}/${BASE_PUBLIC_API_ROUTE}/`)) {
                url = url.replace(
                    `/${BASE_PUBLIC_API_ROUTE}/${BASE_PUBLIC_API_ROUTE}/`,
                    BASE_PUBLIC_API_ROUTE,
                );
            }

            // Logout does not use recaptcha on backend
            const isNotRequiresRecaptcha =
                url.split(BASE_API_URL).last() === "/api/authentication/logout";
            // const isNotRequiresRecaptcha =
            //     IS_LOBBIE_LOCAL || url.split(BASE_API_URL).last() === "/api/authentication/logout";

            const recaptchaAction = `${method.toUpperCase()}__${route}`.split("?").first();

            // https://stackoverflow.com/a/9705227/6410635
            const replacer = /[^a-zA-Z0-9/_]/g;

            const sendPublicRequest = (recaptchaToken: string | undefined) => {
                // https://stackoverflow.com/a/47895020/6410635
                // if (url.includes("?")) {
                //     url = `${url}&nocache=${new Date().getTime()}`;
                // } else {
                //     url = `${url}?nocache=${new Date().getTime()}`;
                // }

                if (recaptchaToken) {
                    if (!route.includes("recaptchaToken=")) {
                        if (url.includes("?")) {
                            url = `${url}&recaptchaToken=${recaptchaToken}`;
                        } else {
                            url = `${url}?recaptchaToken=${recaptchaToken}`;
                        }
                    }
                }

                // // https://stackoverflow.com/a/50632912/6410635
                // if (IS_SAFARI) {
                //     url = url.replace("?", "/?");
                // }

                return makeCancellable(
                    axios
                        .request({
                            ...opts,
                            url,
                            method,
                            data: { recaptchaToken, ...data },
                            // headers: {
                            //     "Cache-Control": "no-cache",
                            //     Pragma: "no-cache",
                            //     Expires: "0",
                            // },
                        })
                        .catch(_errorHandler),
                    () => logDev(`Canceled Axios PUBLIC ${method.toUpperCase()} to route -`, url),
                );
            };

            if (isNotRequiresRecaptcha) {
                return sendPublicRequest(undefined).catch(console.error);
            } else if (executeRecaptcha) {
                return makeCancellable(
                    executeRecaptcha(
                        recaptchaAction ? recaptchaAction.replace(replacer, "_") : "/public",
                    )
                        .then(sendPublicRequest)
                        .catch((e: Error) => {
                            console.error(e);
                            notify({
                                level: "error",
                                title: "Recaptcha Error",
                                message: "Please try again. You may need to refresh the page.",
                            });
                        }),
                );
            } else {
                console.warn("NO RECAPTCHA LOADED, could not get token. Skip sending request.");
            }
        },
        [executeRecaptcha, method, makeCancellable, options],
    );
};

/*
 *
 * SUPERADMIN METHODS
 *
 */

export const useSuperAdminAxiosGet = (): ((
    route: string,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const method = useAxiosSuperadminSecureRequest("get");

    return useCallback(
        (route: string, errorHandler?: (error: AxiosError) => void) => {
            return method(route, null, errorHandler);
        },
        [method],
    );
};

export const useSuperAdminAxiosGetBlob = (): ((
    route: string,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const method = useAxiosSuperadminSecureRequest("get", { responseType: "blob" });

    return useCallback(
        (route: string, errorHandler?: (error: AxiosError) => void) => {
            return method(route, null, errorHandler);
        },
        [method],
    );
};

export const useSuperAdminAxiosPost = (): ((
    route: string,
    payload: Record<string, unknown> | FormData,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    return useAxiosSuperadminSecureRequest("post");
};

export const useSuperAdminAxiosPostBlob = (): ((
    route: string,
    payload: FormData,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    return useAxiosSuperadminSecureRequest("post", { responseType: "blob" });
};

export const useSuperAdminAxiosPut = (): ((
    route: string,
    payload: Record<string, unknown> | FormData,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    return useAxiosSuperadminSecureRequest("put");
};

const useAxiosSuperadminSecureRequest = (
    method: "put" | "post" | "get",
    options?: Record<string, string>,
): ((
    route: string,
    data: TPayload | null,
    errorHandler?: (error: AxiosError) => void,
) => Promise<AxiosResponse | void>) => {
    const makeCancellable = useCancellable();
    const accountPublicUuid = useLobbieAccountPublicUuid();

    return useCallback(
        async (
            route: string,
            data: TPayload | null,
            errorHandler?: (error: AxiosError) => void,
        ) => {
            withLobbieRefresh();

            if (
                isNeedsAccountPublicUuid(route) &&
                !isAccountPublicUuidInRequestURL(route, accountPublicUuid)
            ) {
                logDev(
                    `useAxiosSuperadminSecureRequest - ${method.toUpperCase()} - NO ACCOUNT PUBLIC UUID`,
                );
                return;
            }

            const opts = { withCredentials: true, ...options };
            if (data instanceof FormData) {
                (opts as Record<string, any>)["headers"] = {
                    "content-type": "multipart/form-data",
                };
                (opts as Record<string, any>)["timeout"] = 0; // infinite, default?
            }

            const _route = withAccountPublicUuidQuery(
                getRoute(route),
                accountPublicUuid || undefined,
            );

            const url = `${BASE_API_URL}/${API_PREFIX}/secure/v1/${_route}`;

            if (data === null) {
                data = opts; // eslint-disable-line
            } else {
                data = withAccountPublicUuidBody(data, accountPublicUuid); // eslint-disable-line
            }

            return makeCancellable(
                axios[method](
                    replaceStaffOnAdminRoute(`${BASE_API_URL}/${API_PREFIX}/secure/v1/${_route}`),
                    // @ts-ignore
                    data,
                    opts,
                ).catch((error: AxiosError) => {
                    if (errorHandler) {
                        errorHandler(error);
                    } else if (
                        error?.response?.status === 404 &&
                        !isAccountPublicUuidInRequestURL(route, accountPublicUuid)
                    ) {
                        logDev(
                            "useSuperAdminAxiosGet - no accountPublicUuid attached to 404 request.",
                        );
                    } else {
                        throw error;
                    }
                }),
                () =>
                    logDev(
                        `Canceled useAxiosSuperadminSecureRequest ${method.toUpperCase()} route -`,
                        url,
                    ),
            );
        },
        [method, accountPublicUuid, options, makeCancellable],
    );
};

// HELPERS

const getRoute = (route: string) => {
    // if (route.includes("/shared/")) {
    //     if (route.startsWith("/")) {
    //         return route.slice(1);
    //     } else {
    //         return route;
    //     }
    // }

    if (route.startsWith("/admin/")) {
        return route.replace("/admin/", "admin/");
    }
    if (route.startsWith("admin/")) {
        return route;
    }
    if (route.startsWith("/")) {
        return `admin${route}`;
    }
    return `admin/${route}`;
};

const replaceStaffOnAdminRoute = (route: string) => {
    if (!route) return route;
    if (route.includes("/admin/staff/")) {
        return route.replace("/staff/", "/");
    } else {
        return route;
    }
};

const withAccountPublicUuidQuery = (route: string, accountPublicUuid: string | undefined) => {
    if (accountPublicUuid && !route.includes(`${ACCOUNT_PUBLIC_UUID}=${accountPublicUuid}`)) {
        if (route.includes("?")) {
            return `${route}&${ACCOUNT_PUBLIC_UUID}=${accountPublicUuid}`;
        }
        return `${route}?${ACCOUNT_PUBLIC_UUID}=${accountPublicUuid}`;
    }
    return route;
};

const withAccountPublicUuidBody = (
    body: Record<string, unknown> | Record<string, unknown>[] | FormData,
    accountPublicUuid: string | null | undefined,
) => {
    if (body instanceof FormData) {
        if (!body.get(ACCOUNT_PUBLIC_UUID)) {
            body.append(ACCOUNT_PUBLIC_UUID, accountPublicUuid || "");
            return body;
        }
    }
    if (Array.isArray(body)) {
        return {
            data: body,
            accountPublicUuid: accountPublicUuid || null,
        };
    }
    if (!(body as Record<string, any>)[ACCOUNT_PUBLIC_UUID]) {
        return {
            ...body,
            accountPublicUuid: accountPublicUuid || null,
        };
    }
    return body;
};

const isNeedsAccountPublicUuid = (route: string) => {
    return (
        !route.endsWith("/accounts") &&
        !route.endsWith("/admin/current") &&
        !route.includes("/lobbie")
    );
};

const isAccountPublicUuidInRequestURL = (route: string, accountPublicUuid?: string | null) => {
    if (accountPublicUuid) {
        return true;
    }
    return new URLSearchParams(route.split("?").last()).has(ACCOUNT_PUBLIC_UUID);
};
