/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setBranding } from "src/redux/actions/brandingActions";
import { IAppState } from "../store";
const initialState: any = {
    branding: undefined,
};

export const brandingReducer = createReducer(initialState, (builder) => {
    builder.addCase(setBranding, (state: IAppState, action) => {
        if (action.payload) {
            return action.payload;
        } else {
            return state;
        }
    });
});
