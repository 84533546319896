import dayjs, { Dayjs } from "dayjs";
import { forms, IForm, IFormElement, ISelectOption, ISignatureValue, patients } from "lobbie";
import { IBuildFormItem } from "src/components/shared/forms/builder/utils";
import { TFormElementValue, TValidInvalid } from "src/components/shared/forms/utils";
import { EFormElementTypes } from "src/constants/form";
import { ENotificationSendMethod } from "src/constants/notifications";
import { LOBBIE_COLORS } from "src/utils/colors";
import {
    FALSE_VALUES,
    isEmailAutoGenerated,
    isEmptyObject,
    isNumber,
    isPlainObject,
    LobbieDate,
    removeNonDigits,
} from ".";
import { isStringJSON } from "src/components/shared/forms/formElements/files/utils";

export const toSelectOption = (
    label: string | number,
    value: string | number,
    optional?: Record<string, number | string | boolean>,
    // @ts-ignore // - ts-ignore OR https://stackoverflow.com/a/49982981/6410635
): ISelectOption => ({
    label,
    value,
    ...optional,
});

export const PLEASE_SELECT_VALUE = -1;
export const DEFAULT_SELECT_OPTION = toSelectOption("Please select...", PLEASE_SELECT_VALUE);
/*
 * Because the google-libphonenumber pacakge size is huge (555kb)
 * do a simple phone validation on the front end
 * and save the real validation for the backend
 */
export const isValidPhoneNumber = (
    value: TFormElementValue | string,
    isRequired?: boolean,
): boolean => {
    if (!value || typeof value !== "string") {
        // logDev("isValidPhoneNumber - no value received. Return", !isRequired);
        return !isRequired;
    }

    try {
        const phone = removeNonDigits(value);
        if (!phone) {
            // logDev("isValidPhoneNumber - no phone received. Return", !isRequired);
            return !isRequired;
        }

        if (phone.length !== 10) {
            // logDev(
            //     `isValidPhoneNumber - phone is ${phone.length} digits. NOT 10 or 11. Return false`,
            // );
            return false;
        }

        // * Phone AREA CODE does NOT start with a 0 or 1 (no area-codes start with a 0 or 1)
        if (phone.length === 10 && (phone.startsWith("0") || phone.startsWith("1"))) {
            // logDev(
            //     `isValidPhoneNumber - ${phone.length} digit phone STARTS WITH 0 or 1. Return false.`,
            // );
            return false;
        }

        const match = !!phone.match(/\d{10}/); // NOSONAR
        // logDev("isValidPhoneNumber - phone pattern matches?. Return", match);
        return match;
    } catch (error) {
        // logDev("isValidPhoneNumber - Error validating phone number -", value);
        console.error("isValidPhoneNumber - Error validating phone number", error);
        return false;
    }
};

export const isValidTextArea = (value: TFormElementValue): boolean => {
    return typeof value === "string" && value.length > 0;
};

export const isValidTextInput = (value: TFormElementValue): boolean => {
    return typeof value === "string" && value.length > 0;
};

export const isValidSignature = (value: TFormElementValue): boolean => {
    if (!value) return false;

    if (typeof value === "string") {
        if (isStringJSON(value)) {
            return !!(JSON.parse(value) as ISignatureValue)?.signature;
        } else {
            return isValidTextInput(value);
        }
    } else {
        return !!(value as ISignatureValue)?.signature;
    }
};

export const isValidOption = (value: TFormElementValue): boolean => {
    if (!value || isEmptyObject(value)) {
        return false;
    } else if (typeof value === "string") {
        return value.split(",").length > 0;
    } else if (typeof value === "number") {
        return isNumber(value) && Number(value) !== DEFAULT_SELECT_OPTION.value;
    } else if (Array.isArray(value)) {
        return value.length > 0;
    } else if (isPlainObject(value)) {
        return (value as Record<string, any>).value;
    } else {
        return false;
    }
};

export const isValidDate = (value: TFormElementValue): boolean => {
    return !!value && dayjs(value as string | Dayjs).isValid();
    // return !!(
    //     value &&
    //     ["string", "object"].includes(typeof value) &&
    //     new Date(value as string | Date).toString() !== "Invalid Date" &&
    //     dayjs(value as string | Date).isValid()
    // );
};

// https://stackoverflow.com/a/51231/6410635
export const TYPED_DATE_REGEX = new RegExp("[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}"); // NOSONAR
export const isValidTypedDate = (value: TFormElementValue): boolean => {
    if (!value) return false;

    if (typeof value === "string") {
        return TYPED_DATE_REGEX.test(value.trim());
    } else if (value && new Date(value as any).toString().toLowerCase() !== "invalid date") {
        const s = new Date(value as any)?.toLocaleDateString("en-US");
        if (!s || s === "Invalid Date") return false;
        return TYPED_DATE_REGEX.test(s);
    } else {
        console.error("isValidTypedDate - NOT A VALID TYPED DATE VALUE -", value);
        return false;
    }
};

export const US_ZIP_CODE_REGEX = /^\d{5}$/;
export const isValidZipcode = (value: TFormElementValue): boolean => {
    return typeof value === "string" && value.length === 5 && US_ZIP_CODE_REGEX.test(value);
};

// https://stackoverflow.com/a/1146231/6410635
export const CANADA_ZIP_CODE_REGEX =
    /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ]( ?)[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/; // NOSONAR
export const isValidZipcodeCanada = (value: TFormElementValue): boolean => {
    return typeof value === "string" && CANADA_ZIP_CODE_REGEX.test(value);
};

export const isOptionFormElement = (type: IFormElement | IBuildFormItem | number) => {
    if (typeof type === "number") {
        return [
            EFormElementTypes.Select,
            EFormElementTypes.Radio,
            EFormElementTypes.CheckboxGroup,
        ].includes(type);
    }
    return [
        EFormElementTypes.Select,
        EFormElementTypes.Radio,
        EFormElementTypes.CheckboxGroup,
    ].includes((type as IFormElement).elementType || (type as IBuildFormItem).type);
};

export const isChecked = (value: TFormElementValue): boolean => {
    return (typeof value === "string" && value === "true") || (typeof value === "boolean" && value);
};

// Taken from Yup: https://github.com/jquense/yup/blob/master/src/string.ts#L19
// https://github.com/jquense/yup/commit/440db3e6177d25c06be76995a1deff6e25a90c10#diff-bbade37cef19cf470364082a01329d9fbf208a96862818d50ea97602767d7a48
export const YUP_VALID_EMAIL_REGEX = // eslint-disable-next-line
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; // NOSONAR

export const isValidEmail = (value: TFormElementValue): boolean => {
    if (typeof value !== "string") return false;
    if (!value) return false;

    return YUP_VALID_EMAIL_REGEX.test(value);
};

export const isValidSSN = (value: TFormElementValue): boolean => {
    return typeof value === "string" && /^\d{9}$/.test(removeNonDigits(value));
};

export const getDateTimeValue = (value: TFormElementValue): Dayjs | null | undefined => {
    if (!value) {
        return null;
    } else if (value === "now") {
        return dayjs();
    } else if (value instanceof LobbieDate) {
        return dayjs(value);
    } else if (typeof value === "object" && value instanceof Date) {
        return dayjs(value);
    } else if (value && isNumber(value)) {
        return dayjs(value as number);
    } else if (value && typeof value === "string") {
        if (FALSE_VALUES.includes(value)) {
            return dayjs();
        } else {
            return dayjs(value);
        }
    } else {
        return undefined;
    }
};

export const formatPhone = (input: string[] | string | number | undefined | null): any => {
    if (!input) return "";

    const joined = Array.isArray(input) ? input.join("") : String(input);
    if (typeof joined !== "string") return input;

    const replaced = removeNonDigits(joined);
    if (!replaced) return "";

    const value = replaced.substring(replaced.length - 10, replaced.length);

    const areaCode = value.substring(0, 3);
    const middle = value.substring(3, 6);
    const last = value.substring(6, 10);

    // logDev("Formatting Phone -", {
    //     input,
    //     replaced,
    //     value,
    //     areaCode,
    //     middle,
    //     last,
    // });

    if (value.length > 6) {
        return `(${areaCode}) ${middle} - ${last}`;
    } else if (value.length > 3) {
        return `(${areaCode}) ${middle}`;
    } else if (value.length > 0) {
        return `(${areaCode}`;
    }
    return value;
};

export const PHONE_INPUT_TRANSFORMER = {
    input: (value: string) => formatPhone(value),
    output: (e: React.ChangeEvent<any>) => removeNonDigits(e.target.value),
};

export const withGlobalFormTemplateSortOrder = (a: IForm, b: IForm) => {
    if (
        isNumber(a?.formTemplateVersion?.formTemplate?.sortOrder) &&
        isNumber(b?.formTemplateVersion?.formTemplate?.sortOrder)
    ) {
        return a.formTemplateVersion.formTemplate.sortOrder >
            b.formTemplateVersion.formTemplate.sortOrder
            ? 1
            : -1;
    } else {
        return 0;
    }
};

export const getDefaultSendMethodForRecipient = (
    recipient?:
        | forms.IFormRecipient
        | patients.IAccountPatient
        | patients.IAccountPatientLite
        | forms.IFormRecipientParent,
): ENotificationSendMethod => {
    if (!recipient) return ENotificationSendMethod.Paper;

    const phone = "mobilePhone" in recipient ? recipient.mobilePhone : recipient.parentMobilePhone;
    const email = "email" in recipient ? recipient.email : recipient.parentMobilePhone;

    if ("parents" in recipient && !isEmptyObject(recipient.parents)) {
        return getDefaultSendMethodForRecipient(
            recipient.parents.first() as forms.IFormRecipientParent,
        );
    }

    if (phone && email && !isEmailAutoGenerated(email)) {
        return ENotificationSendMethod.EmailAndSms;
    } else if (phone) {
        return ENotificationSendMethod.Sms;
    } else if (email && !isEmailAutoGenerated(email)) {
        return ENotificationSendMethod.Email;
    } else {
        return ENotificationSendMethod.Paper;
    }
};

export const getSelectStyling = (
    valid: TValidInvalid | undefined,
    inputStyle: React.CSSProperties | undefined,
) => {
    return {
        control: (provided: any) => ({
            ...provided,
            backgroundColor: LOBBIE_COLORS.white,
            borderColor: valid === "is-invalid" ? LOBBIE_COLORS.danger : "rgb(160, 174, 192)",
            cursor: "pointer",
            ...inputStyle,
        }),
        option: (provided: any) => ({
            ...provided,
            cursor: "pointer",
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: valid === "is-invalid" ? LOBBIE_COLORS.danger : LOBBIE_COLORS.secondaryBlack,
        }),
        menu: (provided: any) => ({
            ...provided,
            zIndex: 100,
        }),
        menuList: (provided: any) => ({
            ...provided,
            zIndex: 100,
        }),
    };
};
