import { Spinner } from "react-bootstrap";

const LobbieSpinner: React.FC<{
    isHidden?: boolean;
    className?: string;
    small?: boolean;
    message?: string | React.ReactNode;
    style?: React.CSSProperties;
}> = ({ isHidden, className, small, message, style }) => (
    <>
        <Spinner
            animation="border"
            size={small ? "sm" : undefined}
            style={style}
            className={`${className ? "my-2 " + className : "my-2"} blue ${
                isHidden ? "invisible" : ""
            }`}
        />
        {message && (
            <div className={`ml-1 blue body-small ${isHidden ? "invisible" : ""}`}>{message}</div>
        )}
    </>
);

export default LobbieSpinner;
