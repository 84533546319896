/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { IAppState } from "../store";
import { setNpsSelfSignupInfo } from "src/redux/actions/npsSelfSignupInfoActions";

const initialState: any = {
    npsSelfSignupInfo: undefined,
};

export const npsSelfSignupInfoReducer = createReducer(initialState, (builder) => {
    builder.addCase(setNpsSelfSignupInfo, (state: IAppState, action) => {
        return {
            ...state,
            npsSelfSignupInfo: action.payload,
        };
    });
});
