import {
    business_integrations,
    IAccount,
    IAccountFormSettings,
    IAccountLite,
    IAccountPaymentSettings,
    IBranding,
    INotificationConfig,
    integrations,
} from "lobbie";
import { titleize } from "src/utils";
import { LOBBIE_COLORS } from "src/utils/colors";

const DEFAULT_NOTIFICATION_CONFIG = {
    newAppointment: true,
    newAppointmentFormsAssigned: true,
    newAppointmentFormsReminders: true,
    appointmentConfirmation: true,
    formsAssignedWithoutAppointment: true,
    formsRemindersWithoutAppointment: true,
    remoteCheckIn: true,
    roomReady: true,
    telehealthLink: true,
    attachFormPDFsToEmails: false,
    patientAppointmentReschedulingSMS: true,
};

const DEFAULT_ACCOUNT_FORM_SETTINGS = {
    isShowSignatureTimestamps: true,
};

const DEFAULT_ACCOUNT_PAYMENTS_SETTINGS = {
    isStripeEnabled: false,
    isSquareEnabled: false,
};

class Account implements IAccount {
    readonly id: number;
    readonly createdOn: string;
    readonly name: string;
    publicUuid: string;
    readonly isEmailAutoGenerateEnabled: boolean;
    readonly isKeepFormsOpen: boolean;
    readonly minimumAgeYears: number | null;

    // https://lobbie.atlassian.net/browse/LOBBIE-2739
    readonly verifyDOBOnFormsOpen: boolean;
    readonly appointmentScheduling: boolean;
    readonly evv: boolean;
    readonly nps: boolean;
    readonly forms: boolean;
    readonly sendLogin: boolean;
    readonly emailAutoGenerateUrl: string;
    readonly integrators: integrations.IIntegratorLite[];
    readonly patientFieldName: string;
    readonly practitionerFieldName: string;
    readonly lowerPatientFieldName: string;
    readonly lowerPractitionerFieldName: string;
    readonly smsPhoneNumber: string;
    readonly branding: IBranding;
    readonly businessIntegrations?: business_integrations.IBusinessIntegration;
    readonly showSsnOnCsv: boolean;
    readonly integrable: boolean;
    readonly claims: boolean;
    readonly isNPSSelfSignup: boolean;
    readonly gaplessSelfScheduling: boolean;
    readonly notificationConfig: INotificationConfig;
    readonly accountFormSettings: IAccountFormSettings;
    readonly accountPaymentSettings: IAccountPaymentSettings;
    public constructor(account: IAccount | IAccountLite) {
        this.id = account?.id;
        this.createdOn = account?.createdOn;
        this.branding =
            account?.branding ||
            ({
                logoName: account?.name || "",
                isUsingCustomLogoImage: false,
                logoCircleColor: LOBBIE_COLORS.primary,
                logoLetterColor: LOBBIE_COLORS.white,
                fromEmailAddress: "noreply@lobbie.com",
                fromEmailName: "Lobbie",
            } as IBranding);
        this.name = account?.name || "";
        this.publicUuid = account?.publicUuid || "";
        this.isEmailAutoGenerateEnabled = account?.isEmailAutoGenerateEnabled ?? false;
        this.isKeepFormsOpen = account?.isKeepFormsOpen ?? false;
        this.minimumAgeYears = account?.minimumAgeYears || null;
        this.verifyDOBOnFormsOpen = account?.verifyDOBOnFormsOpen ?? false;
        this.appointmentScheduling = account?.appointmentScheduling ?? false;
        this.evv = account?.evv ?? false;
        this.nps = account?.nps ?? false;
        this.forms = account?.forms ?? false;
        this.claims = account?.claims ?? false;
        this.sendLogin = account?.sendLogin ?? false;
        this.emailAutoGenerateUrl = account?.emailAutoGenerateUrl || "";
        this.integrators = account?.integrators || [];
        this.patientFieldName = titleize(account?.patientFieldName) || "Patient";
        this.practitionerFieldName = titleize(account?.practitionerFieldName) || "Practitioner";
        this.lowerPatientFieldName = account?.patientFieldName?.toLowerCase() || "patient";
        this.lowerPractitionerFieldName =
            account?.practitionerFieldName?.toLowerCase() || "practitioner";
        this.smsPhoneNumber = account?.smsPhoneNumber || "";
        this.businessIntegrations = (account as IAccount)?.businessIntegrations;
        this.showSsnOnCsv = account?.showSsnOnCsv ?? false;
        this.integrable = account?.integrable ?? false;
        this.isNPSSelfSignup = (account as IAccount)?.isNPSSelfSignup ?? false;
        this.gaplessSelfScheduling = (account as IAccount)?.gaplessSelfScheduling ?? true;
        this.notificationConfig =
            (account as IAccount)?.notificationConfig || DEFAULT_NOTIFICATION_CONFIG;
        this.accountFormSettings = account?.accountFormSettings || DEFAULT_ACCOUNT_FORM_SETTINGS;
        this.accountPaymentSettings =
            account?.accountPaymentSettings || DEFAULT_ACCOUNT_PAYMENTS_SETTINGS;
    }
}

export default Account;
