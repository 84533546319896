import { AxiosResponse } from "axios";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ACCOUNT_PUBLIC_UUID } from "src/constants";
import { sortBySortOrder } from "src/utils/sorter";

import FormTemplate from "src/classes/FormTemplate";
import { useAxiosGetter } from "src/hooks/useAxios";
import { useIsSuperAdmin } from "src/hooks/user";
import { handleError, logDev, notify, toSelectOption } from "src/utils";
interface IGetTemplatesOptions {
    accountPublicUuid?: string;
    ids?: number[];
    formTemplateGroupId?: number;
    integratorId?: number;
    isShowInactive?: boolean;
    isShowStaffOnly?: boolean;
}

export const useFormTemplates = ({ isSkipInitialLoad }: { isSkipInitialLoad: boolean }) => {
    const isSuperAdmin = useIsSuperAdmin();
    const getter = useAxiosGetter();

    const [templates, setTemplates] = useState<FormTemplate[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const route = isSuperAdmin ? "/forms/templates?" : "/forms/staff/templates?";

    const getTemplates = useCallback(
        async (options?: IGetTemplatesOptions) => {
            setLoading(true);

            let _route = route;
            if (options?.ids) {
                _route += `&ids=${options.ids.join(",")}`;
            }
            if (options?.accountPublicUuid) {
                _route += `&${ACCOUNT_PUBLIC_UUID}=${options.accountPublicUuid}`;
            }
            if (options?.integratorId) {
                _route += `&integratorId=${options.integratorId}`;
            }
            if (options?.isShowInactive) {
                _route += `&inactive=${options.isShowInactive}`;
            }
            if (options?.formTemplateGroupId) {
                _route += `&formTemplateGroupId=${options.formTemplateGroupId}`;
            }

            const filter = (t: FormTemplate) => {
                let show = true;
                if (options?.isShowInactive) {
                    show = !t.active;
                } else {
                    show = t.active;
                }
                if (!show) return show;

                if (options?.isShowStaffOnly !== undefined) {
                    if (options?.isShowStaffOnly) {
                        show = t.isStaffOnly;
                    } else {
                        show = !t.isStaffOnly;
                    }
                }
                return show;
            };

            return getter(_route.replace("?&", "?"))
                .then((response: AxiosResponse | void) => {
                    setLoading(false);
                    if (!response) {
                        logDev(
                            "useFormTemplates.failed - no accountPublicUuid in request for super admin",
                        );
                        return; // canceled because accountPublicUuid is missing for super admin
                    }

                    const _templates = response && (response.data as FormTemplate[]);
                    if (!_templates) {
                        logDev("NO TEMPLATES RESPONSE", response, options?.ids, isSuperAdmin);
                        response?.status &&
                            response.status > 399 &&
                            notify({
                                level: "error",
                                title: "Error getting form templates.",
                            });
                        return undefined;
                    }

                    // if the sortOrder of 'a' is less than the sortOrder of 'b', sort 'a' to be before 'b'
                    const sorted = sortBySortOrder(_templates.filter(filter));
                    const wrapped = sorted.map((t) => new FormTemplate(t));
                    setTemplates(wrapped);
                    return wrapped;
                })
                .catch((error) => {
                    setLoading(false);
                    logDev("useFormTemplates.getTemplates - http error -", error.message);
                    handleError(error);
                    return undefined;
                });
        },
        [getter, route, isSuperAdmin],
    );

    useEffect(() => {
        if (isSkipInitialLoad) return;

        logDev("useFormTemplates.useEffect.getTemplates");
        getTemplates().catch((error) => {
            logDev("useFormTemplates.useEffect - error getting templates -", error);
            handleError(error);
        });
    }, [getTemplates, isSkipInitialLoad]);

    const formTemplateOptions = useMemo(
        () => templates.map((t) => toSelectOption(t.name, t.id)),
        [templates],
    );

    return { templates, setTemplates, getTemplates, formTemplateOptions, isLoading };
};
