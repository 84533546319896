/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { IPractitioner } from "lobbie";
import { addPractitioners, setPractitioners } from "../actions/practitionerActions";

const initialState: any = {
    practitioners: [],
};

export const practitionersReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(addPractitioners, (state: IPractitioner[], action) => {
            return [...state, ...(action.payload || [])];
        })
        .addCase(setPractitioners, (state: IPractitioner[], action) => {
            if (action.payload) {
                return action.payload;
            }
            return state;
        });
});
