/** @format */
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import AppRouter from "../components/routers/AppRouter";
import { store } from "../redux";

import "bootstrap/dist/css/bootstrap.min.css";

import "src/scss/App.scss";
import "src/scss/lobbie-bootstrap.scss";

import "src/scss/checkbox.scss";
import "src/scss/colors.scss";
import "src/scss/forms/print.scss";
import "src/scss/mobile.scss";
import "src/scss/text.scss";

import "src/scss/patients/calendar.scss";
import "src/scss/patients/navbar.scss";
import "src/scss/patients/profile.scss";

import "src/scss/shared/forms.scss";
import "src/scss/staff/appointments/scheduler.scss";
import "src/scss/staff/dashboard.scss";
import "src/scss/staff/form/sent/dropdown.scss";

import "src/scss/file_downloads.scss";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";

import dayjsLocale from "dayjs/locale/en";

import { ErrorBoundary } from "@sentry/react";
import { StrictMode, useEffect } from "react";
import RenderErrorHandler from "src/app-lobbie/error_handling/RenderErrorHandler";
import { onRenderError } from "src/app-lobbie/error_handling/utils";
import {
    IS_BRAVE,
    IS_CHROME,
    IS_EDGE_CHROMIUM,
    IS_FIREFOX,
    IS_MOBILE_PHONE,
    IS_SAFARI,
} from "src/constants";
import { logDev, notify } from "src/utils";

const RECAPTCHA_KEY = import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY as string;

const RECAPTCHA__SCRIPT_PROPS = {
    async: true, // optional, default to false,
    defer: false, // optional, default to false
    appendTo: "head", // optional, default to "head", can be "head" or "body",
    nonce: undefined, // optional, default undefined
} as const;

const App = () => {
    useEffect(() => {
        /**
         * Support is based on what browsers Google ReCaptcha V3 supports:
         * https://support.google.com/recaptcha/answer/6223828?hl=en&ref_topic=6188330
         *
         * @returns
         */
        const isSupportedBrowser = async (): Promise<boolean> => {
            logDev("App.isSupportedBrowser", {
                isMobile: IS_MOBILE_PHONE,
                IS_FIREFOX,
                IS_SAFARI,
                IS_CHROME,
                IS_EDGE_CHROMIUM,
                IS_BRAVE,
            });

            if (IS_MOBILE_PHONE) {
                return IS_CHROME || IS_SAFARI;
            } else {
                return IS_FIREFOX || IS_SAFARI || IS_CHROME || IS_EDGE_CHROMIUM || IS_BRAVE();
            }
        };

        isSupportedBrowser()
            .then((supported) => {
                if (!supported) {
                    notify({
                        level: "warning",
                        title: "Unsupported Browser Detected",
                        message:
                            "Please use either Firefox, Chrome, Safari or Edge web browsers in order to ensure reliable service.",
                    });
                }
            })
            .catch(console.error);
    }, []);

    if (RECAPTCHA_KEY) {
        return (
            <GoogleReCaptchaProvider
                reCaptchaKey={RECAPTCHA_KEY}
                language="en"
                // useRecaptchaNet={true}
                scriptProps={RECAPTCHA__SCRIPT_PROPS}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale.name}>
                    <Provider store={store}>
                        <ErrorBoundary onError={onRenderError} fallback={<RenderErrorHandler />}>
                            <StrictMode>
                                <AppRouter />
                            </StrictMode>
                        </ErrorBoundary>
                        <ToastContainer newestOnTop limit={5} />
                    </Provider>
                </LocalizationProvider>
            </GoogleReCaptchaProvider>
        );
    } else {
        return <div>Please add the Google ReCaptcha Key.</div>;
    }
};

export default App;
