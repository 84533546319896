import { createElement } from "react";
import { toast, ToastOptions, ToastPosition, TypeOptions } from "react-toastify";
import LobbieToast from "src/components/shared/LobbieToast";
import { IS_COMPUTER_WIDTH, IS_MOBILE_PHONE } from "src/constants";

const ALERT_DELAY_MILLISECONDS = 5000;

const DEFAULT_POSITION = IS_MOBILE_PHONE && !IS_COMPUTER_WIDTH ? "top-center" : "top-right";

export const notify = ({
    level,
    title,
    message,
    duration,
    onClick,
    position,
    isMulti,
    isHideProgressBar,
}: {
    level: TypeOptions;
    title: string;
    message?: string | React.ReactNode;
    duration?: number;
    tada?: boolean;
    onClick?: () => void;
    position?: ToastPosition;
    isMulti?: boolean;
    isHideProgressBar?: boolean;
}) => {
    return toast(
        ({ toastProps, closeToast }) =>
            closeToast &&
            createElement(LobbieToast, {
                title: title,
                Message: message,
                toastProps: toastProps as ToastOptions,
                closeToast: closeToast,
            }),
        {
            toastId: isMulti
                ? `${level}-${title}-${message}-${Math.random()}`
                : `${level}-${title}-${message}`,
            position: position || DEFAULT_POSITION,
            autoClose: (() => {
                if (duration === 0) {
                    return false;
                } else if (duration) {
                    return duration;
                } else {
                    return ALERT_DELAY_MILLISECONDS;
                }
            })(),
            type: level,
            onClick: onClick || undefined,
            theme: "colored",
            pauseOnHover: true,
            pauseOnFocusLoss: true,
            closeOnClick: true,
            closeButton: false,
            hideProgressBar: Boolean(isHideProgressBar),
        },
    );
};
