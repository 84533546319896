import { IFileUpload } from "lobbie";
import { FileWithPath } from "react-dropzone";
import { isValidTextInput, logDev, notify, pluralize } from "src/utils";

// https://lobbie.atlassian.net/browse/LOBBIE-2663
export const MAX_UPLOAD_FILE_SIZE = 1024 * 1024 * 25; // 25 MB
export const DEFAULT_MAX_FILES = 1;
// export const DEFAULT_MAX_FILES = IS_LOBBIE_PROD ? 1 : 2;

export const validateFileUploads = (
    _files: IFileUpload | IFileUpload[],
    minFilesCount: number = 1,
    maxFilesCount: number = DEFAULT_MAX_FILES,
    isNotify: boolean,
) => {
    if (!_files) {
        logDev("useSaveFormFileElements.validateFilesUpload - NO FILES. Skipping upload.", _files);
        return false;
    }

    const files = Array.isArray(_files) ? _files : [_files];

    if (files.length < minFilesCount) {
        isNotify &&
            notify({
                level: "warning",
                title: "Invalid number of files selected.",
                message: `Please select at minimum ${minFilesCount} ${pluralize(
                    "file",
                    minFilesCount,
                )}.`,
            });
        return false;
    }

    if (files.length > maxFilesCount) {
        isNotify &&
            notify({
                level: "warning",
                title: "Invalid number of files selected.",
                message: `Please select at most ${maxFilesCount} ${pluralize(
                    "file",
                    maxFilesCount,
                )}.`,
            });
        return false;
    }

    return files
        .map((file, index) => {
            if (!isValidTextInput(file.s3ObjectPath)) {
                isNotify &&
                    notify({
                        level: "warning",
                        title: `File - ${file.fileName || index + 1} - is invalid.`,
                        message: `Please try uploading ${file.fileName || index + 1} again.`,
                    });
                return false;
            } else {
                return true;
            }
        })
        .every(Boolean);
};

export const validateFiles = (files: FileWithPath[]) => {
    if (!files) {
        logDev("useSaveFormFileElements.validateFiles - NO FILES. Skipping upload.");
        return false;
    }

    return files
        .map((file, index) => {
            if (file.size < 1) {
                notify({
                    level: "warning",
                    title: `File - ${file.name || index + 1} - is empty.`,
                    message: `Please try uploading ${file.name || index + 1} again.`,
                });
                return false;
            }

            if (file.size > MAX_UPLOAD_FILE_SIZE) {
                notify({
                    level: "warning",
                    title: `${file.name} is greater than 25 MB in size.`,
                });
                return false;
            }

            return true;
        })
        .every(Boolean);
};

export const isJSONError = (e: Error) => {
    const message = e.message.toLowerCase().trim();
    return (
        message.includes("end of data after property value") ||
        message.includes("parse error") ||
        message.includes("expected property name") ||
        message.includes("unexpected end of") ||
        message.includes("unterminated string") ||
        message.includes("after property value in") ||
        message.includes("unexpected character") ||
        message.includes("double-quoted property name in") ||
        message.includes("unterminated fractional number")
    );
};
