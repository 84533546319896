import { IValidationResult } from "lobbie";
import { useCallback } from "react";
import { useAxiosPost } from "src/hooks/useAxios";

export const useDeleteFileUpload = () => {
    const { post } = useAxiosPost<IValidationResult>("/shared/file_uploads/delete");
    const handleDelete = useCallback(
        async (s3ObjectPath: string | undefined) => {
            if (s3ObjectPath) {
                return post({ s3ObjectPath }).catch(console.error);
            }
        },
        [post],
    );

    return { deleteFileUpload: handleDelete };
};
