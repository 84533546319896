// @ts-ignore

import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
    createAction,
} from "@reduxjs/toolkit";
import { scheduling } from "lobbie";

export const setPatientScheduling =
    createAction<Partial<scheduling.patient.IPatientScheduling> | null>(
        "patientScheduling/set",
    ) as ActionCreatorWithPayload<Partial<scheduling.patient.IPatientScheduling> | null>; // NOSONAR

export const resetPatientScheduling = createAction(
    "resetPatientScheduling/set",
) as ActionCreatorWithoutPayload;
