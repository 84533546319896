import { createReducer } from "@reduxjs/toolkit";
import { setIframeURL } from "src/redux/actions/iframeActions";
import { IAppState } from "src/redux/store";

const initialState: any = {
    url: "",
};

export const iframeReducer = createReducer(initialState, (builder) => {
    builder.addCase(setIframeURL, (state: IAppState, action) => {
        return {
            ...state,
            url: action.payload,
        };
    });
});
