/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setAccount, setAccountPublicUuid, setAccounts } from "../actions/accountActions";
import { IAppState } from "../store";
const initialState: any = {
    accounts: [],
    account: undefined,
    publicUuid: undefined,
};

export const accountReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setAccountPublicUuid, (state: IAppState, action) => {
            return {
                ...state,
                publicUuid: action.payload,
            };
        })
        .addCase(setAccounts, (state: IAppState, action) => {
            return {
                ...state,
                accounts: action.payload,
            };
        })
        .addCase(setAccount, (state: IAppState, action) => {
            return {
                ...state,
                account: {
                    ...state.account,
                    ...action.payload,
                },
            };
        });
});
