import { AxiosResponse } from "axios";
import { IBranding, IValidationResult } from "lobbie";
import { useCallback, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { UseFormSetValue } from "react-hook-form";
import { useLobbieAccountRedux } from "src/hooks/accounts/accounts";
import { useSavePresignedFiles } from "src/hooks/file_uploads/useSavePresignedFiles";
import { useLobbieLocationId } from "src/hooks/locations/useLobbieLocationId";
import { useAxiosPost, useAxiosPutter } from "src/hooks/useAxios";
import { handleError, isBlobString, isFailedRequest, logDev, notify } from "src/utils";

interface IProps {
    baseRoute: string;
    getBranding: () => void;
    setValue: UseFormSetValue<any>;
    watchLogoS3Url: string;
    isLocationBrandingActive: boolean;
}

export const useBrandingLogoImageV2 = ({
    baseRoute,
    getBranding,
    setValue,
    watchLogoS3Url,
    isLocationBrandingActive,
}: IProps) => {
    const putter = useAxiosPutter();
    const lobbieLocationId = useLobbieLocationId();
    const { accountPublicUuid } = useLobbieAccountRedux();

    const [imageUploadPath, setImagePath] = useState<string>("");
    const [imageUploadFile, setImageUploaded] = useState<FileWithPath>();
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();
    const [progress, setProgress] = useState<number>(0);

    const { uploadToS3 } = useSavePresignedFiles();
    const { post: updateBrandingLogo } = useAxiosPost("/staff/branding/logo/image/v2");

    const save = useCallback(
        (file: FileWithPath | undefined) => {
            if (!file) {
                notify({
                    level: "error",
                    title: "No Image Uploaded.",
                    message:
                        "Please make sure you upload an image for custom logo when 'use a custom logo image' is selected'.",
                });
                return;
            }

            setImagePreviewUrl(URL.createObjectURL(file as Blob));

            // NOTE Extract logo name from file name not truncating the name if it's over 40 characters
            const fileName = file.name;
            const logoName = fileName.split(".").slice(0, -1).join(".");
            setValue("logoName", logoName);

            logDev(
                "ConfigBrandingLogo.handleSaveLogo - SAVING file upload for Account -",
                accountPublicUuid,
            );

            const extra = (
                isLocationBrandingActive && lobbieLocationId
                    ? { locationId: lobbieLocationId.toString() }
                    : {}
            ) as Record<string, string>;

            uploadToS3([file], {
                extra: {
                    accountPublicUuid,
                    ...extra,
                },
                onProgress: (_s3ObjectPath, _fileName, uploadProgress) => {
                    setProgress(uploadProgress);
                },
                onDone: ({ s3ObjectPath }, uploadProgress) => {
                    if (uploadProgress >= 100) {
                        updateBrandingLogo({ s3ObjectPath, ...extra })
                            .then((result) => {
                                if (isFailedRequest(result)) {
                                    // noop
                                } else {
                                    const r = result as IBranding;
                                    setValue("logoS3Url", r.logoS3Url || "");
                                    setValue(
                                        "logoImageS3ObjectPath",
                                        r.logoImageS3ObjectPath || s3ObjectPath,
                                    );
                                    setValue("isUsingCustomLogo", true);
                                    setValue("isUsingCustomLogoImage", true);

                                    setImagePreviewUrl((current) => {
                                        if (current && isBlobString(current)) {
                                            URL.revokeObjectURL(current);
                                        }
                                        return r.logoS3Url || "";
                                    });
                                    setProgress(0);

                                    notify({
                                        level: "success",
                                        title: "Uploaded logo image.",
                                        message: "Click save to save branding.",
                                    });

                                    getBranding();
                                }
                            })
                            .catch(console.error);
                    }
                },
            }).catch(console.error);
        },
        [
            accountPublicUuid,
            isLocationBrandingActive,
            lobbieLocationId,
            uploadToS3,
            updateBrandingLogo,
            setValue,
            getBranding,
        ],
    );

    const clear = useCallback(() => {
        setImagePreviewUrl((current) => {
            if (current && isBlobString(current)) {
                URL.revokeObjectURL(current);
            }
            return "";
        });
        setImagePath("");
        setImageUploaded(undefined);
        setProgress(0);

        if (watchLogoS3Url) {
            const data = isLocationBrandingActive ? { locationId: lobbieLocationId } : {};
            putter(`${baseRoute}/image/delete`, data)
                .then((response: AxiosResponse | void) => {
                    const result = response && (response.data as IValidationResult | IBranding);
                    if (!result) {
                        response?.status &&
                            response.status > 399 &&
                            notify({
                                level: "error",
                                title: "Error deleing logo image.",
                            });
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to delete logo image.",
                            message: (result as IValidationResult).message || "",
                        });
                    } else {
                        notify({
                            level: "success",
                            title: "Deleted logo image.",
                        });
                    }
                    getBranding();
                })
                .catch(handleError);
        }
    }, [
        baseRoute,
        putter,

        watchLogoS3Url,
        getBranding,
        isLocationBrandingActive,
        lobbieLocationId,
    ]);

    return {
        imageUploadPath,
        imageUploadFile,
        imagePreviewUrl,
        progress,
        onFileDrop: save,
        handleClearImageLogo: clear,
    };
};
