/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { IDownloadableFile } from "lobbie";
import { reject } from "lodash";
import { getKey, isNewFile } from "src/components/staff/file_downloads/utils";
import { isEmptyObject } from "src/utils";
import {
    addDownloadableFile,
    addDownloadableFiles,
    removeDownloadableFiles,
    setDownloadableFiles,
    setShowFileDownloads,
} from "../actions/fileDownloadsActions";
const initialState: any = {
    formGroup: undefined,
};

export const fileDownloadsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setShowFileDownloads, (state, action) => {
            const show = action.payload;
            return {
                ...state,
                show,
            };
        })
        .addCase(setDownloadableFiles, (state, action) => {
            const files = action.payload;
            return {
                ...state,
                files,
            };
        })
        .addCase(addDownloadableFile, (state, action) => {
            const file = action.payload;
            return {
                ...state,
                files: state.files.concat([file]),
            };
        })
        .addCase(addDownloadableFiles, (state, action) => {
            if (isEmptyObject(action.payload)) {
                return state;
            }

            const newFiles = (
                [...state.files, ...action.payload] as (IDownloadableFile & { expired?: boolean })[]
            )
                .reduce((sum, file) => {
                    if (!file) {
                        return sum;
                    }

                    const key = getKey(file);
                    const isNew = isNewFile(file);

                    const index = sum.findIndex((f) => getKey(f) === key);
                    if (index !== -1) {
                        const existing = sum[index];
                        if (existing.signedURL) {
                            if (existing.signedURL === "expired") {
                                sum.push({
                                    ...file,
                                    signedURL: existing.signedURL,
                                });
                            } else {
                                sum.push({
                                    ...existing,
                                    ...file,
                                });
                            }
                        } else {
                            sum[index] = {
                                ...existing,
                                ...file,
                                newOnUnix:
                                    isNew && !existing.newOnUnix
                                        ? new Date().valueOf()
                                        : existing.newOnUnix,
                            };
                        }
                    } else {
                        sum.push({
                            ...file,
                            newOnUnix:
                                isNew && !file.newOnUnix ? new Date().valueOf() : file.newOnUnix,
                        });
                    }
                    return sum;
                }, [] as IDownloadableFile[])
                .filter((f) => f.signedURL !== "expired");

            return {
                ...state,
                files: newFiles,
            };
        })
        .addCase(removeDownloadableFiles, (state, action) => {
            const removeFiles = action.payload;
            if (isEmptyObject(removeFiles)) {
                return state;
            }

            const s3ObjectPaths = removeFiles
                .filter((f) => f.s3ObjectPath || f.zippedS3ObjectPath || f.mergedS3ObjectPath)
                .map((f) => f.s3ObjectPath || f.zippedS3ObjectPath || f.mergedS3ObjectPath);

            return {
                ...state,
                files: reject(state.files, (o) =>
                    s3ObjectPaths.includes(
                        o.s3ObjectPath || o.zippedS3ObjectPath || o.mergedS3ObjectPath,
                    ),
                ),
            };
        });
});
