import { createSelector } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { IPatient, IPerson, patients, scheduling } from "lobbie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ACCOUNT_PUBLIC_UUID, EUserType } from "src/constants";
import { useAxiosGetter } from "src/hooks/useAxios";
import { useReduxUser } from "src/hooks/user";
import { setPatientScheduling } from "src/redux/actions/patientSchedulingActions";
import { setUser } from "src/redux/actions/userActions";
import {
    HTTP_STATUS_CODES,
    getStoredUser,
    getStoredUserType,
    handleHttpError,
    logDev,
    removeAllUserSessionData,
} from "src/utils";

const REDUX_USER_STATE = (state: any): { user: IPerson | undefined } => {
    return state?.user;
};

const patientSelector = createSelector(
    [REDUX_USER_STATE],
    (_userState: { user: IPerson | undefined }) => {
        if (_userState.user && !_userState.user.isStaff && _userState.user.isPatient) {
            return _userState.user;
        }
    },
);

export const useCurrentPatientRedux = (): IPatient | undefined => {
    return useSelector(patientSelector) as IPatient | undefined;
};

export const useLobbiePatient = (
    accountPublicUuid?: string | null,
): [
    patients.IPatientAccountPatient | undefined,
    (newPatient: patients.IPatientAccountPatient) => void,
    () => Promise<patients.IPatientAccountPatient | undefined>,
] => {
    const dispatch = useDispatch();
    const getter = useAxiosGetter();
    const defaultUser = useReduxUser();
    const [user, setPatient] = useState(defaultUser);

    const errorHandler = useCallback((error: AxiosError) => {
        if (!error.response) {
            setPatient(undefined);
            return;
        }
        if (error.response?.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
            removeAllUserSessionData();
            setPatient(undefined);
            return;
        }
        logDev("useLobbiePatient.useEffect.load - USER HOOK ERROR RESPONSE", error.response);
        handleHttpError(error);
    }, []);

    const route = useMemo(
        () =>
            !accountPublicUuid
                ? "/patient/current"
                : `/patient/current?${ACCOUNT_PUBLIC_UUID}=${accountPublicUuid}`,
        [accountPublicUuid],
    );

    const getCurrentPatient = useCallback(async () => {
        if (getStoredUserType() === EUserType.Staff) {
            logDev(
                "useLobbiePatient.getCurrentPatient - found UserType === Staff in LocalStorage. Skip getting patient.",
            );
            return;
        }

        logDev("useLobbiePatient.getCurrentPatient");
        return getter(route, errorHandler)
            .then((response: AxiosResponse | void) => {
                if (!response || response.status === HTTP_STATUS_CODES.UNAUTHORIZED) {
                    removeAllUserSessionData();
                    return undefined;
                }

                const result = response.data as patients.IPatientAccountPatient;
                if (!result) {
                    logDev(
                        "useLobbiePatient.getCurrentPatient - no result getting patient. Setting patient as undefined.",
                    );
                    setPatient(undefined);
                    return undefined;
                }

                logDev(
                    "useLobbiePatient.getCurrentPatient - getCurrentPatiented current patient with id -",
                    result.id,
                );
                setPatient(result);

                dispatch(setUser(result));
                return result;
            })
            .catch((error) => {
                errorHandler(error);
                return undefined;
            });
    }, [route, dispatch, errorHandler, getter]);

    useEffect(() => {
        const id = getStoredUser();
        if (id && id >= 1 && getStoredUserType() === EUserType.Patient) {
            getCurrentPatient().catch(console.error);
        }
    }, [getCurrentPatient]);

    return [
        (user || defaultUser) as patients.IPatientAccountPatient,
        setPatient,
        getCurrentPatient,
    ];
};
