/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setKiosk, setKiosks } from "src/redux/actions/forms/actions/kiosksActions";
import { IAppState } from "src/redux/store";

const initialState: any = {};

export const kiosksReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setKiosks, (state: IAppState, action) => {
            return {
                ...state,
                kiosks: action?.payload || [],
            };
        })
        .addCase(setKiosk, (state: IAppState, action) => {
            return {
                ...state,
                kiosk: action?.payload,
            };
        });
});
