// eslint-disable-next-line
export enum EEditGroups {
    PatientStaff = 0,
    PatientOnly = 1,
    StaffOnly = 2,
    PatientStaffLocking = 3,
    PatientOnlyLocking = 4,
    StaffOnlyLocking = 5,
    LockedToAll = 6,
}

// eslint-disable-next-line
export enum EColGroup {
    Col0 = 0,
    Col1 = 1,
    Col2 = 2,
    Col3 = 3,
    Col4 = 4,
    Col5 = 5,
    Col6 = 6,
    Col7 = 7,
    Col8 = 8,
    Col9 = 9,
    Col10 = 10,
    Col11 = 11,
    Col12 = 12,
}
