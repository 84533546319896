/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setAppointmentTypes, setDefaultAppointmentType } from "../actions/appointmentActions";
import { IAppState } from "../store";
const initialState: any = {};

export const appointmentsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setAppointmentTypes, (state: IAppState, action) => {
            return {
                ...state,
                appointmentTypes: action?.payload,
            };
        })
        .addCase(setDefaultAppointmentType, (state: IAppState, action) => {
            return {
                ...state,
                defaultAppointmentType: action?.payload,
            };
        });
});
