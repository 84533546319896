/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { EFileUploadStatus } from "src/constants";
import {
    setLoadingFileUpload,
    setLoadingFileDownload,
    setReduxLoading,
} from "../actions/loadingReduxActions";
import { IAppState } from "../store";
const initialState: any = {
    isLoading: false,
    fileUploadStatus: EFileUploadStatus.NULL,
    setLoadingFileDownload: EFileUploadStatus.NULL,
};

export const loadingReduxReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setReduxLoading, (state: IAppState, action) => {
            return {
                ...state,
                isLoading: action.payload,
            };
        })
        .addCase(setLoadingFileUpload, (state: IAppState, action) => {
            return {
                ...state,
                fileUploadStatus: action.payload,
            };
        })
        .addCase(setLoadingFileDownload, (state: IAppState, action) => {
            return {
                ...state,
                fileDownloadStatus: action.payload,
            };
        });
});
