/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setActiveFormElementId } from "src/redux/actions/formBuilderActions";
import { IAppState } from "../store";
const initialState: any = {
    activeFormElementId: undefined,
};

export const formBuilderReducer = createReducer(initialState, (builder) => {
    builder.addCase(setActiveFormElementId, (state: IAppState, action) => {
        return {
            ...state,
            activeFormElementId: action.payload,
        };
    });
});
