import { useCallback, useMemo } from "react";
import { FiUpload } from "react-icons/fi";
import { FileRejection, FileWithPath, useDropzone } from "react-dropzone";
import { isEmptyObject, logDev, notify } from "src/utils";
import { LOBBIE_COLORS } from "src/utils/colors";
import FlexRowDiv from "../FlexRowDiv";
import { FILE_UPLOAD_MESSAGE } from "../../forms/utils";
import { IPlainObject } from "lobbie";

interface IProps {
    name: string;
    onChange: (file: FileWithPath, path: string | null) => void;
    message?: string;
    isButton?: boolean;
    buttonStyle?: IPlainObject;
    disabled?: boolean;
    containerClass?: string;
    inputStyle?: React.CSSProperties;
}

const DEFAULT_MESSAGE = FILE_UPLOAD_MESSAGE;

const BASE_STYLE = {
    flex: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: LOBBIE_COLORS.secondary,
    borderStyle: "dashed",
    backgroundColor: LOBBIE_COLORS.primaryLighter,
    color: LOBBIE_COLORS.secondaryDarkest,
    outline: "none",
    transition: "border .24s ease-in-out",
};

const ACTIVE_STYLE = {
    borderColor: LOBBIE_COLORS.primaryDark,
};

const ACCEPT_STYLE = {
    borderColor: LOBBIE_COLORS.success,
};

const REJECT_STYLE = {
    borderColor: LOBBIE_COLORS.danger,
};

const ImageDrop: React.FC<IProps> = ({
    name,
    message,
    isButton,
    buttonStyle,
    onChange,
    disabled,
    containerClass,
    inputStyle,
}) => {
    const onDrop = useCallback(
        (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
            logDev("ImageDrop.REJECTED FILES - ", rejectedFiles);
            if (!isEmptyObject(rejectedFiles)) {
                notify({
                    level: "error",
                    title: "Files rejected for upload.",
                    message: `Files must be under 25 MB in size and must be in JPG/JPEG, PNG, or GIF format. Rejected: ${rejectedFiles
                        .map((f) => f.file.name)
                        .join(", ")}`,
                });
            }

            (Array.isArray(acceptedFiles) ? acceptedFiles : []).forEach((file: FileWithPath) => {
                onChange(file, URL.createObjectURL(file));
            });
        },
        [onChange],
    );

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
        onDrop,
        disabled,
        accept: { "image/*": [".png", ".jpg", ".jpeg", ".gif"] },
        multiple: false,
        maxFiles: 1,
    });

    const style = useMemo(
        () => ({
            ...BASE_STYLE,
            ...(isDragActive ? ACTIVE_STYLE : {}),
            ...(isDragAccept ? ACCEPT_STYLE : {}),
            ...(isDragReject ? REJECT_STYLE : {}),
        }),
        [isDragActive, isDragReject, isDragAccept],
    );

    if (isButton) {
        return (
            <button
                type="button"
                className="image-upload-button border bg-white rounded px-3 py-1"
                style={buttonStyle}
            >
                <div {...getRootProps()}>
                    <input {...getInputProps()} name={name} />
                    <span className="blue small semi-bold">{message || DEFAULT_MESSAGE}</span>
                </div>
            </button>
        );
    }
    return (
        <div className={`cy-image-drop container ${containerClass || ""}`}>
            <div {...getRootProps({ style: { ...style, ...inputStyle } as React.CSSProperties })}>
                <input {...getInputProps()} name={name} />
                <FlexRowDiv>
                    <FiUpload className={`${disabled ? "gray3" : "blue"} mt-1 mr-1`} />
                    <span className={disabled ? "gray3" : "blue"}>
                        {message || DEFAULT_MESSAGE}
                    </span>
                </FlexRowDiv>
            </div>
        </div>
    );
};

export default ImageDrop;
