import { IAccount } from "lobbie";

interface IReduxAccountState {
    account: IAccount | undefined;
    accounts: IAccount[];
    publicUuid: string | undefined;
}

export const REDUX_ACCOUNT_STATE = (state: any): IReduxAccountState => {
    return state?.account;
};
