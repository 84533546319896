import { createSelector } from "@reduxjs/toolkit";

import { ILocation, ISelectOption } from "lobbie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReduxLocationState, LOCATIONS_REDUX_STATE } from "src/hooks/locations/constants";

import { useCurrentUserLocations } from "src/hooks/locations/useCurrentUserLocations";
import { setLocations } from "src/redux";
import { getId, handleError, isEmptyObject, logDev, toSelectOption } from "src/utils";
import { sortByName } from "src/utils/sorter";

const locationsSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) => sortByName(locations.locations || []),
);
const locationIdsSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) => locations.locations?.map(getId) || [],
);
const locationsOptionsSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) =>
        sortByName(locations.locations || []).map((l) => toSelectOption(l.name, l.id)),
);
const locationsByIdSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) => {
        return (locations.locations || []).reduce(
            (sum, location) => ({ ...sum, [location.id]: location }),
            {} as { [locationId: number]: ILocation },
        );
    },
);

export const useLobbieLocationsRedux = () => {
    return {
        locations: useSelector(locationsSelector),
        locationIds: useSelector(locationIdsSelector),
        locationsById: useSelector(locationsByIdSelector),
        options: useSelector(locationsOptionsSelector),
    };
};

// StaffHeaderBar
// SettingsLocation

export const useLobbieLocations = (): [
    ILocation[],
    (_locations: ILocation[]) => void,
    (showInactive: boolean) => Promise<ILocation[]>,
] => {
    const dispatch = useDispatch();
    const locations = useSelector(locationsSelector);
    const getLobbieLocations = useCurrentUserLocations();

    const _dispatch = useCallback(
        (_locations: ILocation[]) => {
            if (!_locations) {
                logDev("useLobbieLocations.setLocations/setLocationId to undefined");
                dispatch(setLocations([]));
            } else {
                logDev("useLobbieLocations._dispatch - dispatch locations to redux");
                if (!isEmptyObject(_locations)) {
                    dispatch(setLocations(_locations));
                }
            }
        },
        [dispatch],
    );

    const handleGetLocations = useCallback(
        async (showInactive: boolean) => {
            return getLobbieLocations(showInactive)
                .then((newLocations) => {
                    logDev("useLobbieLocations.handleGetLocations - call _dispatch locations");
                    _dispatch(newLocations);
                    return newLocations;
                })
                .catch((error) => {
                    logDev("useLobbieLocations.handleGetLocations - error");
                    handleError(error);
                    return [];
                });
        },
        [getLobbieLocations, _dispatch],
    );

    useEffect(() => {
        handleGetLocations(false).catch(handleError);
    }, [handleGetLocations]);

    return [locations, _dispatch, handleGetLocations];
};

export const useLobbieLocationsNoRedux = (): {
    locations: ILocation[];
    locationIds: number[];
    options: ISelectOption[];
    setLocations: React.Dispatch<React.SetStateAction<ILocation[]>>;
    handleGetLocations: () => Promise<ILocation[]>;
} => {
    const getLobbieLocations = useCurrentUserLocations();
    const [locations, setLocs] = useState<ILocation[]>([]);

    const handleGetLocations = useCallback(async () => {
        return getLobbieLocations(false)
            .then((_locations) => {
                setLocs(_locations);
                return _locations;
            })
            .catch((error) => {
                handleError(error);
                return [];
            });
    }, [getLobbieLocations]);

    useEffect(() => {
        handleGetLocations().catch(handleError);
    }, [handleGetLocations]);

    const locationIds = useMemo(
        () => locations.map(getId).filter(Boolean) as number[],
        [locations],
    );

    return {
        locations: locations,
        locationIds,
        options: useSelector(locationsOptionsSelector),
        setLocations: setLocs,
        handleGetLocations,
    };
};
