import { LOBBIE_STORAGE, OBJECT_OBJECT } from "src/constants";
import { get } from "lodash";
import ls from "localstorage-slim";
import { getStoredUser, isEmptyObject, notify } from ".";

const IS_COOKIES_ENABLED = !!window.document && "cookie" in window.document;

// SecurityError is thrown by iOS Safari and when 3rd party cookies are disabled for localStorage
// const isSecurityError = (error: Error) => error.name === "SecurityError";

const setCookie = (key: string, value: string) => {
    if (!key) return;

    try {
        if (IS_COOKIES_ENABLED && key && value) {
            document.cookie = `${key.trim()}=${value.trim()}`;
        }
    } catch (error) {
        console.error(error);
        notify({
            level: "error",
            title: "Error storing essential data.",
            message:
                "We had a problem storing a required cookie. You may experience some issues until cookies are enabled for my.lobbie.com",
        });
    }
};

const getCookie = (key: string): string | null => {
    if (!IS_COOKIES_ENABLED) return null;
    if (!key) return null;

    try {
        const cookies = (window.document?.cookie || "")
            .split(";")
            .reduce((sum: Record<string, string>, cookie) => {
                const [cookieKey, cookieValue] = (cookie?.trim() || "").split("=");
                if (cookieKey && cookieValue) {
                    sum[cookieKey.trim()] = cookieValue.trim();
                }
                return sum;
            }, {});
        const value = get(cookies, key)?.trim() || null;
        if (!value) {
            return null;
        } else if (value === OBJECT_OBJECT) {
            deleteCookie(key);
            return null;
        } else {
            return value;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

const deleteCookie = (key: string) => {
    try {
        if (IS_COOKIES_ENABLED && key) {
            document.cookie = `${key.trim()}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    } catch (error) {
        console.error(error);
    }
};

// https://stackoverflow.com/a/179514/6410635
export const deleteAllCookies = () => {
    try {
        const cookies = (window.document?.cookie || "").split(";");

        for (const cookie of cookies) {
            if (cookie) {
                const index = cookie.indexOf("=");
                const key = index > -1 ? cookie.substring(0, index) : cookie;
                if (key) {
                    document.cookie = `${key}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
                }
            }
        }
    } catch (error) {
        console.error(error);
    }
};

// @ts-ignore
window.lobbieWindowStorage = {};
export const memorySet = (key: string, value: string | undefined): void => {
    if (value) {
        // @ts-ignore
        window.lobbieWindowStorage[key] = value;
    }
};
export const memoryGet = (key: string): string | null => {
    // @ts-ignore
    const value = window.lobbieWindowStorage[key] || null;
    if (!value) {
        return null;
    } else if (value === OBJECT_OBJECT) {
        memoryRemove(key);
        return null;
    } else {
        return value;
    }
};
const memoryRemove = (key: string): void => {
    // @ts-ignore
    if (window.lobbieWindowStorage[key]) {
        // @ts-ignore
        delete window.lobbieWindowStorage[key];
    }
};

export const sessionGet = (key: string): string | null => {
    const personId = getStoredUser();
    const k = personId ? `${key}/${personId}` : key;

    try {
        const value = (ls?.get(k, { storage: sessionStorage }) ||
            ls?.get(key, { storage: sessionStorage }) ||
            null) as string | null; // TODO: REMOVE getItem(key) call

        if (!value) {
            return null;
        } else if (value === OBJECT_OBJECT) {
            sessionRemove(k);
            sessionRemove(key);
            return null;
        } else {
            return value;
        }
    } catch (error) {
        return getCookie(k) || memoryGet(k);
    }
};

export const sessionSet = (key: string, value: string | undefined): void => {
    const personId = getStoredUser();
    if (!value) return;
    const k = personId ? `${key}/${personId}` : key;
    try {
        ls?.set(k, value, { storage: sessionStorage });
    } catch (error) {
        setCookie(k, value);
        memorySet(k, value);
    }
};

export const sessionRemove = (key: string) => {
    const personId = getStoredUser();
    if (!key) return;
    const k = personId ? `${key}/${personId}` : key;
    try {
        ls?.remove(key); // TODO: REMOVE
        ls?.remove(k);
    } catch (error) {
        deleteCookie(k);
        memoryRemove(k);
    }
};

export const localGet = (key: string, defaultValue?: string): string | null => {
    const personId = getStoredUser();
    const k = personId ? `${key}/${personId}` : key;

    try {
        const value = (ls?.get(k) as string) || (ls?.get(key) as string) || null; // TODO: REMOVE getItem(key) call
        if (!value) {
            return sessionGet(k) || defaultValue || null;
        } else if (value === OBJECT_OBJECT) {
            localRemove(k);
            localRemove(key);
            return sessionGet(k) || defaultValue || null;
        } else {
            return value;
        }
    } catch (error) {
        return sessionGet(k) || defaultValue || null;
    }
};

export const localSet = (key: string, value: string | undefined): void => {
    if (!value) return;
    const personId = getStoredUser();
    const k = personId ? `${key}/${personId}` : key;
    try {
        ls?.set(k, value);
    } catch (error) {
        return sessionSet(k, value);
    }
};

export const localRemove = (key: string) => {
    const personId = getStoredUser();
    if (!key) return;
    const k = personId ? `${key}/${personId}` : key;
    try {
        ls?.remove(key); // TODO: REMOVE
        ls?.remove(k);
    } catch (error) {
        return sessionRemove(k);
    }
};

export const clear = () => {
    try {
        window.localStorage?.clear();
    } catch (error) {
        deleteAllCookies();
    }
    try {
        window.sessionStorage?.clear();
    } catch (error) {
        deleteAllCookies();
    }

    try {
        // @ts-ignore
        Object.keys(window.lobbieWindowStorage).forEach((key) => {
            memoryRemove(key);
        });

        if ("caches" in window) {
            Object.keys(window.caches).forEach((key) => {
                window.caches.delete(key).catch(console.warn);
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const setStoredAccountId = (id: string | number | undefined) => {
    if (!id) return;
    localSet(LOBBIE_STORAGE.Local.Staff.Account.ID.V2, id.toString());
};

export const setStoredLocationId = (id: string | number) => {
    if (!id) return;
    localSet(LOBBIE_STORAGE.Local.Staff.Location.ID.V2, id.toString());
};

export const clearStoredLocationId = () => {
    localRemove(LOBBIE_STORAGE.Local.Staff.Location.ID.V2);
};

export const getStoredLocationId = (accountId: number | undefined): number | undefined => {
    if (accountId && Number(localGet(LOBBIE_STORAGE.Local.Staff.Account.ID.V2)) === accountId) {
        return localGet(LOBBIE_STORAGE.Local.Staff.Location.ID.V2)
            ? Number(localGet(LOBBIE_STORAGE.Local.Staff.Location.ID.V2))
            : undefined;
    }
};

export const getStoredLocationTimeZone = () => {
    return localGet(LOBBIE_STORAGE.Local.Staff.Location.TimeZone.V2) || undefined;
};
export const setStoredLocationTimeZone = (timeZone: string) => {
    if (!timeZone) return;
    localSet(LOBBIE_STORAGE.Local.Staff.Location.TimeZone.V2, timeZone);
};

export const setStoredMultiLocationsIds = (locationIds: number[]) => {
    if (isEmptyObject(locationIds)) return;
    localSet(LOBBIE_STORAGE.Local.Staff.Locations.Multiple, locationIds.join(","));
};
export const clearStoredMultiLocationsIds = () => {
    localRemove(LOBBIE_STORAGE.Local.Staff.Locations.Multiple);
};
export const getStoredMultiLocationsIds = (accountId: number | undefined): number[] => {
    if (accountId && Number(localGet(LOBBIE_STORAGE.Local.Staff.Account.ID.V2)) === accountId) {
        try {
            const stored = localGet(LOBBIE_STORAGE.Local.Staff.Locations.Multiple);
            if (Array.isArray(stored)) {
                return stored.filter(Boolean).map(Number);
            } else if (typeof stored === "string") {
                return stored.split(",").map(Number);
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    } else {
        return [];
    }
};
