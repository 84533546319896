/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { EUserType } from "src/constants/user";
import { includesSuperAdminRoleGroup, isEmptyObject, setUserStorage } from "src/utils";
import { setUser } from "../actions/userActions";
import { IAppState } from "../store";

const initialState: any = {};

export const userReducer = createReducer(initialState, (builder) => {
    builder.addCase(setUser, (state: IAppState, action) => {
        if (action?.payload && !isEmptyObject(action.payload)) {
            const userType = (() => {
                if (includesSuperAdminRoleGroup(action.payload.roleGroupIds)) {
                    return EUserType.SuperAdmin;
                } else if ("isPatient" in action.payload && !action.payload.isPatient) {
                    return EUserType.Staff;
                } else {
                    return EUserType.Patient;
                }
            })();

            setUserStorage(action.payload.id, userType);
        }
        return {
            ...state,
            user: action?.payload,
        };
    });
});
