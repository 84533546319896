import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { ILocation } from "lobbie";

export const setLocationId = createAction<number | undefined>(
    "locationid/set",
) as ActionCreatorWithPayload<number | undefined>;

export const setLocations = createAction<ILocation[] | undefined>(
    "locations/set",
) as ActionCreatorWithPayload<ILocation[] | undefined>;

export const setLocation = createAction<ILocation | undefined>(
    "location/set",
) as ActionCreatorWithPayload<ILocation | undefined>;

export const setMultiMultiLocations = createAction<ILocation[] | undefined>(
    "multiLocations/set",
) as ActionCreatorWithPayload<ILocation[] | undefined>;
