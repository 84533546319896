import { AxiosResponse } from "axios";
import { IFormTemplate, IValidationResult } from "lobbie";
import { useCallback, useState } from "react";
import FormTemplate from "src/classes/FormTemplate";
import { DEFAULT_ERROR_MESSAGE } from "src/constants";
import { useAxiosGetter } from "src/hooks/useAxios";
import { useIsSuperAdmin } from "src/hooks/user";
import { handleError, isFailedRequest, notify } from "src/utils";

export const useFormTemplate = (): [
    FormTemplate | undefined,
    (t: FormTemplate | undefined) => void,
    (templateId: string | number | undefined, callback?: (t: FormTemplate) => void) => void,
    boolean,
] => {
    const isSuperAdmin = useIsSuperAdmin();
    const getter = useAxiosGetter();
    const [template, setTemplate] = useState<FormTemplate | undefined>();
    const [isLoading, setLoading] = useState<boolean>(false);

    const getTemplate = useCallback(
        (templateId: string | number | undefined, callback?: (t: FormTemplate) => void) => {
            if (!templateId || templateId === "new") {
                setTemplate(undefined);
                return;
            }

            setLoading(true);
            const route = isSuperAdmin
                ? `/forms/templates/${templateId}?formTemplateId=${templateId}`
                : `/forms/staff/templates/${templateId}?formTemplateId=${templateId}`;

            getter(route)
                .then((response: AxiosResponse | void) => {
                    setLoading(false);
                    const result = response && (response.data as IFormTemplate | IValidationResult);
                    if (!result) {
                        response?.status &&
                            response.status > 399 &&
                            notify({
                                level: "error",
                                title: "Error getting form template.",
                                message: DEFAULT_ERROR_MESSAGE,
                            });
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to get form template.",
                            message: (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                        });
                    } else {
                        callback
                            ? callback(new FormTemplate(result as IFormTemplate))
                            : setTemplate(new FormTemplate(result as IFormTemplate));
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [getter, isSuperAdmin],
    );

    return [template, setTemplate, getTemplate, isLoading];
};
