import * as Sentry from "@sentry/react";
import axios from "axios";
import { BASE_API_URL, BASE_AUTH_API_ROUTE, IS_LOBBIE_LOCAL } from "src/constants";
import { notify } from "src/utils";

export const onRenderError = (error: Error) => {
    console.warn("React/Sentry Error Boundary Handler - onRenderError");

    if (IS_LOBBIE_LOCAL) {
        return;
    }

    if (
        error?.message?.includes("Failed to fetch dynamically imported module") ||
        error?.message?.includes("Importing a module script failed")
    ) {
        window.location.reload();
        return;
    }

    if (error?.message?.toLowerCase()?.includes("operation is insecure")) {
        notify({
            level: "error",
            title: "Lobbie requires access to cookies.",
            message:
                "Lobbie requires cookies be enabled in order for certain features to function properly.",
        });
        return;
    }

    Sentry.captureException(error, { extra: { lobbie: "Error occurred in onRenderError" } });

    try {
        document.cookie = "";
    } catch (e) {
        console.warn(e);
    }

    try {
        sessionStorage?.clear();
    } catch (e) {
        console.warn(e);
    }

    try {
        localStorage?.clear();
    } catch (e) {
        console.warn(e);
    }

    if ("caches" in window) {
        caches
            .keys()
            .then((names) => names.forEach(async (name) => await caches.delete(name)))
            .catch(console.error);
    }

    axios.post(`${BASE_API_URL}/${BASE_AUTH_API_ROUTE}/logout`, {}).catch(console.error);
};
