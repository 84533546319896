import { createSelector } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLobbieAccountRedux } from "src/hooks/accounts/accounts";
import { IReduxLocationState, LOCATIONS_REDUX_STATE } from "src/hooks/locations/constants";
import { getStoredLocationId } from "src/utils";

const locationIdSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) => {
        return locations.locationId;
    },
);

/**
 * The current location in the staff header bar
 *
 * @param {number} [defaultId]
 * @return {*}  {(number | undefined)}
 */
export const useLobbieLocationId = (defaultId?: number): number | undefined => {
    const { accountId } = useLobbieAccountRedux();
    const id = useSelector(locationIdSelector);
    return useMemo(
        () => id || defaultId || getStoredLocationId(accountId),
        [accountId, id, defaultId],
    );
};
