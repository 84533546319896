import { createSelector } from "@reduxjs/toolkit";
import { IPerson } from "lobbie";
import { useSelector } from "react-redux";
import { IAppState } from "src/redux";
import {
    includesAdminRoleGroup,
    includesDeveloperRoleGroup,
    includesPractitionerRoleGroup,
    includesSuperAdminRoleGroup,
    includesUserRoleGroup,
} from "src/utils";

const userState = (state: IAppState) => state?.user?.user;

const isSuperAdminSelector = createSelector([userState], (user: IPerson | undefined) =>
    includesSuperAdminRoleGroup(user),
);
const isAdminSelector = createSelector([userState], (user: IPerson | undefined) =>
    includesAdminRoleGroup(user),
);
const isUserRoleSelector = createSelector([userState], (user: IPerson | undefined) =>
    includesUserRoleGroup(user),
);
const isPractitionerRoleGroupSelector = createSelector([userState], (user: IPerson | undefined) =>
    includesPractitionerRoleGroup(user),
);
const isDeveloperRoleGroupSelector = createSelector([userState], (user: IPerson | undefined) =>
    includesDeveloperRoleGroup(user),
);

const isOnlyPractitionerRoleGroupSelector = createSelector(
    [userState],
    (user: IPerson | undefined) =>
        includesPractitionerRoleGroup(user) &&
        !includesAdminRoleGroup(user) &&
        !includesUserRoleGroup(user),
);

const isOnlyDeveloperRoleGroupSelector = createSelector(
    [userState],
    (user: IPerson | undefined) =>
        includesDeveloperRoleGroup(user) &&
        !includesAdminRoleGroup(user) &&
        !includesUserRoleGroup(user),
);

const isAdminUserRoleSelector = createSelector(
    [userState],
    (user: IPerson | undefined) => includesAdminRoleGroup(user) || includesUserRoleGroup(user),
);

export const useIsSuperAdmin = () => {
    return useSelector(isSuperAdminSelector);
};

export const useIsAdmin = () => {
    return useSelector(isAdminSelector);
};

export const useIsUser = () => {
    return useSelector(isUserRoleSelector);
};
export const useIsPractitioner = (): boolean => {
    return useSelector(isPractitionerRoleGroupSelector);
};

export const useIsDeveloper = (): boolean => {
    return useSelector(isDeveloperRoleGroupSelector);
};

export const useIsOnlyPractitioner = () => {
    return useSelector(isOnlyPractitionerRoleGroupSelector);
};
export const useIsOnlyDeveloper = () => {
    return useSelector(isOnlyDeveloperRoleGroupSelector);
};

export const useIsAdminOrUser = () => {
    return useSelector(isAdminUserRoleSelector);
};
