import { ACCOUNT_PUBLIC_UUID } from ".";

export const PATIENT_ROUTES = {
    patientHome: "/patient",
    patientProfile: "/patient/profile",
    patientAppointment: (appointmentId: string | number = ":appointmentId") =>
        `/patient/appointments/${appointmentId}`,
    patientSchedulingHome: "/patient/scheduling",
    patientSchedulingAccounts: "/patient/scheduling/accounts",
    patientSchedulingApptType: "/patient/scheduling/appointmentType",
    patientSchedulingLocation: "/patient/scheduling/location",
    patientSchedulingPractitioner: "/patient/scheduling/practitioner",
    patientSchedulingAddress: "/patient/scheduling/address",
    patientSchedulingCalendar: "/patient/scheduling/calendar",
    patientSchedulingUserInfo: "/patient/scheduling/info",
    patientSchedulingUserEmail: "/patient/scheduling/email",
    patientSchedulingSMSConfirmation: "/patient/scheduling/sms_confirmation",
    patientSchedulingPasswordActivation: "/patient/scheduling/activation",
    patientSchedulingConfirmation: "/patient/scheduling/confirmation",
    patientSchedulingBookingConfirmed: "/patient/scheduling/booking",
};

const EVV_ROUTES = {
    evvLogin: "/evv",
    evvHome: "/evv/home",
    evvPractitionerProfile: "/evv/home/practitioner/profile",
    evvPractitionerAppointment: (
        accountPublicUuid = `:${ACCOUNT_PUBLIC_UUID}`,
        appointmentId: string | number = ":appointmentId",
    ) => `/evv/home/practitioner/${accountPublicUuid}/appointments/${appointmentId}`,
};

const CLAIMS_ROUTES = {
    claimsHome: "/home/claims",
    claimsDetail: (accountPublicUuid = `:${ACCOUNT_PUBLIC_UUID}`, id: string | number = ":id") =>
        `/home/claims/${accountPublicUuid}/${id}`,
};

const FORMS_ROUTES = {
    formId: (
        formId: string | number = ":formId",
        accountPublicUuid = `:${ACCOUNT_PUBLIC_UUID}`,
    ): string => `/form/${accountPublicUuid}/${formId}`,
    formIdSection: (
        formId: string | number = ":formId",
        accountPublicUuid = `:${ACCOUNT_PUBLIC_UUID}`,
        section = ":section",
    ): string => `/form/${accountPublicUuid}/${formId}/${section}`,
    formCompletion: "/forms/completed",
    formArchived: "/forms/archived",
    staffHomeLobbieForms: "/home/forms",
    staffHomeLobbieFormsSend: "/home/forms/send",
    publicFormGroupV1: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplateGroupPublicUuid = ":formTemplateGroupPublicUuid",
    ): string => `/public/forms/${accountPublicUuid}/${locationId}/${formTemplateGroupPublicUuid}`,
    publicFormTemplateV1: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplatePublicUuid = ":formTemplatePublicUuid",
    ): string => `/public/form/${accountPublicUuid}/${locationId}/${formTemplatePublicUuid}`,
    publicFormGroupV2: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplateGroupPublicUuid = ":formTemplateGroupPublicUuid",
    ): string => `/static/forms/${accountPublicUuid}/${locationId}/${formTemplateGroupPublicUuid}`,
    publicFormTemplateV2: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplatePublicUuid = ":formTemplatePublicUuid",
    ): string => `/static/form/${accountPublicUuid}/${locationId}/${formTemplatePublicUuid}`,
    publicFormTemplateKiosksV1: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplateKioskPublicUuid = ":formTemplateKioskPublicUuid",
    ): string =>
        `/public/forms/kiosks/${accountPublicUuid}/${locationId}/${formTemplateKioskPublicUuid}`,
    publicFormTemplateKiosksV2: (
        accountPublicUuid = ":accountPublicUuid",
        locationId = ":locationId",
        formTemplateKioskPublicUuid = ":formTemplateKioskPublicUuid",
    ): string =>
        `/public/forms/kiosks/${accountPublicUuid}/${locationId}/${formTemplateKioskPublicUuid}`,
};

const OAM_ROUTES = {
    patientOAMOffer: "/oam/offer/confirm",
};

const APPOINTMENT_ROUTES = {
    appointmentConfirmation: "/appointmentConfirmation/:actionId/:appointmentAction",
};

const USER_ROUTES: {
    login: "/";
    forgotPassword: "/forgotPassword";
    resetPassword: "/resetPassword";

    registerAction: "/registerUser/:actionId";
} = {
    login: "/",
    forgotPassword: "/forgotPassword",
    resetPassword: "/resetPassword",
    registerAction: "/registerUser/:actionId",
};

const QR_ROUTES: {
    qrPractitioner: "/home/qr";
} = {
    qrPractitioner: "/home/qr",
};

const SMS_ROUTES: {
    smsCheckin: "/appointment/sms/check_in";
    formsPatient: "/forms/patient";
    npsHome: "/nps/home";
} = {
    smsCheckin: "/appointment/sms/check_in",
    formsPatient: "/forms/patient",
    npsHome: "/nps/home",
};

const PUBLIC_ROUTES = {
    publicNPSSurveySubmit: "/public/survey",
    publicClickTrack: "/public/click_track",
    npsSignup: "/nps/signup",
    npsInvite: "/nps/invite",
};

const SETTINGS = "/home/settings";
const SETTINGS_ROUTES = {
    homeSettings: SETTINGS,
    homeSettingsUser: `${SETTINGS}/user`,
    homeSettingsUserId: (id: string | number = ":userId"): string => `${SETTINGS}/user/${id}`,
    homeSettingsDeveloper: `${SETTINGS}/developer`,
    homeSettingsAppointmentType: `${SETTINGS}/appointment_type`,
    homeSettingsAppointmentTypeId: (id: string | number = ":appointmentTypeId"): string =>
        `${SETTINGS}/appointment_type/${id}`,
    homeSettingsAccount: `${SETTINGS}/account`,
    homeSettingsAccountConfig: (configType: string | number = ":accountConfigType"): string =>
        `${SETTINGS}/account/${configType}`,
    homeSettingsSocialNPS: `${SETTINGS}/social_nps`,
    homeSettingsSocialNpsUsageAndBillingDetails: `${SETTINGS}/social_nps_usage_and_billing_details`,
    homeSettingsSubscriptionPlans: `${SETTINGS}/subscription_plans`,
    homeSettingsLocation: `${SETTINGS}/location`,
    homeSettingsLocationId: (id: string | number = ":locationId"): string =>
        `${SETTINGS}/location/${id}`,
    homeSettingsRoom: `${SETTINGS}/room`,
    homeSettingsRoomId: (id: string | number = ":roomId"): string => `${SETTINGS}/room/${id}`,
    homeSettingsPractitionerScheduling: `${SETTINGS}/practitioner_scheduling`,
    homeSettingsPractitionerSchedulingId: (id: string | number = ":practitionerId"): string =>
        `${SETTINGS}/practitioner_scheduling/${id}`,
    homeSettingsPractitionerSchedulingIdTemplates: (
        id: string | number = ":practitionerId",
    ): string => `${SETTINGS}/practitioner_scheduling/${id}/templates`,
    homeSettingsFormTemplates: "/home/settings/forms",
    homeSettingsFormTemplateId: (
        accountPublicUuid = ":accountPublicUuid",
        id: string | number = ":formTemplateId",
    ): string => `/home/settings/forms/${accountPublicUuid}/${id}`,

    homeSettingsIntegrations: "/home/settings/integrators",
    homeSettingsIntegrationId: (id: string | number = ":integratorId"): string =>
        `/home/settings/integrators/${id}`,

    homeSettingsBranding: "/home/settings/branding",
    homeSettingsBrandingId: (id: string | number = ":brandingId"): string =>
        `/home/settings/branding/${id}`,

    homeSettingsClaims: "/home/settings/claims",
    homeSettingsServiceCharges: "/home/settings/service_charges",
    homeSettingsServiceChargeId: (id: string | number = ":serviceChargeId"): string =>
        `${SETTINGS}/service_charge/${id}`,

    homeSettingsAutomation: "/home/settings/automations",
    homeSettingsAutomationId: (id: string | number = ":automationId"): string =>
        `/home/settings/automations/${id}`,

    homeSettingsThirdPartyAPI: "/home/settings/third_party_apis",
    homeSettingsThirdPartyAPIId: (id: string | number = ":thirdPartyAPIId"): string =>
        `/home/settings/third_party_apis/${id}`,

    homeSettingsSelfScheduling: `${SETTINGS}/self_scheduling`,
    homeSettingsSelfSchedulingId: (id: string | number = ":selfSchedulingSettingId"): string =>
        `${SETTINGS}/self_scheduling/${id}`,
    homeSettingsNotifications: `${SETTINGS}/notifications`,
    homeSettingsNotificationType: (type = ":notificationType"): string =>
        `${SETTINGS}/notifications/${type}`,
};

const ADMIN_SETTINGS = "/admin/settings";
const ADMIN_ROUTES = {
    admin: "/admin",
    adminLogin: "/admin/login",
    adminSettings: ADMIN_SETTINGS,
    adminFormsSettings: `${ADMIN_SETTINGS}/forms`,
    adminFormsLibrary: `${ADMIN_SETTINGS}/forms/library`,
    adminSettingsDeveloper: `${ADMIN_SETTINGS}/developer`,
    adminSettingsFormTemplateId: (id: string | number = ":formTemplateId"): string =>
        `${ADMIN_SETTINGS}/forms/${id}`,
    adminSettingsUser: `${ADMIN_SETTINGS}/user`,
    adminSettingsUserId: (id: string | number = ":userId"): string =>
        `${ADMIN_SETTINGS}/user/${id}`,
    adminSettingsAppointmentTypeId: (id: string | number = ":appointmentTypeId"): string =>
        `${ADMIN_SETTINGS}/appointment_type/${id}`,
    adminSettingsAppointmentType: `${ADMIN_SETTINGS}/appointment_type`,
    adminSettingsAccount: `${ADMIN_SETTINGS}/account`,
    adminSettingsAccountConfig: (configType: string | number = ":accountConfigType"): string =>
        `${ADMIN_SETTINGS}/account/${configType}`,
    adminSettingsSocialNPS: `${ADMIN_SETTINGS}/social_nps`,
    adminSettingsLocation: `${ADMIN_SETTINGS}/location`,
    adminSettingsLocationId: (id: string | number = ":locationId"): string =>
        `${ADMIN_SETTINGS}/location/${id}`,
    adminSettingsRoom: `${ADMIN_SETTINGS}/room`,
    adminSettingsRoomId: (id: string | number = ":roomId"): string =>
        `${ADMIN_SETTINGS}/room/${id}`,
    adminSettingsPractitionerScheduling: `${ADMIN_SETTINGS}/practitioner_scheduling`,
    adminSettingsPractitionerSchedulingId: (id: string | number = ":practitionerId"): string =>
        `${ADMIN_SETTINGS}/practitioner_scheduling/${id}`,
    adminSettingsPractitionerSchedulingIdTemplates: (
        id: string | number = ":practitionerId",
    ): string => `${ADMIN_SETTINGS}/practitioner_scheduling/${id}/templates`,
    adminSettingsIntegrators: "/admin/settings/integrators",
    adminSettingsIntegratorId: (id: string | number = ":integratorId"): string =>
        `/admin/settings/integrators/${id}`,

    adminSettingsBranding: "/admin/settings/branding",
    adminSettingsBrandingId: (id: string | number = ":brandingId"): string =>
        `/admin/settings/branding/${id}`,

    adminSettingsClaims: "/admin/settings/claims",
    adminSettingsServiceCharges: "/admin/settings/service_charges",
    adminSettingsServiceChargeId: (id: string | number = ":serviceChargeId"): string =>
        `${ADMIN_SETTINGS}/service_charge/${id}`,

    adminSettingsAutomation: "/admin/settings/automations",
    adminSettingsAutomationId: (id: string | number = ":automationId"): string =>
        `/admin/settings/automations/${id}`,

    adminSettingsThirdPartyAPI: "/home/settings/third_party_apis",
    adminSettingsThirdPartyAPIId: (id: string | number = ":thirdPartyAPIId"): string =>
        `/home/settings/third_party_apis/${id}`,

    adminSettingsSelfScheduling: `${ADMIN_SETTINGS}/self_scheduling`,
    adminSettingsSelfSchedulingId: (id: string | number = ":selfSchedulingSettingId"): string =>
        `${ADMIN_SETTINGS}/self_scheduling/${id}`,
    adminSettingsFormTemplateTags: "/admin/settings/form_template_tags",
    adminSettingsLobbieFormAttributes: "/admin/settings/lobbie_form_attributes",
    adminSettingsFormTemplateTagId: (id: string | number = ":formTemplateTagId"): string =>
        `${ADMIN_SETTINGS}/forms/formTemplateTag/${id}`,
    adminSettingsNotifications: "/admin/settings/notifications",
    adminSettingsNotificationId: (id: string | number = ":notification"): string =>
        `${ADMIN_SETTINGS}/notifications/${id}`,
    adminSettingsNotificationType: (type = ":notificationType"): string =>
        `${ADMIN_SETTINGS}/notifications/${type}`,
    adminCRM: "/admin/crm",
    adminPdfTemplates: "/admin/pdfTemplates",
};

export const STAFF_ROUTES = {
    // homeStaff: "/home/staff",
    homeStaffProfile: "/home/staff/profile",
    homeStaffEHRTesting: "/home/staff/ehr/testing",
};

export const ROUTES = {
    home: "/home",
    homeAnalytics: "/home/analytics",
    homeSocialNPS: "/home/social_nps",
    homeAppointments: "/home/appointments",
    homeAppointmentsScheduler: "/home/appointments/scheduler",
    homeAppointmentsSchedulerDay: "/home/appointments/scheduler/day",
    homeActionItems: "/home/action_items",
    homePatients: "/home/patients",
    homePatientsImport: "/home/patients/import",
    homePayments: "/home/payments",
    homeAppointmentId: (
        accountPublicUuid = ":accountPublicUuid",
        id: string | number = ":appointmentId",
    ): string => `/home/appointment/${accountPublicUuid}/${id}`,
    homePatientsEditId: (
        accountPublicUuid = ":accountPublicUuid",
        id: string | number = ":accountPatientId",
    ): string => `/home/patients/edit/${accountPublicUuid}/${id}`,
    homePatientsId: (
        accountPublicUuid = ":accountPublicUuid",
        id: string | number = ":accountPatientId",
    ): string => `/home/patients/${accountPublicUuid}/${id}`,
    ...SETTINGS_ROUTES,
    ...CLAIMS_ROUTES,
    ...PATIENT_ROUTES,
    ...APPOINTMENT_ROUTES,
    ...USER_ROUTES,
    ...SMS_ROUTES,
    ...QR_ROUTES,
    ...OAM_ROUTES,
    ...FORMS_ROUTES,
    ...ADMIN_ROUTES,
    ...EVV_ROUTES,
    ...STAFF_ROUTES,
    ...PUBLIC_ROUTES,
};
