/** @format */

import { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import CenteredFullScreenLoadingSpinner from "src/components/shared/loading/CenteredFullScreenLoadingSpinner";
import Login from "src/components/shared/user/Login";
import { EUserType, IS_LOBBIE_LOCAL, ROUTES } from "src/constants";
import { retry } from "src/utils/lazy";

import FullScreenLoadingSpinner from "src/components/shared/loading/FullScreenLoadingSpinner";
import { getStoredUserType, logDev } from "src/utils";

const NPSSignup = lazy(() => retry(() => import("../staff/signup/NPSSignup")));

const StaffRouter = lazy(() => retry(() => import("src/components/routers/StaffRouter")));

const PatientFormsArchived = lazy(() =>
    retry(() => import("src/components/patients/forms/PatientFormsArchived")),
);
// const StaffHome = lazy(() => import("../staff/StaffHome")));
const PatientParentRouter = lazy(() => retry(() => import("./PatientParentRouter")));
const LobbieForm = lazy(() => retry(() => import("../shared/forms/LobbieForm")));
const AnonymousPatientFormsPublicV2 = lazy(() =>
    retry(() => import("src/components/patients/forms/anonymous/AnonymousPatientFormsPublicV2")),
);
const AnonymousPatientSingleFormPublicV2 = lazy(() =>
    retry(
        () => import("src/components/patients/forms/anonymous/AnonymousPatientSingleFormPublicV2"),
    ),
);

const AnonymousPatientFormsKiosk = lazy(() =>
    retry(() => import("src/components/patients/forms/anonymous/AnonymousPatientFormsKiosk")),
);

const PatientFormsHome = lazy(() =>
    retry(() => import("src/components/patients/forms/PatientFormsHome")),
);
const PatientOAMOffer = lazy(() => retry(() => import("src/components/patients/PatientOAMOffer")));
const ForgotPassword = lazy(() => retry(() => import("../shared/user/ForgotPassword")));
const RegisterUser = lazy(() => retry(() => import("../shared/user/RegisterUser")));
const ResetPassword = lazy(() => retry(() => import("../shared/user/ResetPassword")));
const PatientFormsCompletionConfirmation = lazy(() =>
    retry(() => import("src/components/patients/forms/PatientFormsCompletionConfirmation")),
);
const SmsCheckIn = lazy(() => retry(() => import("../sms/SmsCheckIn")));
const StaffAppointmentConfirmation = lazy(() =>
    retry(() => import("../staff/appointments/StaffAppointmentConfirmation")),
);
const EVVRouter = lazy(() => retry(() => import("./EVVRouter")));

const PatientNPSReviewScore = lazy(() =>
    retry(() => import("src/components/patients/nps/PatientNPSReviewScore")),
);
const PatientNPSReviewSubmitted = lazy(() =>
    retry(() => import("src/components/patients/nps/PatientNPSReviewSubmitted")),
);

const AppRouter = () => {
    logDev("AppRouter.render");

    return (
        <Router>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            {/* <Switch> */}
            <Route path={ROUTES.login} exact={true}>
                <Login />
            </Route>
            <Route path={ROUTES.evvLogin} exact={true}>
                <Login isEVV={true} />
            </Route>
            <Route path={ROUTES.npsSignup} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={
                                IS_LOBBIE_LOCAL
                                    ? "Loading NPS Signup NO INVITE"
                                    : "Loading Lobbie..."
                            }
                        />
                    }
                >
                    <NPSSignup />
                </Suspense>
            </Route>
            <Route path={ROUTES.npsInvite} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={
                                IS_LOBBIE_LOCAL
                                    ? "Loading NPS Signup WITH INVITE"
                                    : "Loading Lobbie..."
                            }
                        />
                    }
                >
                    <NPSSignup isInvite={true} />
                </Suspense>
            </Route>
            <Route path={ROUTES.home}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={
                                IS_LOBBIE_LOCAL ? "Loading Staff Router..." : "Loading Lobbie..."
                            }
                        />
                    }
                >
                    <StaffRouter />
                </Suspense>
            </Route>
            <Route path={ROUTES.patientHome}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={IS_LOBBIE_LOCAL ? "Loading Patient Router" : "Loading..."}
                        />
                    }
                >
                    <PatientParentRouter />
                </Suspense>
            </Route>
            <Route exact path={ROUTES.formId()}>
                <Suspense
                    fallback={<CenteredFullScreenLoadingSpinner message={"Loading Form..."} />}
                >
                    <LobbieForm userType={getStoredUserType() ?? EUserType.Patient} />
                </Suspense>
            </Route>
            <Route path={ROUTES.forgotPassword} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={"Loading Lobbie Forgot Password..."}
                        />
                    }
                >
                    <ForgotPassword />
                </Suspense>
            </Route>
            <Route path={ROUTES.resetPassword} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={"Loading Lobbie Password Reset..."}
                        />
                    }
                >
                    <ResetPassword />
                </Suspense>
            </Route>
            <Route path={ROUTES.formCompletion} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner message={"Loading Forms Completion..."} />
                    }
                >
                    <PatientFormsCompletionConfirmation />
                </Suspense>
            </Route>
            <Route path={ROUTES.formArchived} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner message={"Loading Forms Archived..."} />
                    }
                >
                    <PatientFormsArchived />
                </Suspense>
            </Route>
            <Route path={ROUTES.registerAction} exact={true}>
                <Suspense
                    fallback={<CenteredFullScreenLoadingSpinner message={"Loading Lobbie..."} />}
                >
                    <RegisterUser />
                </Suspense>
            </Route>
            <Route path={ROUTES.appointmentConfirmation} exact={true}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner
                            message={"Loading Appointment Confirmation..."}
                        />
                    }
                >
                    <StaffAppointmentConfirmation />
                </Suspense>
            </Route>

            <Route path={ROUTES.smsCheckin} exact={true}>
                <Suspense
                    fallback={<CenteredFullScreenLoadingSpinner message={"Loading Check-in..."} />}
                >
                    <SmsCheckIn />
                </Suspense>
            </Route>
            <Route path={ROUTES.npsHome}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <PatientNPSReviewScore />
                </Suspense>
            </Route>
            <Route path={ROUTES.formsPatient}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <PatientFormsHome />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormTemplateKiosksV1()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientFormsKiosk />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormGroupV1()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientFormsPublicV2 />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormTemplateV1()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientSingleFormPublicV2 />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormTemplateKiosksV2()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientFormsKiosk />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormGroupV2()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientFormsPublicV2 />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicFormTemplateV2()} exact={true}>
                <Suspense fallback={<CenteredFullScreenLoadingSpinner message={"Loading..."} />}>
                    <AnonymousPatientSingleFormPublicV2 />
                </Suspense>
            </Route>

            <Route path={ROUTES.patientOAMOffer} exact={true}>
                <Suspense fallback={<FullScreenLoadingSpinner message={["Loading Offer..."]} />}>
                    <PatientOAMOffer />
                </Suspense>
            </Route>

            <Route path={ROUTES.publicNPSSurveySubmit}>
                <Suspense
                    fallback={
                        <CenteredFullScreenLoadingSpinner message={"Loading Submission..."} />
                    }
                >
                    <PatientNPSReviewSubmitted />
                </Suspense>
            </Route>
            <Route path={ROUTES.evvHome}>
                <Suspense
                    fallback={<CenteredFullScreenLoadingSpinner message={"Loading EVV..."} />}
                >
                    <EVVRouter />
                </Suspense>
            </Route>
            {/* </Switch> */}
        </Router>
    );
};

export default AppRouter;
