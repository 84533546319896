import dayjs, { Dayjs } from "dayjs";
import { IAppointment, IBaseUnix } from "lobbie";
import { logDev } from ".";
import {
    BROWSER_TIME_ZONE,
    DATE_TIME_FORMATS,
    DEPRECATED_TO_TIMEZONES,
    TIMEZONES_TO_DEPRECATED,
} from "../constants";

export const getDateIfInvalid = (date: Dayjs) => {
    if (!date) {
        return dayjs();
        // @ts-ignore
    } else if (date.isValid()) {
        return date;
    } else {
        return dayjs();
    }
};

// https://stackoverflow.com/a/15171030/6410635
const MILLISECONDS_IN_HOUR = 60000;
export const toDateWithSameOffset = (date: string | number | Date) => {
    const d = new Date(date);
    d.setTime(d.getTime() + new Date().getTimezoneOffset() * MILLISECONDS_IN_HOUR);
    return d;
};

export const timeZoneFromDeprecated = (timeZone: string | undefined): string => {
    if (!timeZone) {
        logDev("timeZoneFromDeprecated - NO timeZone passed. Return BROWSER_TIME_ZONE");
        return BROWSER_TIME_ZONE;
    }
    return (
        (DEPRECATED_TO_TIMEZONES as Record<string, string>)[
            timeZone.trim().toLocaleLowerCase("en-US")
        ] || timeZone.trim()
    );
};

export const timeZoneToDeprecated = (timeZone: string): string => {
    if (!timeZone) {
        logDev("timeZoneFromDeprecated - NO timeZone passed. Use BROWSER_TIME_ZONE");
    }
    return (
        (TIMEZONES_TO_DEPRECATED as Record<string, string>)[
            timeZone.trim().toLocaleLowerCase("en-US") ||
                BROWSER_TIME_ZONE.trim().toLocaleLowerCase("en-US")
        ] || timeZone
    );
};

export const transformDateToString = (
    date: string | undefined | Date | Dayjs,
    isBlock?: boolean,
): string | false | null => {
    if (!date) return null;

    const d = dayjs(date);
    if (!d.isValid()) {
        if (isBlock) {
            return false;
        } else {
            return null;
        }
    }

    return d.format(DATE_TIME_FORMATS.MonthDayYear);
};

// ISSUE - https://github.com/iamkun/dayjs/issues/1713
// SOLUTION - https://github.com/iamkun/dayjs/issues/1154
export const getAbbreviatedTimeZone_NoGMT = (
    datetime: Dayjs,
    timeZone: string = BROWSER_TIME_ZONE,
): string => {
    const abbreviated = datetime.tz(timeZone).format("z");

    if (abbreviated.includes("GMT")) {
        return (
            new Intl.DateTimeFormat(timeZone.includes("Europe") ? "en-GB" : "en-US", {
                timeZone: timeZone,
                timeZoneName: "short",
            })
                .format(datetime.valueOf())
                ?.split(",")
                ?.last()
                ?.trim() || abbreviated
        );
    } else {
        return abbreviated;
    }
};

/*
 * When selecing a DATE (no time) in React Date Picker the Date is set to the beginning of the day in Browser time.
 * So if I select March 31, 2022 and my browser is set to EST, I would get - "Thu Mar 31 2022 00:00:00 GMT-0400 (Eastern Daylight Time)"
 *
 * Since a Lobbie user may be physically located in a time zone that is DIFFERENT than their browser time zone
 * we need to adjust the Date object from React Date Picker to be the beginning of the day in the LOBBIE time zone.
 */
export const convertBrowserDateToLobbieLocationDate = (
    date: Date,
    timeZone: string | undefined,
) => {
    if (!timeZone) {
        logDev(
            "TIMEZONE UNDEFINED IN convertBrowserDateToLobbieLocationDate. RETURN ORIGINAL DATE",
        );
        return date;
    }
    if (!date) {
        logDev("DATE UNDEFINED IN convertBrowserDateToLobbieLocationDate. RETURN ORIGINAL DATE");
        return date;
    }

    // Get the offset in minutes of the passed date
    const offset = date.getTimezoneOffset();

    // Get the unix epoch time of the passed date
    const value = date.valueOf();

    // Create a Dayjs object using the unix epoch time of our passed date and our time zone
    const current = daysjsFromUnix(value, timeZone);

    // Get the difference in minutes between UTC and our Dayjs object
    const currentOffset = current.utcOffset() < 0 ? current.utcOffset() * -1 : current.utcOffset();

    // Subtract the passed dates offset minutes from the current offset minutes and return a new Date
    return current.add(currentOffset - offset, "minutes").toDate();
};

/*
 * React Date Picker expects a Date that is coordinated to a browser's time zone, but Lobbie sends dates that are adjusted to the Lobbie Location time zone
 * Adjust a Lobbie Location time zone to the browser time zone
 */
export const convertLobbieLocationDateToBrowserDate = (
    unix: number,
    timeZone: string | undefined,
): Date => {
    const lobbieDate = daysjsFromUnix(unix, timeZone).toDate();

    // https://stackoverflow.com/a/15171030/6410635
    return new Date(
        lobbieDate.toLocaleString("en-US", {
            timeZone: timeZoneFromDeprecated(timeZone),
        }),
    );
};

export const getTimezone = (timeZone: string | undefined): string => {
    if (!timeZone) {
        return "Etc/UTC";
    }
    if (timeZone.startsWith("US/")) {
        return timeZoneFromDeprecated(timeZone);
    }
    return timeZone;
};

export const newDayjs = (timeZone: string | undefined): Dayjs => {
    return dayjs().tz(getTimezone(timeZone));
};

export const getNow = (timeZone: string) => {
    return newDayjs(timeZone);
};

export const daysjsFromUnix = (
    unixStamp: number,
    timeZone: string | undefined,
    keepLocalDate = false,
) => {
    return dayjs(unixStamp).tz(getTimezone(timeZone), keepLocalDate);
};

export const dayjsFormat = (day: Dayjs, format: string): string => {
    return day.format(format);
};
export const dayjsFormatUnix = (
    unix: number,
    timeZone: string | undefined,
    format: string,
): string => {
    return daysjsFromUnix(unix, getTimezone(timeZone)).format(format);
};

export const formatISODateTime = (datetime: string, timeZone: string | undefined) => {
    return dayjs(new Date(datetime))
        .tz(timeZone || "UTC")
        .format(DATE_TIME_FORMATS.Scheduler.BlockDateTime12HFormatCondensedAMPMFullYear);
};

export const setNoon = (date: Dayjs) => {
    return date.hour(12).minute(0).second(0).millisecond(0);
};

export const isNoonOrGreater = (date: Dayjs) => {
    return date.hour() >= 12;
};

export const fromDate = (date: Date, timeZone: string): Dayjs => {
    return dayjs(date).tz(getTimezone(timeZone));
};

export const parseDayjs = (
    datetime: string,
    format: string,
    timeZone: string | undefined,
): Dayjs => {
    return dayjs.tz(datetime, format, getTimezone(timeZone));
};

export const durationMinutes = (baseUnix: IBaseUnix) => {
    return daysjsFromUnix(baseUnix.endUnix, baseUnix.timeZone).diff(
        daysjsFromUnix(baseUnix.startUnix, baseUnix.timeZone),
        "minutes",
        true,
    );
};

export const toUTC = (date: Dayjs | number, timeZone: string): Dayjs => {
    if (typeof date === "number") {
        return newDayjs(getTimezone(timeZone)).utc();
    }
    return date.utc();
};

export const zeroTime = (day: Dayjs) => {
    return day.hour(0).minute(0).second(0).millisecond(0);
};

export const toStartOfDay = (day: Dayjs) => zeroTime(day);

export const toEndOfDay = (day: Dayjs) => {
    return day.hour(23).minute(59).second(59).millisecond(999);
};

export const isBetweenUnix = <T extends IBaseUnix>(items: T[], start: Dayjs, end: Dayjs) => {
    return items.filter(
        (item) => item.startUnix >= start.valueOf() && item.startUnix < end.valueOf(),
    );
};

export const isToday = (day: Dayjs | null) => {
    if (!day) return false;

    const date = new Date();

    return (
        day.date() === date.getDate() &&
        day.month() === date.getMonth() &&
        day.year() === date.getFullYear()
    );
};

export const calculateMinutesPatientWaitedInRoom = (
    appointment: IAppointment,
    timeZone: string | undefined,
) => {
    return daysjsFromUnix(appointment.roomInUnix, timeZone).diff(
        daysjsFromUnix(
            appointment.startUnix > appointment.checkedInUnix
                ? appointment.startUnix
                : appointment.checkedInUnix,
            timeZone,
        ),
        "minute",
        true,
    );
};

export const getMinutesBetweenDates = (startDate: Date, endDate: Date) => {
    const diff = endDate.getTime() - startDate.getTime();
    return Math.abs(diff / 60000.0);
};

/**
 * https://chat.openai.com/c/9b8f6a60-0c21-4dc2-9ba2-ab473e4267e3
 * @prompt "write some javascript code for me that, as a user types, formats the string into the date format MM/DD/YYYY"
 *
 * @param value
 * @returns {String}
 */
export const formatTypedDate = (value: string): string => {
    if (!value) return "";

    let inputValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    if (inputValue.length > 8) {
        inputValue = inputValue.slice(0, 8); // Keep only the first 8 characters
    }

    if (inputValue.length >= 2 && inputValue.length < 4) {
        inputValue = inputValue.slice(0, 2) + "/" + inputValue.slice(2);
    } else if (inputValue.length >= 4) {
        inputValue =
            inputValue.slice(0, 2) + "/" + inputValue.slice(2, 4) + "/" + inputValue.slice(4);
    }
    return inputValue;
};

// export const formatTypedDateV1 = (value: string) => {
//     if (!value) return "";

//     const words = value.split("/").filter(Boolean);
//     let newDate = "";

//     for (const word of words) {
//         if (words.length === 1) {
//             if (word.length === 1) {
//                 return word;
//             } else if (word.length === 2) {
//                 return `${word}/`;
//             }
//         }

//         // 0 - word
//         if (words.lastIndexOf(word) === 0) {
//             if (!newDate) {
//                 if (word.length === 1) {
//                     newDate += `0${word}/`;
//                 } else {
//                     newDate += `${word}/`;
//                 }
//             }
//         }

//         // 1 - word
//         if (words.lastIndexOf(word) === 1) {
//             if (word.length === 1 && words.length === 2) {
//                 if (value.endsWith("/")) {
//                     newDate += `0${word}/`;
//                 } else {
//                     newDate += word;
//                 }
//             } else if (word.length === 1 && words.length === 3) {
//                 newDate += `0${word}/`;
//             } else if (word.length === 2) {
//                 newDate += `${word}/`;
//             }
//         }

//         // 2 - word
//         if (words.lastIndexOf(word) === 2) {
//             if (word.length < 4) {
//                 newDate += `/${word}`;
//             } else if (word.length === 4) {
//                 newDate += word;
//             } else {
//                 newDate += word.slice(0, 4);
//             }
//         }
//     }

//     return newDate.replace("//", "/");
// };
