import { IS_COMPUTER_WIDTH } from "src/constants";
import { LOBBIE_COLORS } from "src/utils/colors";
import ColorCircle from "./ColorCircle";

interface IProps {
    logoLetterColor: string;
    logoCircleColor: string;
    logoName: string;
    isPrinting: boolean;
}
const BrandLogo: React.FC<IProps> = ({
    logoLetterColor,
    logoCircleColor,
    logoName,
    isPrinting,
}) => {
    return (
        <div className="d-flex align-items-center">
            <ColorCircle
                id="brand-logo-circle"
                color={logoCircleColor}
                style={{
                    width: 35,
                    height: 35,
                    minWidth: 35,
                    minHeight: 35,
                    maxWidth: 35,
                    maxHeight: 35,
                    marginRight: 10,
                    backgroundColor: logoCircleColor || LOBBIE_COLORS.primary,
                    fontWeight: "bold",
                    color: logoLetterColor || LOBBIE_COLORS.white,
                    border: isPrinting ? "1px solid black" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                }}
                logoLetter={logoName ? logoName.charAt(0) : "XXX"}
            />
            <span
                id="brand-logo-name"
                className={`bold my-auto user-select-none ${IS_COMPUTER_WIDTH ? "" : "body-small"}`}
            >
                {logoName ? logoName.toUpperCase() : null}
            </span>
        </div>
    );
};

export default BrandLogo;
