/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setFormTemplateNames } from "src/redux/actions/forms/actions/formsDashboardActions";

const INITIAL_STATE: { formTemplateNames: string[] } = {
    formTemplateNames: [],
};

export const formsDashboardReducer = createReducer(INITIAL_STATE, (builder) => {
    builder.addCase(setFormTemplateNames, (state, action) => {
        return {
            ...state,
            formTemplateNames: action.payload || [],
        };
    });
});
