import dayjs from "dayjs";
import { ICacheableFileResponse, IDownloadableFile } from "lobbie";
import { getMinutesBetweenDates } from "src/utils";

export const getKey = (file: IDownloadableFile | ICacheableFileResponse) => {
    if (file?.zippedS3ObjectPath) {
        return file?.zippedS3ObjectPath;
    } else if (file?.mergedS3ObjectPath) {
        return file?.mergedS3ObjectPath;
    } else {
        return (file as IDownloadableFile)?.s3ObjectPath;
    }
};

export const isNewPastMinute = (file: IDownloadableFile) => {
    return file.newOnUnix && getMinutesBetweenDates(new Date(), new Date(file.newOnUnix)) <= 1;
};

export const isNewFile = (file: IDownloadableFile) => {
    return (
        !file.newOnUnix &&
        file.signedURL &&
        dayjs(file.lastUpdatedOnUnix || file.lastUpdatedOnUnixUtc)
            .add(10, "minutes")
            .isAfter(dayjs())
    );
};
