/** @format */
import { yupResolver } from "@hookform/resolvers/yup";
import { IBranding, ILocation } from "lobbie";
import { useCallback, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";

import { useBranding } from "src/hooks/branding/useBranding";
import { handleError, notify } from "src/utils";
import { CONFIG_BRANDING_VALIDATION_SCHEMA } from "src/utils/validations/config/branding";
import ConfigBrandingEmail from "./branding/ConfigBrandingEmail";
import ConfigBrandingLogo from "./branding/ConfigBrandingLogo";
import { useLobbieLocationId } from "src/hooks/locations/useLobbieLocationId";

export const DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS = "noreply@lobbie.com";
export const DEFAULT_ACCOUNT_REPLY_TO_EMAIL_ADDRESS = "noreply@lobbie.com";
export const DEFAULT_ACCOUNT_FROM_EMAIL_NAME = "Lobbie";

interface IProps {
    location?: ILocation | null;
    isLocationBrandingActive: boolean;
    setLocationBrandingActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfigBranding: React.FC<IProps> = ({ location }) => {
    const isLocationBrandingActive = !!location?.id;
    const lobbieLocationId = useLobbieLocationId();
    const formMethods = useForm<any, any, IBranding>({
        resolver: yupResolver(CONFIG_BRANDING_VALIDATION_SCHEMA),
        defaultValues: {
            locationId: lobbieLocationId,
            isLocationBrandingActive,
        },
    });

    const { handleSubmit, reset } = formMethods;

    const { isLoading, getBranding, createUpdateBranding } = useBranding();

    const handleGetBranding = useCallback(() => {
        getBranding(isLocationBrandingActive)
            .then((branding) => {
                if (branding) {
                    reset({
                        ...branding,
                        locationId: lobbieLocationId,
                        isLocationBrandingActive,
                    });
                }
            })
            .catch(handleError);
    }, [isLocationBrandingActive, lobbieLocationId, reset, getBranding]);

    useEffect(() => {
        handleGetBranding();
    }, [handleGetBranding]);

    const onSubmit = useCallback(
        async (newBranding: IBranding) => {
            if (
                newBranding.fromEmailAddress &&
                !newBranding.fromEmailAddress.endsWith("@lobbie.com")
            ) {
                notify({
                    level: "warning",
                    title: "Invalid email from address.",
                    message: "From address must end with @lobbie.com",
                });
                return;
            }

            createUpdateBranding(newBranding)
                .then((branding) => {
                    if (branding) {
                        setTimeout(() => {
                            notify({
                                level: "success",
                                title: "Branding saved.",
                                message: "Refreshing the page.",
                            });
                            window.location.reload();
                        }, 1000);
                    }
                })
                .catch(handleError);
        },
        [createUpdateBranding],
    );

    const handleContinue = useCallback(
        async (e: React.MouseEvent<HTMLFormElement>) => {
            e.preventDefault();
            e.stopPropagation();

            await handleSubmit(onSubmit)();
        },
        [onSubmit, handleSubmit],
    );

    return (
        <FormProvider {...formMethods}>
            <Form onSubmit={handleContinue}>
                <div className="col">
                    <div className="row">
                        <div className="col">
                            <h3 className="bold">{location?.name || "System"}</h3>
                        </div>
                        <div className="col">
                            <div className="text-right">
                                <Button type="submit" disabled={isLoading}>
                                    Save Branding
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row my-3">
                            <div className="col border rounded bg-white p-3">
                                <ConfigBrandingLogo
                                    isLocationBrandingActive={isLocationBrandingActive}
                                    getBranding={handleGetBranding}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col border rounded bg-white p-3">
                                <ConfigBrandingEmail getBranding={handleGetBranding} />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </FormProvider>
    );
};

export default ConfigBranding;
