import { IFileUpload, IFileUploadProgress, IValidationResult } from "lobbie";
import { useCallback, useEffect, useRef } from "react";
import { FileWithPath } from "react-dropzone";
import { DEFAULT_ERROR_MESSAGE } from "src/constants";
import { useLobbieAccountRedux } from "src/hooks/accounts/accounts";
import { useDeleteFileUpload } from "src/hooks/file_uploads/useDeleteFileUpload";
import { DEFAULT_MAX_FILES, validateFiles } from "src/hooks/file_uploads/validate";
import { useAxiosPost } from "src/hooks/useAxios";
import { handleError, isFailedRequest, logDev, notify } from "src/utils";

interface IOptions {
    onProgress?: (s3ObjectPath: string, fileName: string, progress: number) => void;
    onDone?: (fileUpload: IFileUpload, progress: number) => void;
    onError?: (error: Error, s3ObjectPath?: string) => void;
    max?: number;
    extra?: Record<string, string | number | null>;
    retryCount?: number;
}

// https://lobbie.atlassian.net/browse/LOBBIE-2663
// export const MAX_UPLOAD_FILE_SIZE = 1024 * 1024 * 25; // 25 MB

const FAST_3G_UPLOAD_SPEED_KBPS = 100.0;

const DEFAULT_OPTIONS = {
    max: DEFAULT_MAX_FILES,
    extra: {},
};

export const useSavePresignedFiles = () => {
    const { accountId, accountPublicUuid } = useLobbieAccountRedux();

    const { post: createSignedUpload } = useAxiosPost<{ s3ObjectPath: string; signedURL: string }>(
        "/shared/file_uploads/new",
        false,
    );
    const { post: createFileUpload } = useAxiosPost<IFileUpload>(
        "/shared/file_uploads/create",
        false,
    );
    const { deleteFileUpload } = useDeleteFileUpload();

    const handleCreateSignedUpload = useCallback(
        (file: FileWithPath) => {
            if (!(accountId || accountPublicUuid) || !file?.size) {
                return { s3ObjectPath: "", signedURL: "" };
            }

            return createSignedUpload({
                accountId: accountId || accountPublicUuid,
                type: file.type,
                size: file.size,
            })
                .then((result) => {
                    if (!result) {
                        console.error(
                            `useSavePresignedURL.handleCreateSignedUpload - No presigned url returned from /shared/file_uploads/new - result: ${result}, file: ${file.type}/${file.size}, accountId: ${accountId || accountPublicUuid}`,
                        );
                        return {
                            signedURL: "",
                            s3ObjectPath: "",
                        };
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to upload image",
                            message:
                                (result as IValidationResult)?.message || DEFAULT_ERROR_MESSAGE,
                        });
                        return {
                            signedURL: "",
                            s3ObjectPath: "",
                        };
                    } else {
                        return result as { s3ObjectPath: string; signedURL: string };
                    }
                })
                .catch((e) => {
                    handleError(e);
                    return {
                        signedURL: "",
                        s3ObjectPath: "",
                    };
                });
        },
        [accountId, accountPublicUuid, createSignedUpload],
    );

    const countLowRatesReported = useRef<number>(0);
    useEffect(() => {
        if (countLowRatesReported.current === 10) {
            notify({
                level: "info",
                title: "Slow internet connection detected.",
                message: "Your file upload(s) may take a little longer than expected.",
            });
        }
    });

    const sendXHRRequest = useCallback(
        (
            resolve: (value: boolean) => void,
            file: FileWithPath,
            s3ObjectPath: string,
            signedURL: string,
            options: IOptions = {},
        ) => {
            if (options.onProgress) {
                options.onProgress(s3ObjectPath, file.name, 0.01);
            }

            // https://stackoverflow.com/a/40311906/6410635
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedURL, true);

            let lastNow = new Date().getTime();
            let lastKBytes = 0;

            // Progress event listener - https://stackoverflow.com/a/60239011/6410635
            xhr.upload.addEventListener("progress", (e) => {
                if (e.lengthComputable && options.onProgress) {
                    const now = new Date().getTime();
                    const bytes = e.loaded;
                    const kbytes = bytes / 1024;
                    const uploadedkBytes = kbytes - lastKBytes;
                    const elapsed = (now - lastNow) / 1000;
                    const kbps = elapsed ? uploadedkBytes / elapsed : 0;
                    lastKBytes = kbytes;
                    lastNow = now;

                    if (kbps && kbps < FAST_3G_UPLOAD_SPEED_KBPS) {
                        countLowRatesReported.current = countLowRatesReported.current + 1;
                    }

                    options.onProgress(
                        s3ObjectPath,
                        file.name,
                        Math.ceil((e.loaded / e.total) * 100.0),
                    );
                }
            });

            xhr.onabort = () => {
                console.error("useSavePresignedURL - XHR file upload request aborted.");
                resolve(false);
            };

            xhr.onloadend = () => {
                logDev("useSavePresignedURL - XHR file onloadend event");
            };

            xhr.onload = () => {
                // const x = {
                //     ...xhr,
                //     status: Math.floor(Math.random() * 11) < 2 ? 200 : 503,
                //     statusText: "Slow Down",
                // };

                if (xhr.status === 503 && xhr.statusText === "Slow Down") {
                    if (options.retryCount && options.retryCount >= 10) {
                        console.error(
                            `useSavePresignedURL - XHR request.onload status !== 200. Status: ${xhr.status}: ${xhr.statusText}`,
                        );
                        resolve(false);
                    } else {
                        return window.setTimeout(
                            () => {
                                return sendXHRRequest(resolve, file, s3ObjectPath, signedURL, {
                                    ...options,
                                    retryCount: options.retryCount ? options.retryCount + 1 : 1,
                                });
                            },
                            Math.floor(Math.random() * 100) + 100,
                        );
                    }
                } else {
                    if (xhr.status.toString().match(/^2/)) {
                        resolve(true);
                    } else if (options.retryCount && options.retryCount < 2) {
                        return window.setTimeout(
                            () => {
                                return sendXHRRequest(resolve, file, s3ObjectPath, signedURL, {
                                    ...options,
                                    retryCount: options.retryCount ? options.retryCount + 1 : 1,
                                });
                            },
                            Math.floor(Math.random() * 100) + 100,
                        );
                    } else {
                        console.error(
                            `useSavePresignedURL - XHR request.onload status !== 200. Status: ${xhr.status}: ${xhr.statusText}. Retry count - ${options.retryCount}`,
                        );
                        resolve(false);
                    }
                }
            };

            xhr.onerror = () => {
                console.error(
                    `useSavePresignedURL - Error in XHR request uploading file. Status: ${xhr.status}: ${xhr.statusText}`,
                );
                deleteFileUpload(s3ObjectPath).catch(console.error);
                resolve(false);
            };

            xhr.send(file);
        },
        [deleteFileUpload],
    );

    const handleUploadToS3 = useCallback(
        async (
            file: FileWithPath,
            s3ObjectPath: string,
            signedURL: string,
            options: IOptions = {},
        ): Promise<boolean> => {
            return new Promise((resolve) =>
                sendXHRRequest(resolve, file, s3ObjectPath, signedURL, options),
            );
        },
        [sendXHRRequest],
    );

    const uploadToS3 = useCallback(
        async (
            files: FileWithPath[],
            options: IOptions = DEFAULT_OPTIONS,
        ): Promise<IFileUpload[]> => {
            try {
                if (!validateFiles(files)) {
                    countLowRatesReported.current = 0;
                    return [];
                }

                return Promise.all(
                    files.map(async (file) => {
                        const { s3ObjectPath, signedURL } = await handleCreateSignedUpload(file);
                        if (!signedURL) {
                            return;
                        }

                        return await handleUploadToS3(file, s3ObjectPath, signedURL, options)
                            .then((ok: boolean) => {
                                countLowRatesReported.current = 0;
                                if (ok) {
                                    return createFileUpload({
                                        s3ObjectPath,
                                        // signedURL, // we don't send the signed url here because it is only used for PUT/upload requests to S3
                                        fileName: file.name,
                                        type: file.type,
                                        ...options.extra,
                                    })
                                        .then((fileUpload) => {
                                            if (fileUpload && !isFailedRequest(fileUpload)) {
                                                options.onDone?.(fileUpload as IFileUpload, 100);
                                                return fileUpload as IFileUpload;
                                            }
                                        })
                                        .catch((e) => {
                                            if (options.onError) {
                                                options.onError(e, s3ObjectPath);
                                            } else {
                                                console.error(e);
                                            }
                                        });
                                } else {
                                    notify({
                                        level: "error",
                                        title: "Error uploading file.",
                                        message: DEFAULT_ERROR_MESSAGE,
                                    });
                                }
                            })
                            .catch((e) => {
                                countLowRatesReported.current = 0;
                                if (options.onError) {
                                    options.onError(e, s3ObjectPath);
                                } else {
                                    console.error(e);
                                }
                            });
                    }),
                )
                    .then((result) => result.filter(Boolean) as IFileUpload[])
                    .catch((e) => {
                        if (options.onError) {
                            options.onError(e);
                        } else {
                            console.error(e);
                        }
                        return [];
                    });
            } catch (e) {
                countLowRatesReported.current = 0;
                if ((e as Error).message !== "catch") {
                    if (options.onError) {
                        options.onError(e as Error);
                    } else {
                        console.error(e);
                    }
                }
                return [];
            }
        },
        [createFileUpload, handleCreateSignedUpload, handleUploadToS3],
    );

    return { uploadToS3: uploadToS3 };
};

export const getReconciledFileUploads = (
    current: IFileUploadProgress[],
    fileUploads: IFileUploadProgress[],
): IFileUploadProgress[] => {
    if (Array.isArray(current)) {
        return Object.values(
            [...current, ...fileUploads].reduce((sum: Record<string, IFileUploadProgress>, f) => {
                const currentProgress = sum[f.s3ObjectPath]?.progress || 0;
                if (currentProgress && f.progress && currentProgress < f.progress) {
                    return {
                        ...sum,
                        [f.s3ObjectPath]: f,
                    };
                } else {
                    return {
                        ...sum,
                        [f.s3ObjectPath]: { ...f, progress: f.progress || 0 },
                    };
                }
            }, {}) as Record<string, IFileUploadProgress>,
        );
    } else {
        return fileUploads;
    }
};
