import { FiCircle } from "react-icons/fi";

interface IProps {
    color: string;
    style?: React.CSSProperties;
    logoLetter?: string;
    id?: string;
}
const ColorCircle: React.FC<IProps> = ({ color, id, style, logoLetter }) => {
    if (!logoLetter) {
        return (
            <FiCircle
                size={"1em"}
                color={color}
                style={style ? { ...style, backgroundColor: color } : { backgroundColor: color }}
                className="user-select-none rounded-circle"
            />
        );
    }

    return (
        <div
            id={id}
            className="complete rounded-circle"
            style={{
                backgroundColor: color,
                color: color,
                height: "1em",
                width: "1em",
                textAlign: "center",
                ...style,
            }}
        >
            <div className="user-select-none">{logoLetter}</div>
        </div>
    );
};

export default ColorCircle;
