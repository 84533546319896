import dayjs from "dayjs";
import ls from "localstorage-slim";
import { Dispatch, UnknownAction } from "redux";
import { EUserType, LOBBIE_STORAGE } from "src/constants";
import { getId, isEmptyObject, isNumeric, logDev } from ".";
import {
    clear,
    getStoredLocationId,
    getStoredMultiLocationsIds,
    localGet,
    localRemove,
    localSet,
    memoryGet,
    memorySet,
    sessionGet,
    sessionSet,
    setStoredAccountId,
    setStoredLocationId,
    setStoredLocationTimeZone,
    setStoredMultiLocationsIds,
} from "./storage";
import { IStaffer } from "lobbie";
import { setLocation, setLocations, setMultiMultiLocations } from "src/redux";
import { setAccount } from "src/redux/actions/accountActions";
import { setBranding } from "src/redux/actions/brandingActions";

export const setUserSessionLocalStorage = (
    userId: number,
    userType: EUserType,
    options?: { defaultTTL?: number },
) => {
    const NOW_EST = dayjs();
    const EST_4AM = dayjs().hour(4).minute(0).second(0).millisecond(0);

    // ttl = seconds until expiration
    let ttl = options?.defaultTTL;
    if (!ttl) {
        // Same day but it's earlier in the morning
        if (NOW_EST.isBefore(EST_4AM)) {
            ttl = EST_4AM.diff(NOW_EST, "seconds");
        } else {
            // update EST_4AM to next day
            const tomorrow4AM = EST_4AM.date(EST_4AM.date() + 1);
            ttl = tomorrow4AM.diff(NOW_EST, "seconds");
        }
    }

    logDev(
        `userReducer.setUser - Setting ${LOBBIE_STORAGE.Local.Shared.User} and ${LOBBIE_STORAGE.Local.Shared.UserType} in ls.storage with expiry in ${ttl} seconds`,
    );
    ls.set(LOBBIE_STORAGE.Local.Shared.User, userId || Number.MAX_SAFE_INTEGER, {
        ttl: Math.abs(ttl),
    });
    ls.set(LOBBIE_STORAGE.Local.Shared.UserType, userType, { ttl: Math.abs(ttl) });
};

export const isEmailAutoGenerated = (email: string | null | undefined) => {
    if (!email) return false;

    return (
        email.toLowerCase().startsWith("anonymous_") || email.toLowerCase().startsWith("cleartake_")
    );
};

export const removeAllUserSessionData = (isClearAllStorage?: boolean) => {
    logDev("user.logout - CLEARING all sessionStorage, localStorage and cookies");

    if (isClearAllStorage) {
        ls.clear();
        clear();
    } else {
        // * LOBBIE-1309
        const doNotClearSessionOptions = {
            [LOBBIE_STORAGE.Session.Staff.Patients.PageNumber]: sessionGet(
                LOBBIE_STORAGE.Session.Staff.Patients.PageNumber,
            ),
            [LOBBIE_STORAGE.Session.Staff.Patients.NumberRow]: sessionGet(
                LOBBIE_STORAGE.Session.Staff.Patients.NumberRow,
            ),

            [LOBBIE_STORAGE.Session.Staff.Scheduler.SelectedDay_v2]: sessionGet(
                LOBBIE_STORAGE.Session.Staff.Scheduler.SelectedDay_v2,
            ),
            [LOBBIE_STORAGE.Session.Staff.Scheduler.SelectedDayTimezone_v2]: sessionGet(
                LOBBIE_STORAGE.Session.Staff.Scheduler.SelectedDayTimezone_v2,
            ),
            [LOBBIE_STORAGE.Session.Staff.Scheduler.Practitioners_v2]: sessionGet(
                LOBBIE_STORAGE.Session.Staff.Scheduler.Practitioners_v2,
            ),
        };

        const doNotClearLocalOptions = {
            [LOBBIE_STORAGE.Local.Staff.Account.ID.V2]: localGet(
                LOBBIE_STORAGE.Local.Staff.Account.ID.V2,
            ),
            [LOBBIE_STORAGE.Local.Staff.Location.ID.V2]: localGet(
                LOBBIE_STORAGE.Local.Staff.Location.ID.V2,
            ),
            [LOBBIE_STORAGE.Local.Staff.Locations.Multiple]: localGet(
                LOBBIE_STORAGE.Local.Staff.Locations.Multiple,
            ),

            [LOBBIE_STORAGE.Local.Staff.Forms.PageNumber]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.PageNumber,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.NumberRow]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.NumberRow,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.DateRange]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.DateRange,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.FormStatus]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.FormStatus,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.PatientName]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.PatientName,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.FormTemplateNames]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.FormTemplateNames,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.PractitionerId]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.PractitionerId,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.SortField]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.SortField,
            ),
            [LOBBIE_STORAGE.Local.Staff.Forms.SortDirection]: localGet(
                LOBBIE_STORAGE.Local.Staff.Forms.SortDirection,
            ),
        };

        clear();
        ls.clear();

        Object.keys(doNotClearSessionOptions).forEach((key) => {
            const value = doNotClearSessionOptions[key];
            if (value) {
                sessionSet(key, doNotClearSessionOptions[key] as string);
            }
        });

        Object.keys(doNotClearLocalOptions).forEach((key) => {
            const value = doNotClearLocalOptions[key];
            if (value) {
                localSet(key, doNotClearLocalOptions[key] as string);
            }
        });
    }
};

export const setUserStorage = (
    userId: number,
    userType: EUserType,
    options?: { defaultTTL?: number },
) => {
    const NOW_EST = dayjs();
    const EST_4AM = dayjs().hour(4).minute(0).second(0).millisecond(0);

    // ttl = seconds until expiration
    let ttl = options?.defaultTTL;
    if (!ttl) {
        // Same day but it's earlier in the morning
        if (NOW_EST.isBefore(EST_4AM)) {
            ttl = EST_4AM.diff(NOW_EST, "seconds");
        } else {
            // update EST_4AM to next day
            const tomorrow4AM = EST_4AM.date(EST_4AM.date() + 1);
            ttl = tomorrow4AM.diff(NOW_EST, "seconds");
        }
    }

    logDev(
        `setUserStorage - Setting ${LOBBIE_STORAGE.Local.Shared.User} and ${LOBBIE_STORAGE.Local.Shared.UserType} in ls.storage with expiry in ${ttl} seconds`,
    );
    try {
        ls.set(LOBBIE_STORAGE.Local.Shared.User, userId || Number.MAX_SAFE_INTEGER, {
            ttl: Math.abs(ttl),
            storage: sessionStorage,
        });
        ls.set(LOBBIE_STORAGE.Local.Shared.UserType, userType, {
            ttl: Math.abs(ttl),
            storage: sessionStorage,
        });
    } catch (error) {
        if (!isSecurityError(error as Error)) {
            console.error(error);
        }
        memorySet(LOBBIE_STORAGE.Local.Shared.User, (userId || Number.MAX_SAFE_INTEGER).toString());
        memorySet(LOBBIE_STORAGE.Local.Shared.UserType, userType.toString());
    }
};

export const getStoredUser = (): number | undefined => {
    try {
        const stored =
            ls.get(LOBBIE_STORAGE.Local.Shared.User, { storage: sessionStorage }) ??
            ls.get(LOBBIE_STORAGE.Local.Shared.User); // TODO: Remove getting from localStorage
        if (stored) {
            return Number(stored);
        }
    } catch (error) {
        if (!isSecurityError(error as Error)) {
            console.error(error);
        }
        return Number(memoryGet(LOBBIE_STORAGE.Local.Shared.User)) || undefined;
    }
};

export const getStoredUserType = (): number | undefined => {
    try {
        const stored =
            ls.get(LOBBIE_STORAGE.Local.Shared.UserType, { storage: sessionStorage }) ??
            ls.get(LOBBIE_STORAGE.Local.Shared.UserType); // TODO: Remove getting from localStorage
        if (isNumeric(stored)) {
            return Number(stored);
        }
    } catch (error) {
        if (!isSecurityError(error as Error)) {
            console.error(error);
        }
        return Number(memoryGet(LOBBIE_STORAGE.Local.Shared.UserType)) || undefined;
    }
};

export const removeStoredUser = () => {
    localRemove(LOBBIE_STORAGE.Local.Shared.User);
};
export const removeStoredUserType = () => {
    localRemove(LOBBIE_STORAGE.Local.Shared.UserType);
};

const isSecurityError = (error: Error) => {
    return (
        (error as Error)?.message?.toLowerCase()?.includes("operation is insecure") ||
        (error as Error)?.name?.toLowerCase() === "securityerror"
    );
};

export const updateStafferState = (dispatch: Dispatch<UnknownAction>, staffer: IStaffer) => {
    dispatch(setAccount(staffer.account));
    if (staffer.account?.branding) {
        dispatch(setBranding(staffer.account.branding));
    }

    // setting multiple location data upon login
    if (staffer.locations) {
        const locationId = getStoredLocationId(staffer.account?.id);
        const location =
            staffer.locations.find((l) => l.id === locationId) ||
            staffer?.defaultLocation ||
            staffer.locations.first();

        const storedMultipleLocationsIds = getStoredMultiLocationsIds(staffer.account?.id) || [];
        const _multiLocations = staffer.locations.filter((l) =>
            storedMultipleLocationsIds.includes(l.id),
        );
        const multiLocations = isEmptyObject(_multiLocations) ? [location] : _multiLocations;

        dispatch(setLocation(location));
        dispatch(setLocations(staffer?.locations));
        dispatch(setMultiMultiLocations(multiLocations));

        setStoredAccountId(staffer?.account?.id);
        setStoredLocationId(location?.id);
        setStoredLocationTimeZone(location?.timeZone);
        setStoredMultiLocationsIds((multiLocations?.map(getId).filter(Boolean) as number[]) || []);
    }
};
