export const APPOINTMENTS_START_DATE_UNIX_KEY = "@lobbie/session/appointments/filter/start";
export const APPOINTMENTS_END_DATE_UNIX_KEY = "@lobbie/session/appointments/filter/end";
export const APPOINTMENTS_DISPLAYING_EVV_KEY = "@lobbie/session/appointments/filter/evv";
export const APPOINTMENTS_PATIENT_NAME_KEY = "@lobbie/session/appointments/filter/patient";
export const APPOINTMENTS_PRACTITIONER_ID_KEY = "@lobbie/session/appointments/filter/practitioner";

export const LOBBIE_STORAGE = {
    Session: {
        NPS: {
            Onboarding: {
                ShowSetUpSocialReviews: "@lobbie/social_nps/onboarding/showSetUpSocialReviews",
            },
        },
        Patients: {
            Forms: {
                Anonymous: {
                    AccountPublicUuid: "@lobbie/patients/forms/anonymous/AccountPublicUuid",
                    LocationId: "@lobbie/patients/forms/anonymous/LocationId",
                    FormTemplatePublicUuid:
                        "@lobbie/patients/forms/anonymous/FormTemplatePublicUuid",
                    FormTemplateGroupPublicUuid:
                        "@lobbie/patients/forms/anonymous/FormTemplateGroupPublicUuid",
                },
                ActiveFormGroupId: "@lobbie/patients/forms/ActiveFormGroupId",
                CacheKey: "@lobbie/patients/forms/CacheKey",
                S3ObjectPath: "@lobbie/patients/forms/S3ObjectPath",
                PageNumber: "@lobbie/patients/forms/PageNumber",
                NumberRow: "@lobbie/patients/forms/NumberRow",
                AnonymousCreated: "@lobbie/patients/forms/AnonymousCreated",
                AnonymousUpdateable: "@lobbie/patients/forms/AnonymousUpdateable",
                FormCompleted: "@lobbie/patients/forms/FormCompleted",
                VerifiedDateOfBirth: "@lobbie/patients/forms/VerifiedDateOfBirth",
            },
            Scheduler: {
                Practitioners_v2: "@lobbie/staff/scheduler/practitioners_v2",
                SelectedDay_v2: "@lobbie/staff/scheduler/selected/day_v2",
                SelectedDayTimezone_v2: "@lobbie/staff/scheduler/selected/day/timezone_v2",
                InitialAccountPatientId_v2:
                    "@lobbie/staff/scheduler/selected/day/InitialAccountPatientId_v2",
                ShowSchedulablePractitioners_v2:
                    "@lobbie/staff/scheduler/selected/day/ShowSchedulablePractitioners_v2",
            },
        },
        Staff: {
            Forms: {
                ExpandedRows: "@lobbie/staff/forms/ExpandedRows",
                Send: {
                    Start: "@lobbie/session/forms/filter/start",
                    End: "@lobbie/session/forms/filter/end",
                },
            },
            Patients: {
                PatientName: "@lobbie/staff/patients/PatientName",
                PageNumber: "@lobbie/staff/patients/PageNumber",
                NumberRow: "@lobbie/staff/patients/NumberRow",
            },
            Scheduler: {
                Practitioners_v2: "@lobbie/staff/scheduler/practitioners_v2",
                SelectedDay_v2: "@lobbie/staff/scheduler/selected/day_v2",
                SelectedDayTimezone_v2: "@lobbie/staff/scheduler/selected/day/timezone_v2",
                InitialAccountPatientId_v2:
                    "@lobbie/staff/scheduler/selected/day/InitialAccountPatientId_v2",
                ShowSchedulablePractitioners_v2:
                    "@lobbie/staff/scheduler/selected/day/ShowSchedulablePractitioners_v2",
            },
            Settings: {
                SelfSchedulingSettingName: "@lobbie/staff/settings/SelfSchedulingSettingName",
            },
        },
        Shared: {
            Forms: {
                SectionIndex: "@lobbie/shared/forms/SectionIndex",
            },
        },
    },
    Local: {
        Banner: {
            Version: "@lobbie/banner/version",
        },
        Forms: {
            Drawable: {
                Signature: "@lobbie/forms/drawable/signature",
                Initials: "@lobbie/forms/drawable/initials",
            },
            Initials: "@lobbie/forms/initials",
            Signature: "@lobbie/forms/signature",
        },
        Locations: {
            Colors: "@lobbie/locations/colors",
            Location: {
                Name: "@lobbie/locations/location/name",
            },
        },
        Shared: {
            User: "@lobbie/user",
            UserType: "@lobbie/userType",
        },
        Staff: {
            Signature: {
                Signature: "@lobbie/local/staff/signature/Signature",
            },
            Account: {
                ID: {
                    V2: "@lobbie/account/id/v2",
                },
            },
            Features: {
                PatientDetailsV2: "@lobbie/local/staff/features/PatientDetailsV2",
            },
            Location: {
                ID: {
                    V2: "@lobbie/location/id/v2",
                },
                TimeZone: {
                    V2: "@lobbie/location/timezone/v2",
                },
            },
            Locations: {
                Multiple: "@lobbie/locations/multiple/ids",
            },
            Forms: {
                FormStatus: "@lobbie/session/forms/filter/status",
                NumberRow: "@lobbie/staff/forms/NumberRow",
                DateRange: "@lobbie/staff/forms/DateRange",
                FormTemplateNames: "@lobbie/staff/forms/FormTemplateNames",
                LocationIds: "@lobbie/staff/forms/LocationIds",
                PractitionerId: "@lobbie/staff/forms/PractitionerId",
                PatientName: "@lobbie/staff/patients/PatientName",
                PageNumber: "@lobbie/staff/forms/PageNumber",
                SortField: "@lobbie/staff/forms/SortField",
                SortDirection: "@lobbie/staff/forms/SortDirection",
                CSV: {
                    IS_RESPECT_LOCATION_FILTER: "@lobbie/staff/forms/csv/isRespectLocationFilter",
                    IS_RESPECT_PATIENT_SEARCH_FILTER:
                        "@lobbie/staff/forms/csv/isRespectPatientSearch",
                    IS_RESPECT_TEMPLATE_FILTER: "@lobbie/staff/forms/csv/isRespectTemplatesFilter",
                    IS_RESPECT_PRACTITIONERS_FILTER:
                        "@lobbie/staff/forms/csv/isRespectPractitionersFilter",
                    IS_RESPECT_STATUS_FILTER: "@lobbie/staff/forms/csv/isRespectStatusFilter",
                    IS_RESPECT_DUE_DATE_FILTER: "@lobbie/staff/forms/csv/isRespectDueDateFilter",
                    IS_SHOW_CURRENT_AND_ARCHIVED_FILTER:
                        "@lobbie/staff/forms/csv/isShowCurrentAndArchived",
                    IS_INCLUDE_FORM_ANSWERS_FILTER: "@lobbie/staff/forms/csv/isIncludeFormAnswers",
                    IS_INCLUDE_FORM_ANSWERS_METADATA:
                        "@lobbie/staff/forms/csv/isIncludeFormAnswersMetadata",
                    IS_SINGLE_CSV: "@lobbie/staff/forms/csv/isSingleCSV",
                    IS_SELECTED_FORM_GROUPS: "@lobbie/staff/forms/csv/isRespectSelectedFormGroups",
                    IS_RESPECT_PAGINATION: "@lobbie/staff/forms/csv/isRespectPagination",
                },
            },
            Patients: {
                Details: {
                    Section: "@lobbie/staff/patients/details/Section",
                    FormId: "@lobbie/staff/patients/details/FormId",
                },
            },
        },
        Patient: {
            Forms: {
                Token: "@lobbie/patient/forms/Token",
            },
        },
        Settings: {
            Integrations: {
                AppointmentTypes: {
                    ShowConfirmationModal:
                        "@lobbie/settings/integrations/appointment_types/show_confirmation_modal",
                },
                Locations: {
                    ShowConfirmationModal:
                        "@lobbie/settings/integrations/locations/show_confirmation_modal",
                },
                FormTemplates: {
                    ShowConfirmationModal:
                        "@lobbie/settings/integrations/form_templates/show_confirmation_modal",
                },
            },
            PractitionerScheduling: {
                View: "@lobbie/settings/practitioner_scheduling/PractitionersSchedulingView",
            },
        },
    },
};
