/** @format */
import { ErrorMessage } from "@hookform/error-message";
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { Button, ProgressBar } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import BrandLogo from "src/components/shared/elements/BrandLogo";
import ImageDrop from "src/components/shared/elements/files/ImageDrop";

import LobbieSpinner from "src/components/shared/loading/LobbieSpinner";
import LobbieErrorMessage from "src/components/shared/LobbieErrorMessage";
import { useFormTemplate } from "src/hooks/forms/formTemplate/useFormTemplate";
import { useFormTemplates } from "src/hooks/forms/formTemplate/useFormTemplates";

import { IBranding } from "lobbie";
import LobbieCheckboxV2 from "src/components/shared/LobbieCheckboxV2";

import FullScreenLoadingSpinner from "src/components/shared/loading/FullScreenLoadingSpinner";
import { useBrandingLogoImageV2 } from "src/hooks/branding/useBrandingLogoImageV2";
import { useBrandingLogoReset } from "src/hooks/branding/useBrandingLogoReset";
import { useBrandingRedux } from "src/hooks/branding/useBrandingRedux";
import { useLobbieLocation } from "src/hooks/locations/useLobbieLocation";
import { useLobbieLocationId } from "src/hooks/locations/useLobbieLocationId";
import { useIsSuperAdmin } from "src/hooks/user";
import { isEmptyObject, logDev, notify } from "src/utils";
import { LOBBIE_COLORS } from "src/utils/colors";
import { retry } from "src/utils/lazy";

const ResetBrandingWarningModal = lazy(() => retry(() => import("./ResetBrandingWarningModal")));
const FormBuilderPreviewModal = lazy(() =>
    retry(() => import("src/components/shared/forms/builder/preview/FormBuilderPreviewModal")),
);

interface IProps {
    isLocationBrandingActive: boolean;
    getBranding: () => void;
}

const ConfigBrandingLogo: React.FC<IProps> = ({ isLocationBrandingActive, getBranding }) => {
    const { reset } = useFormContext();
    const [, dispatchLocation] = useLobbieLocation();
    const lobbieLocationId = useLobbieLocationId();
    const isSuperAdmin = useIsSuperAdmin();
    const [, dispatchBranding] = useBrandingRedux();
    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const baseRoute = useMemo(
        () => (isSuperAdmin ? "/admin/branding/logo" : "/staff/branding/logo"),
        [isSuperAdmin],
    );

    const [isLoading, setLoading] = useState<boolean>(false);
    const { templates } = useFormTemplates({ isSkipInitialLoad: false });
    const [previewTemplate, , getPreviewTemplate] = useFormTemplate();

    const { isLogoBrandingReset, reresetLogo, handleSetLogoBrandingReset } = useBrandingLogoReset({
        baseRoute,
        reset,
        setLoading,
    });

    const watchLogoName = watch("logoName");
    const watchLogoS3Url = watch("logoS3Url");
    const watchIsUsingCustomLogoImage = watch("isUsingCustomLogoImage");
    const watchLogoCircleColor = watch("logoCircleColor");
    const watchLogoLetterColor = watch("logoLetterColor");

    const { imagePreviewUrl, progress, onFileDrop, handleClearImageLogo } = useBrandingLogoImageV2({
        baseRoute,
        getBranding,
        setValue,
        watchLogoS3Url,
        isLocationBrandingActive,
    });

    useEffect(() => {
        return () => {
            if (imagePreviewUrl) {
                try {
                    logDev(
                        "ConfigBrandingLogo.useEffect - revoke imagePreviewUrl -",
                        imagePreviewUrl,
                    );
                    URL.revokeObjectURL(imagePreviewUrl);
                } catch {}
            }
        };
    }, [imagePreviewUrl]);

    const handleShowPreview = useCallback(
        (templateId?: number) => {
            if (templateId) {
                dispatchBranding({
                    logoName: watchLogoName,
                    logoS3Url: watchLogoS3Url,
                    isUsingCustomLogoImage: watchIsUsingCustomLogoImage,
                    logoCircleColor: watchLogoCircleColor,
                    logoLetterColor: watchLogoLetterColor,
                } as IBranding);
            } else {
                // * NOTE: Dispatching location sets the redux branding associated with the location, whether it's location or system-based
                dispatchLocation(lobbieLocationId, undefined);
            }
            getPreviewTemplate(templateId);
        },
        [
            getPreviewTemplate,
            dispatchBranding,
            watchLogoName,
            watchLogoS3Url,
            watchIsUsingCustomLogoImage,
            watchLogoCircleColor,
            watchLogoLetterColor,
            dispatchLocation,
            lobbieLocationId,
        ],
    );

    const onLetterColorInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setValue("logoLetterColor", newValue);
        },
        [setValue],
    );

    const onCircleColorInputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = event.target.value;
            setValue("logoCircleColor", newValue);
        },
        [setValue],
    );

    const handleInFormLogoPreview = useCallback(() => {
        if (Array.isArray(templates) && !isEmptyObject(templates.first())) {
            handleShowPreview(templates?.first()?.id);
        } else {
            notify({
                level: "error",
                title: "No available form template.",
                message: "Lobbie does not have any form templates to preview for this account.",
            });
        }
    }, [templates, handleShowPreview]);

    const handleChangeUsingCustomLogo = useCallback(() => {
        setValue("isUsingCustomLogoImage", !watchIsUsingCustomLogoImage);
    }, [watchIsUsingCustomLogoImage, setValue]);

    return (
        <div>
            <div className="row user-select-none mb-2">
                <div className="col">
                    <h4 className="normal">Logo</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-12">
                    <Form.Group className="logo-option">
                        <LobbieCheckboxV2
                            onClick={handleChangeUsingCustomLogo}
                            checked={watchIsUsingCustomLogoImage}
                            label={
                                <span
                                    id="logoOptionSwitch"
                                    className="pointer bold"
                                >{`Use a custom logo image${
                                    !lobbieLocationId ? " (select a Location to enable)" : ""
                                }`}</span>
                            }
                            disabled={!lobbieLocationId}
                        />
                    </Form.Group>
                </div>
            </div>
            {watchIsUsingCustomLogoImage ? (
                <div className="row border-top border-bottom">
                    <div className="col bg-white p-4 mr-4">
                        {isLoading ? (
                            <LobbieSpinner />
                        ) : imagePreviewUrl || watchLogoS3Url ? (
                            <img
                                style={{
                                    maxWidth: 300,
                                    maxHeight: 70,
                                }}
                                src={imagePreviewUrl || watchLogoS3Url}
                                alt={"Account Logo"}
                            />
                        ) : (
                            <small
                                id="branding-preview"
                                className="text-secondary user-select-none"
                            >
                                {lobbieLocationId
                                    ? "Logo preview"
                                    : "Select a Location to view the logo image."}
                            </small>
                        )}
                    </div>
                    <div className="col py-3 bg-light">
                        <div className="row g-0">
                            <Form.Group controlId="logoImageS3ObjectPath" className="mr-2 col">
                                <div className="row g-0 mb-1">
                                    <ImageDrop
                                        name={"logoImageS3ObjectPath"}
                                        onChange={onFileDrop}
                                        message={
                                            imagePreviewUrl || watchLogoS3Url
                                                ? "Replace logo"
                                                : "Upload logo"
                                        }
                                        isButton={true}
                                    />
                                </div>
                                <div className={`row g-0 mb-1 ${progress ? "" : "invisible"}`}>
                                    <ProgressBar
                                        className="w-100"
                                        now={progress}
                                        label={`${progress.toFixed(1)}%`}
                                    />
                                </div>
                                <ErrorMessage
                                    as={LobbieErrorMessage}
                                    errors={errors}
                                    name={"logoImageS3ObjectPath"}
                                />
                            </Form.Group>
                            <Form.Group>
                                <button
                                    id="branding-reset"
                                    type="button"
                                    onClick={handleClearImageLogo}
                                    className="shadow-none border bg-white rounded px-3 py-1"
                                    disabled={!imagePreviewUrl && !watchLogoS3Url}
                                >
                                    <span
                                        className={
                                            imagePreviewUrl || watchLogoS3Url
                                                ? "blue small semi-bold"
                                                : "small semi-bold"
                                        }
                                    >
                                        Clear logo
                                    </span>
                                </button>
                            </Form.Group>
                        </div>
                        <small className="user-select-none mt-2">
                            Your image must be a PNG, JPG, JPEG, or BMP file under 25 MB.
                        </small>
                        <small className="user-select-none">
                            Image of size 70 * 100 pixels recommended.
                        </small>
                        <div className="mt-3" />
                        <Form.Group className="mt-3">
                            <Form.Label>Logo Name (Max 40 characters)</Form.Label>
                            <Form.Control
                                {...register("logoName")}
                                type="text"
                                maxLength={40}
                                placeholder="Enter logo name"
                            />
                            <Form.Text className="text-muted">
                                This name is automatically set when you upload an image, but you can
                                edit it here.
                            </Form.Text>
                            <ErrorMessage
                                as={LobbieErrorMessage}
                                errors={errors}
                                name={"logoName"}
                            />
                        </Form.Group>
                    </div>
                </div>
            ) : (
                <div className="row">
                    <Form.Group className="col-4">
                        <Form.Label>Name on the logo: *</Form.Label>
                        <Form.Control
                            {...register("logoName")}
                            type="text"
                            id="logoName"
                            name="logoName"
                            placeholder="Name on the logo"
                        />
                        <ErrorMessage as={LobbieErrorMessage} errors={errors} name={"logoName"} />
                    </Form.Group>
                    <Form.Group className="col-2 user-select-none mr-3">
                        <Form.Label>Circle color:</Form.Label>
                        <div className="row border rounded">
                            <div className="col-8 p-0">
                                <Form.Control
                                    {...register("logoCircleColor")}
                                    className="border-0 rounded w-100"
                                    type="text"
                                    id="logoCircleColor"
                                    name="logoCircleColor"
                                    placeholder="Circle color"
                                />
                            </div>
                            <div className="col-4 p-0">
                                <input
                                    className="form-control p-0 border-0 rounded pointer h-100 w-100"
                                    type="color"
                                    onChange={onCircleColorInputChange}
                                    value={watchLogoCircleColor || LOBBIE_COLORS.primary}
                                />
                            </div>
                        </div>

                        <ErrorMessage
                            as={LobbieErrorMessage}
                            errors={errors}
                            name={"logoCircleColor"}
                        />
                    </Form.Group>
                    <Form.Group className="col-2 user-select-none mr-3">
                        <Form.Label>Letter color:</Form.Label>
                        <div className="row border rounded">
                            <div className="col-8 p-0">
                                <Form.Control
                                    {...register("logoLetterColor")}
                                    className="border-0 rounded w-100"
                                    type="text"
                                    id="logoLetterColor"
                                    name="logoLetterColor"
                                    placeholder="Letter"
                                />
                            </div>
                            <div className="col-4 p-0">
                                <input
                                    className="form-control p-0 border-0 rounded pointer h-100 w-100"
                                    type="color"
                                    onChange={onLetterColorInputChange}
                                    value={watchLogoLetterColor || LOBBIE_COLORS.white}
                                />
                            </div>
                        </div>
                        <ErrorMessage
                            as={LobbieErrorMessage}
                            errors={errors}
                            name={"logoLetterColor"}
                        />
                    </Form.Group>
                    <div className="col">
                        <Form.Label>Preview:</Form.Label>
                        {watchLogoName && (
                            <BrandLogo
                                logoName={watchLogoName}
                                logoCircleColor={watchLogoCircleColor}
                                logoLetterColor={watchLogoLetterColor}
                                isPrinting={false}
                            />
                        )}
                    </div>
                </div>
            )}

            <div className="row g-0 py-2">
                <div className="col-8">
                    <span className="link" onClick={handleInFormLogoPreview}>
                        Form preview
                    </span>
                </div>
                <div className="col-4 text-right">
                    <span>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={handleSetLogoBrandingReset}
                        >
                            Reset Logo Branding
                        </Button>
                    </span>
                </div>
            </div>
            {isLogoBrandingReset && (
                <Suspense fallback={<FullScreenLoadingSpinner />}>
                    <ResetBrandingWarningModal
                        showModal={isLogoBrandingReset}
                        handleModalShowHide={handleSetLogoBrandingReset}
                        handleContinue={reresetLogo}
                        message={
                            <>
                                <div className="row">
                                    <div className="col">
                                        This will reset all your logo branding across all forms and
                                        other resources throughout Lobbie.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        Any stored images will also be deleted.
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Suspense>
            )}
            {previewTemplate && (
                <Suspense fallback={<FullScreenLoadingSpinner />}>
                    <FormBuilderPreviewModal
                        formTemplateName={previewTemplate.name}
                        formElements={previewTemplate.getAllFormElementsForPreview()}
                        screen="computer"
                        showModal={!!previewTemplate}
                        hideModal={handleShowPreview}
                        isShowColumnWidthHeaders={false}
                    />
                </Suspense>
            )}
        </div>
    );
};

export default ConfigBrandingLogo;
