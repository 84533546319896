import LobbieSpinner from "./LobbieSpinner";

const CenteredLoadingSpinner = ({
    message,
    direction,
    small,
    style,
    isHidden,
}: {
    message?: string;
    direction?: "column" | "row";
    small?: boolean;
    style?: React.CSSProperties;
    isHidden?: boolean;
}) => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: direction || "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "0 auto",
                visibility: isHidden ? "hidden" : "visible",
                ...style,
            }}
        >
            {message && <span className="blue">{message}</span>}
            {direction === "row" && <span>&nbsp;</span>}
            <LobbieSpinner small={small} isHidden={isHidden} />
        </div>
    );
};

export default CenteredLoadingSpinner;
