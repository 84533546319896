export const LOBBIE_COLORS = {
    primaryDark: "#0026ca",
    primary: "#2b46ff",
    primaryLight: "#e7e9ff",
    primaryLighter: "#f3f4ff",
    black: "#000000",
    secondaryBlack: "#242424",
    secondaryDarkest: "#242424",
    secondaryDarker: "#454a61",
    secondaryDark: "#65697a",
    secondary: "#D7DEFF",
    secondaryLight: "#eaeaea",
    secondaryLighter: "#f5f5f5",
    secondaryLightest: "#f8f8f8",
    secondaryWhite: "#f8f8f8",
    successDark: "#003314",
    success: "#2ca931",
    successLight: "#E8F9D7",
    warningDark: "#EED202",
    warning: "#ffd600",
    warningLight: "#fff7cc",
    dangerDark: "#9b0000",
    danger: "#cb0404",
    dangerLight: "#f1cbcb",
    dangerLighter: "#fbdbdb",
    white: "#ffffff",
};

export const EVV_COLORS = {
    checkedOut: "#0026ca",
    checkedOutTransparant: "#2B46FF0D",
    checkedInMoreTransparant: "#5FDBA71A",
    checkedInTransparant: "#5FDBA70D",
    checkedInLight: "#e6fff2",
    checkedIn: "#5FDBA7",
    checkedInDark: "#127448",
    checkedInDarker: "#0F5F30",
    late: "#9b0000",
    missed: "#E3DC23",
    missedDarker: "#847700",
    white: "#ffffff",
    blackTransparant: "#0000000D",
    blackMoreTransparant: "#00000003",
    newLocation: "#9A002c",
    newLocationLight: "#F5CDCD",
    okLocationMoreTranparant: "#00A5041A",
    okLocation: "#006703",
};

export const INTEGRATION_COLORS = {
    connected: "#D1EBC1",
};
