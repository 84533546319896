import { AxiosError, AxiosResponse } from "axios";
import { ILocation, IValidationResult } from "lobbie";
import { useCallback } from "react";
import { DEFAULT_ERROR_MESSAGE } from "src/constants";
import { handleError, isFailedRequest, notify } from "src/utils";
import { useAxiosGetter } from "../useAxios";
import { useIsSuperAdmin } from "../user";

export const useCurrentUserLocations = () => {
    const getter = useAxiosGetter();
    const isSuperAdmin = useIsSuperAdmin();
    return useCallback(
        (showInactive: boolean) => {
            const route = isSuperAdmin
                ? `/admin/locations?inactive=${showInactive}`
                : `/staff/location?inactive=${showInactive}`;

            return getter(route)
                .then((response: AxiosResponse | void) => {
                    const result = response && (response.data as ILocation[] | IValidationResult);
                    if (!result) {
                        // notify({
                        //     level: "error",
                        //     title: "Error loading the Lobbie locations you are assigned to.",
                        //     message: DEFAULT_ERROR_MESSAGE,
                        // });
                        // return [];
                        return [];
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to load the Lobbie locations you are assigned to.",
                            message:
                                (result as IValidationResult)?.message || DEFAULT_ERROR_MESSAGE,
                        });
                        return [];
                    } else {
                        return result as ILocation[];
                    }
                })
                .catch((error: AxiosError) => {
                    handleError(error);
                    return [];
                });
        },
        [getter, isSuperAdmin],
    );
};
