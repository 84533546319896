import {
    ActionCreatorWithPayload,
    ActionCreatorWithoutPayload,
    createAction,
} from "@reduxjs/toolkit";
import { IFileUploadProgress, IForm, patients } from "lobbie";
import { TFormElementValue } from "src/components/shared/forms/utils";

export const resetForm = createAction("form/reset") as ActionCreatorWithoutPayload;
export const setForm = createAction<IForm | undefined>("form/set") as ActionCreatorWithPayload<
    IForm | undefined
>;

export const addFormElementAnswer = createAction<{
    formId: number;
    formElementId: number;
    value: TFormElementValue;
}>("formElementAnswers/add");

export const updateFormElementAnswerArray = createAction<{
    formId: number;
    formElementId: number;
    value: IFileUploadProgress[];
}>("formElementAnswers/updateArray");

export const setFormElementAnswers =
    createAction<Record<number, Record<number, TFormElementValue>>>("formElementAnswers/set");

export const setFormStorageKey = createAction<string | undefined>("formStorageKey/set");

export const setFormAnonymousAccountPatient = createAction<
    patients.IAccountPatientLite | undefined
>("formAnonymousAccountPatient/set");

export const setFormWidth = createAction<number | undefined>("formWidth/set");

export const setFormTemplateSectionIndex = createAction<number | undefined>(
    "formTemplateSectionIndex/set",
);

export const setFormTemplateSectionScrolledId = createAction<number | undefined>(
    "formTemplateSectionScrolledId/set",
);
