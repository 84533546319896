import { AxiosResponse } from "axios";
import { IValidationResult, IBranding } from "lobbie";
import { useState, useCallback } from "react";
import { DEFAULT_ERROR_MESSAGE } from "src/constants";
import { useAxiosPutter } from "src/hooks/useAxios";
import { notify, isFailedRequest, handleError } from "src/utils";

interface IProps {
    baseRoute: string;
    reset: (b: IBranding) => void;
    setLoading: (load: boolean) => void;
}
export const useBrandingLogoReset = ({ baseRoute, reset, setLoading }: IProps) => {
    const putter = useAxiosPutter();

    const [isLogoBrandingReset, setLogoBrandingReset] = useState<boolean>(false);
    const handleSetLogoBrandingReset = useCallback(() => {
        setLogoBrandingReset((current) => !current);
    }, []);

    const handleBrandingReset = useCallback(
        (locationId?: number) => {
            setLoading(true);
            const route = `${baseRoute}/reset`;
            putter(route, locationId ? { locationId } : {})
                .then((response: AxiosResponse | void) => {
                    const result = response && (response.data as IValidationResult | IBranding);
                    if (!result) {
                        response?.status &&
                            response.status > 399 &&
                            notify({
                                level: "error",
                                title: "Error resetting logo branding.",
                                message: DEFAULT_ERROR_MESSAGE,
                            });
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to reset logo branding.",
                            message: (result as IValidationResult).message || DEFAULT_ERROR_MESSAGE,
                        });
                        return;
                    } else {
                        reset(result as IBranding);
                        notify({
                            level: "success",
                            title: "Reset logo branding.",
                        });
                    }
                })
                .catch(handleError);
        },
        [putter, baseRoute, reset, setLoading],
    );

    return {
        isLogoBrandingReset,
        reresetLogo: handleBrandingReset,
        handleSetLogoBrandingReset,
    };
};
