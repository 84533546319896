import { createReducer } from "@reduxjs/toolkit";
import { IStaffSchedulerDate } from "lobbie";
import * as actions from "../actions/staffSchedulerActions";
const initialState: any = {};

export const staffSchedulerReducer = createReducer(initialState, (builder) => {
    builder.addCase(
        actions.setStaffSchedulerDate,
        (state, action: { payload: IStaffSchedulerDate | undefined }) => {
            return {
                ...state,
                staffSchedulerDate: action.payload,
            };
        },
    );
});
