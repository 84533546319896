import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { IPractitioner } from "lobbie";

export const addPractitioners = createAction<IPractitioner[] | undefined>(
    "practitioners/add",
) as ActionCreatorWithPayload<IPractitioner[] | undefined>;

export const setPractitioners = createAction<IPractitioner[] | undefined>(
    "practitioners/set",
) as ActionCreatorWithPayload<IPractitioner[] | undefined>;
