import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { EFileUploadStatus } from "src/constants";

export const setReduxLoading = createAction("setReduxLoading") as ActionCreatorWithPayload<boolean>;
export const setLoadingFileUpload = createAction(
    "setLoadingFileUpload",
) as ActionCreatorWithPayload<EFileUploadStatus>;
export const setLoadingFileDownload = createAction(
    "setLoadingFileDownload",
) as ActionCreatorWithPayload<EFileUploadStatus>;
