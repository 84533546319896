/** @format */

import { createReducer } from "@reduxjs/toolkit";
import {
    setIntegrationWizard,
    setIntegrationWizardSyncableObjects,
} from "../actions/integrationWizardActions";
import { IReduxIntegrationWizardState } from "../store";

const initialState: any = {
    appointmentTypes: [],
    locations: [],
    practitioners: [],
    syncable: {
        appointmentTypes: {},
        locations: {},
        practitioners: {},
        accountPatients: {},
        appointments: {},
    },
};

export const integrationWizardReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(setIntegrationWizard, (state: IReduxIntegrationWizardState, action) => {
            return {
                ...state,
                ...action.payload,
            };
        })
        .addCase(
            setIntegrationWizardSyncableObjects,
            (state: IReduxIntegrationWizardState, action) => {
                return {
                    ...state,
                    syncable: {
                        ...state.syncable,
                        ...action.payload,
                    },
                };
            },
        );
});
