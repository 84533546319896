// eslint-disable-next-line
export enum EUserType {
    Patient,
    Staff,
    SuperAdmin,
}

export const USER_TYPES = {
    [EUserType.Patient]: "patient",
    [EUserType.Staff]: "staff",
    [EUserType.SuperAdmin]: "superadmin",
};

// 8-50 characters, 1 numnber, 1 special character
// https://stackoverflow.com/a/53778084/6410635
export const VALID_PASSWORD_REGEX = new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z])(?=\\S+$).{8,50}$"); // NOSONAR

export const VALID_PASSWORD_MESSAGE =
    "Password must be between 8 and 50 characters and include at least 1 digit and 1 special character.";
