/* eslint-disable */

import { ComponentType } from "react";

// https://dev.to/goenning/how-to-retry-when-react-lazy-fails-mb5
export function retry(
    fn: () => Promise<{ default: ComponentType<any> }>,
    retriesLeft = 5,
    interval = 1000,
): Promise<{ default: ComponentType<any> }> {
    return new Promise((resolve, reject) => {
        fn()
            .then(resolve)
            .catch((error: Error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}
