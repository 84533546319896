/** @format */

import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError, AxiosResponse } from "axios";
import { IValidationResult } from "lobbie";
import { BaseSyntheticEvent, useCallback } from "react";
import { NavLink } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { BASE_AUTH_API_ROUTE, ROUTES, SUPERADMIN_DOMAINS } from "src/constants";
import { useAxiosPublicPost } from "src/hooks/useAxios";
import { useIsSuperAdmin, useLogoutNoRedirect } from "src/hooks/user";
import {
    HTTP_STATUS_CODES,
    handleError,
    isEmptyObject,
    isFailedRequest,
    logDev,
    notify,
    removeAllUserSessionData,
} from "src/utils";
import * as yup from "yup";
import FormFieldError from "../forms/FormFieldError";
import { TLoginReturn } from "./utils";

interface IProps {
    onSubmit: (data: TLoginReturn | IValidationResult) => void;
    setSubmitting: (submitting: boolean) => void;
    setSelectedForm?: (form: "signup" | "login") => void;
    isSubmitting: boolean;
    isPasswordResetEnabled: boolean;
    email?: string;
    accountPublicUuid?: string;
}

interface ILoginData {
    email: string;
    password: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

const LoginForm: React.FC<IProps> = ({
    onSubmit,
    setSubmitting,
    setSelectedForm,
    isSubmitting,
    isPasswordResetEnabled,
    email,
    accountPublicUuid,
}) => {
    const { push } = useHistory();
    const isSuperAdmin = useIsSuperAdmin();
    const poster = useAxiosPublicPost();
    const logout = useLogoutNoRedirect();

    const defaultValues: ILoginData = {
        email: email || "",
        password: "",
    };
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<any>({
        resolver: yupResolver(VALIDATION_SCHEMA),
        defaultValues,
    });

    const watchEmail = watch("email", "");

    const handleNavigateForgotPassword = useCallback(() => {
        push(ROUTES.forgotPassword, { email: watchEmail });
    }, [push, watchEmail]);

    const submitter = useCallback(
        async (data: ILoginData) => {
            setSubmitting(true);

            const route = SUPERADMIN_DOMAINS.includes(window.location.host)
                ? "api/admin/login"
                : `${BASE_AUTH_API_ROUTE}/login`;

            await poster(route, { ...data, accountPublicUuid }, (error: AxiosError) => {
                const status = error?.response?.status;
                if (status && status > 399 && status < 500) {
                    if (status === HTTP_STATUS_CODES.NOT_ACCEPTABLE) {
                        notify({
                            level: "error",
                            title: "Recaptcha Error",
                            message: "Please try again. You may need to refresh the page.",
                        });
                    } else if (status === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
                        logDev("handleHttpError: Handling HTTP Status Code 422");
                        removeAllUserSessionData();
                        fetch(`${BASE_AUTH_API_ROUTE}/logout`, {
                            method: "POST",
                            body: JSON.stringify({}),
                        })
                            .then(() => {
                                notify({
                                    level: "warning",
                                    title: "Issue signing in.",
                                    message:
                                        "Please try refreshing the page and signing into Lobbie again.",
                                });
                            })
                            .catch(console.error);
                    } else {
                        notify({
                            level: "error",
                            title: "Invalid",
                            message: "Invalid credentials.",
                        });
                    }
                    removeAllUserSessionData(true);
                }
            })
                .then((response: AxiosResponse | void) => {
                    setSubmitting(false);
                    const result = response && (response.data as TLoginReturn | IValidationResult);
                    if (isFailedRequest(result as IValidationResult)) {
                        notify({
                            level: "error",
                            title: "Failed to log in.",
                            message: (result as IValidationResult).message || "",
                        });
                        removeAllUserSessionData(true);
                        onSubmit(result as IValidationResult);
                    } else {
                        onSubmit(result as TLoginReturn);
                    }
                })
                .catch((error) => {
                    handleError(error);
                    removeAllUserSessionData(true);
                    setSubmitting(false);
                });
        },
        [poster, accountPublicUuid, onSubmit, setSubmitting],
    );

    const formSubmit = useCallback(
        async (e: BaseSyntheticEvent<any, any, any>) => {
            e.preventDefault();
            e.stopPropagation();
            await logout().catch(console.error);
            await handleSubmit(submitter)(e);
        },
        [submitter, handleSubmit, logout],
    );

    !isEmptyObject(errors) && logDev("LoginForm Errors", errors);

    return (
        <Form onSubmit={formSubmit} id="login-form">
            <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                    {...register("email")}
                    name="email"
                    type="email"
                    placeholder="Enter email"
                    isInvalid={!!errors.email?.message}
                    disabled={isSubmitting}
                    autoComplete="email"
                />
                <FormFieldError message={errors.email?.message} />
            </Form.Group>
            <Form.Group controlId="password" className="pt-2">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    {...register("password")}
                    name="password"
                    type="password"
                    placeholder="Password"
                    isInvalid={!!errors.password?.message}
                    disabled={isSubmitting}
                    autoComplete="current-password"
                />
                {isPasswordResetEnabled && (
                    <NavLink
                        onClick={handleNavigateForgotPassword}
                        className="float-right pt-0 pr-0 link blue"
                        tabIndex={-1}
                        disabled={isSubmitting}
                    >
                        Forgot your password?
                    </NavLink>
                )}
                <FormFieldError message={errors.password?.message} />
            </Form.Group>
            <Button
                variant="primary"
                className="my-2 px-5 w-100"
                type="submit"
                data-testid="login-button"
                disabled={isSubmitting}
            >
                Login
            </Button>
            {!isSuperAdmin && setSelectedForm && (
                <Button
                    variant="outline-primary"
                    className="m-3"
                    type="button"
                    data-testid="signup-button"
                    onClick={() => setSelectedForm("signup" as const)}
                    disabled={isSubmitting}
                >
                    Sign Up
                </Button>
            )}
        </Form>
    );
};

export default LoginForm;
