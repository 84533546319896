// eslint-disable-next-line
export enum EFormElementTypes {
    Null = 0,
    TextArea = 1,
    Checkbox = 2,
    Text = 3,
    Select = 4,
    Radio = 5,
    Date = 6,
    PhoneNumber = 7,
    Zipcode = 8,
    Email = 9,
    SocialSecurityNumber = 10,
    Container = 11,
    FormattedText = 12,
    Signature = 13,
    Time = 14,
    Initials = 15,
    ImagePhotoOrAttachment = 16,
    SignatureDate = 17,
    FileAttachment = 18,
    YesNo = 19,
    TypedDate = 20,
    State = 21,
    Consent = 22,
    AnnotatedBody = 23,
    Address = 24,
    Body = 25,
    PageBreak = 26,
    Invisible = 27,
    DrawableBody = 28,
    DrawableMamogram = 29,
    DrawableSignature = 30,
    DrawableInitials = 31,
    DrawableImage = 32,
    CalculatedSum = 33,
    ExternalAPISearch = 34,
    ExternalAPISelect = 35,
    Number = 36,
    ZipCodeCanada = 37,
    CheckboxGroup = 38,
    SquarePayment = 39,
    StripePayment = 40,
    MultipleFileUpload = 41,
    Photo = 42,
    EligibilityCheck = 43,
    ServiceCharge = 44,
    ThirdPartyAPI = 45,
    FormCompleteAction = 46,
}

// eslint-disable-next-line
export enum EFormSendStep {
    Recipients = 1,
    Selection = 2,
    Confirm = 3,
    Review = 4,
    PreFill = 5,
    DueDate = 6,
    DeliveryMechanism = 7,
}

// eslint-disable-next-line
export enum EFormDeliveryMechanism {
    InOfficeQRCode = 1,
    OutOfOfficeEmailSMS = 2,
}

// eslint-disable-next-line
export enum EFormDateRange {
    Next24,
    Today,
    TodayTomorrow,
    ThisWeek,
    NextWeek,
    All,
    Custom,
    CompletedToday,
    CompletedThisWeek,
    CompletedLastWeek,
}

// eslint-disable-next-line
export enum EFormStatus {
    Complete,
    Incomplete,
    All,
}

export const FormStatuses = [EFormStatus.Complete, EFormStatus.Incomplete, EFormStatus.All];

// eslint-disable-next-line
export enum EFormPrintUser {
    Patient = "patient",
    Staff = "staff",
}
