import { IFormTemplate, IFormTemplateLabel, IFormTemplateSection, pdf } from "lobbie";
import { IFormElementWithSection } from "src/components/shared/forms/builder/utils";

class FormTemplate implements IFormTemplate {
    readonly id: number;
    readonly formTemplateId: number;

    /**
     * The ID of the FormTemplate, managed by Lobbie, that this FormTemplate was duplicated from.
     */
    readonly lobbieFormTemplateId?: number;

    name: string;
    description: string;
    formTemplatePDF: pdf.IFormTemplatePDF;
    formTemplateSections: IFormTemplateSection[];
    formType: number;
    readonly publicUuid: string;
    readonly publicLink?: string;
    readonly formTemplateLabel?: IFormTemplateLabel;
    readonly active: boolean;
    readonly isFillablePDFOnly: boolean;
    readonly isStaffOnly: boolean;
    readonly version: number;
    readonly versionId: number;
    readonly versionUpdatedOnISO: string;
    readonly sortOrder: number;
    readonly staticUuid: string;
    readonly lastUpdatedOn: string;
    readonly adminEnabled: boolean;
    readonly lobbieIntegrationUuid: string;
    public constructor(template: IFormTemplate) {
        this.id = template.id;
        this.formTemplateId = template.id;
        this.lobbieFormTemplateId = template.lobbieFormTemplateId;
        this.name = template.name;
        this.publicUuid = template.publicUuid;
        this.publicLink = template.publicLink;
        this.description = template.description;
        this.formTemplatePDF = template.formTemplatePDF;
        this.formTemplateSections = template?.formTemplateSections || [];
        this.formType = template.formType;
        this.formTemplateLabel = template.formTemplateLabel;
        this.active = template.active;
        this.isFillablePDFOnly = template.isFillablePDFOnly;
        this.isStaffOnly = template.isStaffOnly;
        this.version = template.version;
        this.versionId = template.versionId;
        this.versionUpdatedOnISO = template.versionUpdatedOnISO;
        this.sortOrder = template.sortOrder;
        this.staticUuid = template.staticUuid;
        this.lastUpdatedOn = template.lastUpdatedOn;
        this.adminEnabled = template.adminEnabled;
        this.lobbieIntegrationUuid = template.lobbieIntegrationUuid;
    }

    private previewReducer(
        sum: IFormElementWithSection[],
        section: IFormTemplateSection,
    ): IFormElementWithSection[] {
        return [
            ...sum,
            ...section.formElements.map((e) => ({
                ...e,
                sectionName: section.name,
            })),
        ];
    }

    public getAllFormElementsForPreview() {
        return this.formTemplateSections.reduce(
            this.previewReducer,
            [] as IFormElementWithSection[],
        );
    }
}

export default FormTemplate;
