/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { scheduling } from "lobbie";
import { DEFAULT_PATIENT_SCHEDULING } from "src/constants";
import { logDev } from "src/utils";
import * as actions from "../actions/patientSchedulingActions";

const initialState: scheduling.patient.IPatientScheduling = DEFAULT_PATIENT_SCHEDULING;

export const patientSchedulingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(
            actions.setPatientScheduling,
            (
                state: scheduling.patient.IPatientScheduling,
                action: {
                    payload: Partial<scheduling.patient.IPatientScheduling> | null;
                },
            ) => {
                logDev("Redux - updating patient scheduling");
                const newState = {
                    ...state,
                    ...action.payload,
                    animate: !!action.payload?.route,
                    routes: (() => {
                        // Payload includes routes, back button behavior
                        if (action.payload?.routes) {
                            return action.payload?.routes;

                            // Payload includes a route, add it to routes
                        } else if (
                            action.payload?.route &&
                            (state.routes || []).last() !== action.payload.route
                        ) {
                            return (state.routes || []).concat(action.payload.route);
                        } else {
                            return state.routes || [];
                        }
                    })(),
                } as scheduling.patient.IPatientScheduling;

                return newState;
            },
        )
        .addCase(actions.resetPatientScheduling, () => {
            logDev("Redux - resetting patient scheduling");

            try {
                localStorage.clear();
                sessionStorage.clear();
            } catch (error) {
                console.warn(error);
            }

            return initialState;
        });
});
