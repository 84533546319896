import { ILocation } from "lobbie";

import { IAppState } from "src/redux";

export interface IReduxLocationState {
    locationId: number | undefined;
    location: ILocation | undefined;
    locations: ILocation[] | undefined;
    multiLocations: ILocation[] | undefined;
}

export const LOCATIONS_REDUX_STATE = (state: IAppState): IReduxLocationState => state.location;
