import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { IAppointmentType } from "lobbie";

export const setAppointmentTypes = createAction<IAppointmentType[] | undefined>(
    "appointmentTypes/set",
) as ActionCreatorWithPayload<IAppointmentType[] | undefined>;

export const setDefaultAppointmentType = createAction<IAppointmentType | undefined>(
    "defaultAppointmentType/set",
) as ActionCreatorWithPayload<IAppointmentType | undefined>;
