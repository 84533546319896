import { ErrorBoundary } from "@sentry/react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import RenderErrorHandler from "src/app-lobbie/error_handling/RenderErrorHandler";
import { SentryUtil } from "src/utils/sentry";
import App from "./App";
import { onRenderError } from "src/app-lobbie/error_handling/utils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);
dayjs.extend(isBetween);
dayjs.extend(weekOfYear);
dayjs.extend(quarterOfYear);

dayjs.tz.setDefault("UTC");

SentryUtil.init();

// if (import.meta.env.MODE !== "production") {
// const DELAY_MILLISECONDS = 1000;
// const axe = require("@axe-core/react"); // eslint-disable-line
// axe(React, ReactDOM, DELAY_MILLISECONDS);
// import("@axe-core/react")
//     .then((axe: any) => {
//         axe(React, ReactDOM, DELAY_MILLISECONDS);
//     })
//     .catch(console.error);
// }

// React 17
// ReactDOM.render(
//     <StrictMode>
//         <App />
//     </StrictMode>,
//     document.getElementById("root"),
// );

// https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html
// React 18 batching
// eslint-disable-next-line
createRoot(document.getElementById("root")!).render(
    <ErrorBoundary onError={onRenderError} fallback={<RenderErrorHandler />}>
        {/* React.StrictMode forces components to be rendered twice in development
    https://stackoverflow.com/a/60619061/6410635 */}
        <StrictMode>
            <App />
        </StrictMode>
    </ErrorBoundary>,
);

// * NOTE: Don't register service worker here, registered in Header for staff only, for web push notifications
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
