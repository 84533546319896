import { createSelector } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { IPatient, IPerson, IPractitioner, IStaffer } from "lobbie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EUserType } from "src/constants";
import { useLobbieLocationId } from "src/hooks/locations/useLobbieLocationId";
import { useLogoutNoRedirect, useLogoutUser } from "src/hooks/user/useLogout";
import { useReduxUser } from "src/hooks/user/useUser";
import {
    IAppState,
    setLocation,
    setLocationId,
    setLocations,
    setMultiMultiLocations,
} from "src/redux";
import { setAccount } from "src/redux/actions/accountActions";
import { setBranding } from "src/redux/actions/brandingActions";
import { setUser } from "src/redux/actions/userActions";
import {
    getStoredLocationId,
    getStoredMultiLocationsIds,
    getStoredUser,
    getStoredUserType,
    handleError,
    isEmptyObject,
    logDev,
    notify,
    removeStoredUser,
    removeStoredUserType,
    setStoredAccountId,
} from "src/utils";
import { sortById } from "src/utils/sorter";
import { useAxiosGetter } from "../useAxios";

const REDUX_USER_STATE = (state: any): { user: IPerson | undefined } => {
    return state?.user;
};

const staffSelector = createSelector(
    [REDUX_USER_STATE],
    (_userState: { user: IPerson | undefined }) => {
        if (
            _userState.user &&
            (_userState.user.isStaff ||
                _userState.user.isPractitioner ||
                _userState.user.isPatient === false)
        ) {
            return _userState.user;
        }
    },
);

export const useCurrentStafferRedux = (): IStaffer | IPractitioner | undefined => {
    return useSelector(staffSelector) as IStaffer | IPractitioner | undefined;
};

export const useCurrentStafferDefaultLocation = () => {
    return useSelector((state: IAppState) => {
        const accountId = state?.account?.account?.id;
        const locations = state?.location?.locations || [];
        const user = state?.user?.user as IStaffer | IPractitioner | undefined;
        return (
            user?.defaultLocation ||
            locations
                ?.filter((l) => !!getStoredMultiLocationsIds(accountId).includes(l.id))
                ?.first() ||
            locations?.filter((l) => getStoredLocationId(accountId) === l.id)?.first() ||
            sortById(locations ?? []).first()
        );
    });
};

// Only called if there is no patient
export const useCurrentStaffer = (
    patient?: IPatient,
    options?: {
        isForceGetStaffer?: boolean;
    },
): [
    IStaffer | undefined,
    (_user: IPatient | IStaffer | IPractitioner | undefined) => void,
    () => void,
    boolean,
] => {
    const getter = useAxiosGetter();
    const dispatch = useDispatch();
    const user = useReduxUser();
    const locationId = useLobbieLocationId();
    const [logout] = useLogoutUser("/");
    const logoutNoRedirect = useLogoutNoRedirect();
    const [isLoading, setLoading] = useState<boolean>(false);

    const isForceGetStaffer = useMemo(() => options?.isForceGetStaffer, [options]);

    const dispatchCurrentStaffer = useCallback(
        (_user: IPatient | IStaffer | IPractitioner | undefined) => {
            if (!_user) {
                dispatch(setUser(undefined));
            } else {
                dispatch(setUser(_user));
            }
        },
        [dispatch],
    );

    const getCurrentStaffer = useCallback(
        (isSuperadmin?: boolean) => {
            if (getStoredUserType() === EUserType.Patient) {
                logDev(
                    "useCurrentStaffer.getCurrentStaffer - SESSION_USER_TYPE is Patient. Skip getting current staffer",
                );
                return;
            }

            logDev("useCurrentStaffer.getCurrentStaff");
            const route = isSuperadmin ? "/admin/person/current" : "/staff/person/current";

            setLoading(true);
            getter(route)
                .then((response: AxiosResponse | void) => {
                    setLoading(false);
                    const result = response && (response.data as IStaffer | IPractitioner);

                    if (!result) {
                        return;
                    }
                    dispatchCurrentStaffer(result);
                    dispatch(setAccount(result.account));
                    setStoredAccountId(result.account?.id);
                    const storedMultiLocationIds = getStoredMultiLocationsIds(result.account?.id);
                    if (result.locations) {
                        dispatch(setLocations(result.locations));
                        dispatch(
                            setMultiMultiLocations(
                                storedMultiLocationIds
                                    ? result.locations.filter((l) =>
                                          storedMultiLocationIds.includes(l.id),
                                      )
                                    : result.locations,
                            ),
                        );
                        if (!locationId) {
                            const storedLocationId = getStoredLocationId(result.account?.id);
                            const location =
                                result.locations.find((l) => l.id === storedLocationId) ||
                                result.defaultLocation ||
                                result.locations.first();

                            if (location) {
                                dispatch(setLocation(location));
                                dispatch(setLocationId(location.id));
                            }

                            if (
                                location &&
                                (result?.locations ?? []).some(
                                    (l) => l.branding.isLocationBrandingActive,
                                )
                            ) {
                                if (location.branding.isLocationBrandingActive) {
                                    dispatch(setBranding(location.branding));
                                }
                            } else if (result.account || result.locations) {
                                dispatch(
                                    setBranding(
                                        result.account?.branding ||
                                            result.locations.first().branding,
                                    ),
                                );
                            }
                        }
                    } else if (result.account?.branding) {
                        dispatch(setBranding(result.account.branding));
                    }
                })
                .catch((error: AxiosError) => {
                    setLoading(false);
                    if (error?.message?.includes("401")) {
                        return;
                    }
                    handleError(error);
                });
        },
        [dispatch, dispatchCurrentStaffer, getter, locationId],
    );

    useEffect(() => {
        if (isForceGetStaffer) {
            getCurrentStaffer();
        }
    }, [getCurrentStaffer, isForceGetStaffer]);

    useEffect(() => {
        const id = getStoredUser();
        const isPresent = id && id >= 1;
        const isStaff = getStoredUserType() === EUserType.Staff;
        const isSuperadmin = getStoredUserType() === EUserType.SuperAdmin;

        if (isPresent && isStaff && isEmptyObject(patient) && isEmptyObject(user)) {
            getCurrentStaffer(false);
        } else if (isPresent && isSuperadmin && isEmptyObject(patient) && isEmptyObject(user)) {
            getCurrentStaffer(true);
        } else if (!isPresent && !isStaff && !isSuperadmin) {
            removeStoredUser();
            removeStoredUserType();

            if (window.location.pathname !== "/") {
                notify({
                    level: "info",
                    title: "Session expired.",
                    message: "Please log into Lobbie again.",
                    onClick: () => {
                        logout().catch(console.error);
                    },
                    duration: 5000,
                });
                setTimeout(() => {
                    logout().catch(console.error);
                }, 5000);
            } else {
                logoutNoRedirect().catch(console.error);
            }
        }
    }, [patient, user, getCurrentStaffer, logout, logoutNoRedirect]);

    return [user as IStaffer, dispatchCurrentStaffer, getCurrentStaffer, isLoading];
};
