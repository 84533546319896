import { createSelector } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { forms, IAccount, INotificationConfig, IPatient, IValidationResult } from "lobbie";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import Account from "src/classes/Account";
import {
    ACCOUNT_PUBLIC_UUID,
    DEFAULT_ERROR_MESSAGE,
    LOBBIE_ACCOUNT_UUID,
    SELF_SCHEDULING_SETTING_PUBLIC_UUID,
} from "src/constants";
import { useBrandingRedux } from "src/hooks/branding/useBrandingRedux";
import { useAxiosGetter } from "src/hooks/useAxios";
import { setAccount } from "src/redux/actions/accountActions";
import { LOBBIE_COLORS } from "src/utils/colors";
import {
    handleError,
    handleHttpError,
    HTTP_STATUS_CODES,
    isEmptyObject,
    isFailedRequest,
    logDev,
    notify,
    pluralize,
    removeAllUserSessionData,
    sessionGet,
} from "src/utils";
import { useIsSuperAdmin } from "../user";
import { REDUX_ACCOUNT_STATE } from "src/hooks/accounts/accountState";

interface IReduxAccountState {
    account: IAccount | undefined;
    accounts: IAccount[];
    publicUuid: string | undefined;
}

const accountSelector = createSelector(
    [REDUX_ACCOUNT_STATE],
    (_accountState: IReduxAccountState): IAccount | undefined => {
        if (
            _accountState.account &&
            Object.prototype.hasOwnProperty.call(_accountState.account, "account")
        ) {
            // @ts-ignore
            return _accountState.account?.account;
        }
        return _accountState.account;
    },
);

const accountNotificationConfigSelector = createSelector(
    [REDUX_ACCOUNT_STATE],
    (_accountState: IReduxAccountState): INotificationConfig | undefined => {
        if (
            _accountState.account &&
            Object.prototype.hasOwnProperty.call(_accountState.account, "account")
        ) {
            // @ts-ignore
            return _accountState.account?.account.notificationConfig;
        }
        return _accountState.account?.notificationConfig;
    },
);

const accountsSelector = createSelector(
    [REDUX_ACCOUNT_STATE],
    (_accountState: IReduxAccountState) => {
        if (
            _accountState.account &&
            Object.prototype.hasOwnProperty.call(_accountState.account, "accounts")
        ) {
            // @ts-ignore
            return _accountState.account?.accounts;
        }
        return _accountState.accounts;
    },
);

const accountPublicUuidSelector = createSelector(
    [REDUX_ACCOUNT_STATE],
    (_accountState: IReduxAccountState) =>
        _accountState.publicUuid || _accountState.account?.publicUuid,
);

export const useLobbieAccountRedux = (): {
    account: Account;
    accountId: number;
    accountName: string;
    accountPublicUuid: string | null;
    patientFieldName: string;
    practitionerFieldName: string;
    lowerPatientFieldName: string;
    lowerPractitionerFieldName: string;
    lowerPractitionerFieldNamePlural: string;
    claims: boolean;
    isPro: boolean;
    isEVV: boolean;
    isIntegrable: boolean;
} => {
    const reduxAccount = useSelector(accountSelector);
    const accountPublicUuid = useLobbieAccountPublicUuid();
    const account = useMemo(() => new Account(reduxAccount as Account), [reduxAccount]);
    if (account && !account?.publicUuid && accountPublicUuid) {
        account["publicUuid"] = accountPublicUuid;
    }

    const patientFieldName = useMemo(
        () => account?.patientFieldName || (account.isNPSSelfSignup ? "Contact" : "Patient"),
        [account],
    );
    const lowerPatientFieldName = useMemo(() => patientFieldName.toLowerCase(), [patientFieldName]);

    const practitionerFieldName = useMemo(
        () => account?.practitionerFieldName || "Practitioner",
        [account],
    );
    const lowerPractitionerFieldName = useMemo(
        () => practitionerFieldName.toLowerCase(),
        [practitionerFieldName],
    );
    const lowerPractitionerFieldNamePlural = useMemo(
        () => pluralize(lowerPatientFieldName),
        [lowerPatientFieldName],
    );

    const accountId = useMemo(() => account.id, [account]);
    const accountName = useMemo(() => account.name, [account]);
    const isPro = useMemo(() => account.appointmentScheduling, [account]);
    const isEVV = useMemo(() => account.evv, [account]);
    const isIntegrable = useMemo(() => account.integrable, [account]);
    const claims = useMemo(() => account.claims, [account]);

    return {
        account,
        accountId,
        accountName,
        accountPublicUuid,
        patientFieldName,
        practitionerFieldName,
        lowerPatientFieldName,
        lowerPractitionerFieldName,
        lowerPractitionerFieldNamePlural,
        claims,
        isPro,
        isEVV,
        isIntegrable,
    };
};

export const useLobbieAccountLogoRedux = (): forms.IFormAccountLogo => {
    const [branding] = useBrandingRedux();
    const { accountName } = useLobbieAccountRedux();
    return useMemo(
        () => ({
            isUsingCustomLogoImage: branding?.isUsingCustomLogoImage,
            logoImageS3ObjectPath: branding?.logoImageS3ObjectPath,
            logoCircleColor: branding?.logoCircleColor || LOBBIE_COLORS.primary,
            logoLetterColor: branding?.logoLetterColor || LOBBIE_COLORS.white,
            logoName: branding?.logoName || accountName || "Lobbie",
            logoS3Url: branding?.logoS3Url,
        }),
        [branding, accountName],
    ) as forms.IFormAccountLogo;
};

export const useLobbieAccountNotificationConfigRedux = (): INotificationConfig => {
    return useSelector(accountNotificationConfigSelector) as INotificationConfig;
};

export const useLobbieAccountPublicUuid = (): string | null => {
    const search = useLocation().search;
    const searchParamsAccountPublicUuid = useMemo(
        () => new URLSearchParams(search).get(ACCOUNT_PUBLIC_UUID),
        [search],
    );
    const params: { accountPublicUuid: string | undefined } = useParams();
    const uuid = useSelector(accountPublicUuidSelector);
    const account = useSelector(accountSelector);
    const accounts = useSelector(accountsSelector);

    const paramsId = useMemo(() => params.accountPublicUuid, [params]);
    const accountId = useMemo(() => account?.publicUuid, [account]);
    const accountsLength = useMemo(() => accounts.length, [accounts]);
    const firstAccount = useMemo(() => accounts.first(), [accounts]);
    const firstAccountId = useMemo(() => firstAccount?.publicUuid, [firstAccount]);

    return useMemo(() => {
        if (paramsId) return paramsId;
        if (searchParamsAccountPublicUuid) return searchParamsAccountPublicUuid;
        if (uuid) return uuid;
        if (accountId) return accountId;

        if (accountsLength === 1 && firstAccountId) {
            return firstAccountId;
        }

        return sessionGet(ACCOUNT_PUBLIC_UUID);
    }, [paramsId, searchParamsAccountPublicUuid, uuid, accountId, accountsLength, firstAccountId]);
};

export const useLobbieSelfSchedulingSettingPublicUuid = () => {
    const search = useLocation().search;
    const searchParamsSelfSchedulingSettingPublicUuid = useMemo(
        () => new URLSearchParams(search).get(SELF_SCHEDULING_SETTING_PUBLIC_UUID),
        [search],
    );
    const params = useParams() as Record<string, string>;
    const fromParams = useMemo(() => params?.selfSchedulingPublicUuid, [params]);

    return useMemo(
        () =>
            searchParamsSelfSchedulingSettingPublicUuid ||
            fromParams ||
            sessionGet(SELF_SCHEDULING_SETTING_PUBLIC_UUID),
        [fromParams, searchParamsSelfSchedulingSettingPublicUuid],
    );
};

export const usePatientAccounts = ({
    patient,
    accountPublicUuid,
}: {
    patient?: IPatient;
    accountPublicUuid?: string | null;
}): [IAccount[], boolean] => {
    const getter = useAxiosGetter();
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getAccounts = () => {
            if (patient && accountPublicUuid) {
                setLoading(true);
                logDev("usePatientAccounts - getting Account with publicUuid -", accountPublicUuid);
                getter(`/patient/accounts?accountPublicUuid=${accountPublicUuid}`)
                    .then((response) => {
                        setLoading(false);
                        const result = response && (response.data as IAccount[]);
                        if (!result) {
                            notify({
                                level: "error",
                                title: "Error getting account.",
                                message: "Please refresh and try again.",
                            });
                            return;
                        }
                        setAccounts(result);
                    })
                    .catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            } else if (patient && patient.accounts && !isEmptyObject(patient.accounts)) {
                logDev("usePatientAccounts - set Accounts from Patient");
                setAccounts(patient.accounts);
            } else if (!accountPublicUuid && patient) {
                setLoading(true);
                logDev("usePatientAccounts - no accountPublicUuid, get patient accounts");
                getter("/patient/accounts")
                    .then((response) => {
                        setLoading(false);
                        const result = response && (response.data as IAccount[]);
                        if (!result) {
                            notify({
                                level: "error",
                                title: "Error getting patient accounts.",
                                message: "Please refresh and try again.",
                            });
                            return;
                        }
                        setAccounts(result);
                    })
                    .catch((error) => {
                        setLoading(false);
                        handleError(error);
                    });
            }
        };
        getAccounts();
    }, [getter, patient, accountPublicUuid, setAccounts]);

    return [accounts, isLoading];
};

export const usePatientAccount = (patientId: string | number, accountId: string | number) => {
    const getter = useAxiosGetter();
    const [account, setPatientAccount] = useState<IAccount | undefined>();

    useEffect(() => {
        getter(`/patients/${patientId}/account/${accountId}`)
            .then((response) => {
                const result = response && (response.data as IValidationResult | IAccount);
                if (!result) {
                    response?.status &&
                        response.status > 399 &&
                        notify({
                            level: "error",
                            title: "Error getting data.",
                            message: DEFAULT_ERROR_MESSAGE,
                        });
                } else if (isFailedRequest(result as IValidationResult)) {
                    notify({
                        level: "error",
                        title: "Failed to data.",
                        message: (result as IValidationResult)?.message || DEFAULT_ERROR_MESSAGE,
                    });
                } else {
                    setPatientAccount(result as IAccount);
                }
            })
            .catch((error: AxiosError) => {
                handleError(error);
            });
    }, [getter, patientId, accountId, setPatientAccount]);

    return account;
};

export const useLobbieStaffAccount = (
    href?: string,
): [
    Account,
    (newAccount: IAccount) => void,
    () => Promise<IAccount | void>,
    // (acc: IAccountLite | undefined) => Promise<IAccount | void>,
] => {
    const dispatch = useDispatch();
    const getter = useAxiosGetter();
    const isSuperAdmin = useIsSuperAdmin();
    const account = useSelector(accountSelector);

    const dispatchAccount = useCallback(
        (newAccount: IAccount | undefined) => {
            if (newAccount?.publicUuid === LOBBIE_ACCOUNT_UUID) {
                return;
            }
            dispatch(setAccount(newAccount));
        },
        [dispatch],
    );

    const getAccount = useCallback(() => {
        logDev("useLobbieStaffAccount.getAccount");

        return getter("/staff/account/current", (error: AxiosError) => {
            handleHttpError(error, { href });
        })
            .then((response) => {
                const result = response && (response.data as IAccount | IValidationResult);
                if (!result) {
                    // dispatchAccount(undefined);
                    // notify({
                    //     level: "error",
                    //     title: "Error getting account info.",
                    // });
                } else if (isFailedRequest(result)) {
                    notify({
                        level: "error",
                        title: "Failed to get account info.",
                    });
                } else {
                    dispatchAccount(result as IAccount);
                    return result as IAccount;
                }
            })
            .catch((error: AxiosError) => {
                dispatchAccount(undefined);
                if (!error.response) {
                    return;
                } else if (error.response?.status !== HTTP_STATUS_CODES.UNAUTHORIZED) {
                    logDev("ACCOUNT HOOK ERROR RESPONSE", error.response);
                    removeAllUserSessionData();
                } else {
                    handleHttpError(error, { href });
                }
            });
    }, [getter, href, dispatchAccount]);

    useEffect(() => {
        if (isEmptyObject(account) && !isSuperAdmin) {
            logDev("useLobbieStaffAccount.useEffect.getAccount");

            getAccount().catch(handleError);
        }
    }, [isSuperAdmin, account, getAccount]);

    return [
        useMemo(() => new Account(account as IAccount), [account]),
        dispatchAccount,
        getAccount,
    ];
};
