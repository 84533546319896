/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setFormWidth } from "../actions/formActions";

const INITIAL_STATE: any = {
    formWidth: undefined,
};

export const formWidthReducer = createReducer(INITIAL_STATE, (builder) => {
    builder.addCase(setFormWidth, (state, action) => {
        return action.payload;
    });
});
