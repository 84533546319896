import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { IAccount, IAccountBasicInfo } from "lobbie";

export const setAccount = createAction<Partial<IAccount> | undefined>(
    "account/set",
) as ActionCreatorWithPayload<Partial<IAccount> | undefined>;

export const setAccountPublicUuid = createAction<string | null | undefined>(
    "accountPublicUuid/set",
) as ActionCreatorWithPayload<string | null | undefined>;

export const setAccounts = createAction<IAccountBasicInfo[]>("accounts/set");
