/** @format */

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    forms,
    IAccount,
    IAccountBasicInfo,
    IAppointmentType,
    IBranding,
    IDownloadableFile,
    IForm,
    IFormTemplateLabel,
    ILocation,
    integrations,
    IPerson,
    IPractitioner,
    IStaffSchedulerDate,
    patients,
    scheduling,
} from "lobbie";
import FormTemplate from "src/classes/FormTemplate";
import { TFormElementValue } from "src/components/shared/forms/utils";
import {
    DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS,
    DEFAULT_ACCOUNT_FROM_EMAIL_NAME,
    DEFAULT_ACCOUNT_REPLY_TO_EMAIL_ADDRESS,
} from "src/components/staff/config/ConfigBranding";
import { brandingReducer } from "src/redux/reducers/brandingReducer";
import { formBuilderReducer } from "src/redux/reducers/formBuilderReducer";
import { formReducer } from "src/redux/reducers/formReducer";
import { kiosksReducer } from "src/redux/reducers/forms/kiosks/kiosksReducer";
import { formWidthReducer } from "src/redux/reducers/formWidthReducer";
import { iframeReducer } from "src/redux/reducers/iframeReducer";
import { LOBBIE_COLORS } from "src/utils/colors";

import NPSSelfSignupInfo from "src/classes/NPSSelfSignupInfo";
import { formsDashboardReducer } from "src/redux/reducers/forms/formsDashboardReducer";
import { npsSelfSignupInfoReducer } from "src/redux/reducers/npsSelfSignupReducer";
import { IS_LOBBIE_PROD } from "../constants";
import { locationReducer } from "./reducers";
import { accountReducer } from "./reducers/accountReducer";
import { appointmentsReducer } from "./reducers/appointmentsReducer";
import { fileDownloadsReducer } from "./reducers/fileDownloadsReducer";
import { formGroupReducer } from "./reducers/formGroupReducer";
import { integrationWizardReducer } from "./reducers/integrationWizardReducer";
import { integratorsReducer } from "./reducers/integratorsReducer";
import { loadingReduxReducer } from "./reducers/loadingReduxReducer";
import { patientSchedulingReducer } from "./reducers/patientSchedulingReducer";
import { practitionersReducer } from "./reducers/practitionersReducer";
import { staffSchedulerReducer } from "./reducers/staffSchedulerReducer";
import { userReducer } from "./reducers/userReducer";

import { logger as reduxLogger } from "redux-logger";

const reducers = combineReducers({
    npsSelfSignupInfo: npsSelfSignupInfoReducer,
    account: accountReducer,
    appointments: appointmentsReducer,
    branding: brandingReducer,
    iframe: iframeReducer,
    integrators: integratorsReducer,
    loadingRedux: loadingReduxReducer,
    location: locationReducer,
    fileDownloads: fileDownloadsReducer,
    form: formReducer,
    formBuilder: formBuilderReducer,
    formGroup: formGroupReducer,
    formWidth: formWidthReducer,
    formsDashboard: formsDashboardReducer,
    kiosks: kiosksReducer,
    patientScheduling: patientSchedulingReducer,
    practitioners: practitionersReducer,
    staffScheduler: staffSchedulerReducer,
    integrationWizard: integrationWizardReducer,
    user: userReducer,
});

export interface IReduxIntegrationWizardSyncableState {
    appointmentTypes: Record<string, integrations.IAppointmentTypeIntegration>;
    locations: Record<string, integrations.ILocationIntegration>;
    practitioners: Record<string, integrations.IStafferIntegration>;
    accountPatients: Record<string, integrations.IAccountPatientIntegration>;
    appointments: Record<string, integrations.IAppointmentIntegration>;
    formTemplates: Record<string, integrations.IFormTemplateIntegration>;
    formTemplateLabels: Record<string, integrations.IFormTemplateLabelIntegration[]>;
}

export interface IReduxIntegrationWizardState {
    appointmentTypes: IAppointmentType[];
    locations: ILocation[];
    practitioners: IPractitioner[];
    formTemplates: FormTemplate[];
    formTemplateLabels: IFormTemplateLabel[];
    integrator: integrations.IIntegrator;
    syncable: IReduxIntegrationWizardSyncableState;
}

export interface IAppState {
    accountNPSSelfSignupInfo: {
        accountNPSSelfSignupInfo: NPSSelfSignupInfo | undefined;
    };
    account: {
        account: IAccount | undefined;
        accounts: IAccountBasicInfo[];
        publicUuid: string | undefined;
    };
    appointments: {
        appointmentTypes: IAppointmentType[] | undefined;
        defaultAppointmentType: IAppointmentType | undefined;
    };
    branding: IBranding;
    fileDownloads: {
        show: boolean;
        files: IDownloadableFile[];
    };
    form: {
        form: IForm | undefined;
        formElementAnswers: Record<number, Record<number, TFormElementValue>>;
        storageKey: string | undefined; // key used for local storage of form data including signatures/initials
        anonymousAccountPatient: patients.IAccountPatientLite | undefined;
        formTemplateSectionIndex: number | undefined;
        formTemplateSectionScrolledId: number | undefined;
    };
    formsSend: {
        initialRecipients: patients.IAccountPatient[] | undefined;
    };
    formWidth: number | undefined;
    formsDashboard: {
        formTemplateNames: string[];
    };
    formBuilder: {
        activeFormElementId: number | undefined;
    };
    formGroup: forms.IFormGroup | undefined;
    iframe: {
        url: string | undefined;
    };
    integrators: integrations.IIntegrator[] | undefined;
    integrationWizard: IReduxIntegrationWizardState;
    kiosks: {
        kiosks: [];
        kiosk: undefined;
    };
    loadingRedux: {
        isLoading: boolean;
        fileUploadStatus: number;
        fileDownloadStatus: number;
    };
    location: {
        locationId: number | undefined;
        location: ILocation | undefined;
        locations: ILocation[] | undefined;
        multiLocations: ILocation[] | undefined;
    };
    patientScheduling: scheduling.patient.IPatientScheduling;
    practitioners: IPractitioner[];
    staffScheduler: {
        staffSchedulerDate: IStaffSchedulerDate | undefined;
    };
    user: {
        user: IPerson | undefined;
    };
}

export const store = (() => {
    const _store = {
        reducer: reducers,
        devTools: !IS_LOBBIE_PROD,
        preloadedState: {
            branding: {
                id: -1,
                isUsingCustomLogoImage: false,
                logoS3Url: "",
                logoCircleColor: LOBBIE_COLORS.primary,
                logoLetterColor: LOBBIE_COLORS.white,
                logoName: "Lobbie",
                fromEmailAddress: DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS,
                fromEmailName: DEFAULT_ACCOUNT_FROM_EMAIL_NAME,
                replyToEmailAddress: DEFAULT_ACCOUNT_REPLY_TO_EMAIL_ADDRESS,
            },
            form: {
                form: undefined,
                formElementAnswers: {},
                storageKey: undefined,
                formTemplateSectionScrolledId: undefined,
            },
            formWidth: undefined,
            formGroup: undefined,
            formsDashboard: {
                formTemplateNames: [],
            },
            fileDownloads: {
                showFileDownloads: false,
                files: [],
            },
            iframe: {
                url: "",
            },
            integrators: [],
            kiosks: {
                kiosks: [],
                kiosk: undefined,
            },
            location: {
                locationId: undefined,
                location: undefined,
                locations: [],
                multiLocations: [],
            },
            practitioners: [],
        },
    };

    if (IS_LOBBIE_PROD) {
        return configureStore({
            ..._store,
            // middleware: () => [] as Middlewares<any>,
        });
    } else {
        // ! TOP-LEVEL AWAIT CRASHES THE FRONTEND
        // eslint-disable-next-line
        return configureStore({
            ..._store,
            middleware: () => [reduxLogger] as any,
        });
    }
})();
