import { ISortOrderable } from "lobbie";
import { isNumber } from "lodash";
import { isEmptyObject } from "src/utils";

export const sortByNumber = (items: number[]) => {
    if (isEmptyObject(items)) return [];
    return [...items].sort((a, b) => (a < b ? -1 : 1));
};

export const sortById = <T extends { id: number }>(items: T[], direction = "asc") => {
    if (isEmptyObject(items)) return [];
    const multiplier = direction === "desc" ? -1 : 1;
    return [...items].sort((a, b) => multiplier * (a.id < b.id ? -1 : 1));
};

export const sortByLabel = <T extends { label: string | number }>(
    items: T[],
    direction = "asc",
) => {
    if (isEmptyObject(items)) return [];
    const multiplier = direction === "desc" ? -1 : 1;
    return [...items].sort((a, b) => multiplier * (a.label < b.label ? -1 : 1));
};

export const sortByName = <T extends { name: string }>(items: T[], direction = "asc") => {
    if (isEmptyObject(items)) return [];
    const multiplier = direction === "desc" ? -1 : 1;
    return [...items].sort((a, b) => multiplier * (a.name < b.name ? -1 : 1));
};

export const sortByFirstName = <T extends { firstName: string }>(items: T[], direction = "asc") => {
    if (isEmptyObject(items)) return [];
    const multiplier = direction === "desc" ? -1 : 1;
    return [...items].sort((a, b) => multiplier * (a.firstName < b.firstName ? -1 : 1));
};

/**
 * Sort objects with a sortOrder
 *
 * @param items
 * @param direction
 * @returns
 */
export const sortBySortOrder = <T extends ISortOrderable>(
    items: T[] | undefined,
    direction = "asc",
): T[] => {
    if (!items || isEmptyObject(items)) return [];
    const multiplier = direction === "desc" ? -1 : 1;
    return [...items].sort(
        (a, b) =>
            multiplier *
            (isNumber(a.sortOrder) && isNumber(b.sortOrder)
                ? a.sortOrder > b.sortOrder
                    ? 1
                    : -1
                : 0),
    );
};
