import React, { useCallback, useMemo } from "react";
import { FiCheck } from "react-icons/fi";
import { useThrottleFunction } from "src/hooks/useThrottle";
import { noop } from "src/utils";
import { LOBBIE_COLORS } from "src/utils/colors";

interface IProps {
    id?: string;
    checked: boolean;
    onClick: () => void;
    disabled?: boolean;
    label?: string | React.ReactNode | null;
    className?: string;
    checkboxClassName?: string;
    checkboxContainerClassName?: string;
    labelContainerClassName?: string;
    inputStyle?: React.CSSProperties;
    checkedColor?: string;
}

const LobbieCheckboxV2: React.FC<IProps> = ({
    id,
    checked,
    onClick,
    label,
    disabled,
    className,
    checkboxClassName,
    checkboxContainerClassName,
    labelContainerClassName,
    inputStyle,
    checkedColor,
}) => {
    const style = useMemo(
        () =>
            checked
                ? {
                      backgroundColor: LOBBIE_COLORS.primaryLighter,
                      borderColor: LOBBIE_COLORS.primary,
                      ...inputStyle,
                  }
                : {
                      backgroundColor: LOBBIE_COLORS.secondaryLighter,
                      borderColor: LOBBIE_COLORS.secondaryLight,
                      ...inputStyle,
                  },
        [checked, inputStyle],
    );

    const checkboxStyle = useMemo(
        () => ({
            backgroundColor: checked
                ? (checkedColor ?? LOBBIE_COLORS.primary)
                : LOBBIE_COLORS.white,
            borderRadius: "25%",
            boxShadow: "0px 4px 4px rgba(95, 128, 250, 0.1)",
            opacity: disabled ? "50%" : "100%",
        }),
        [checked, checkedColor, disabled],
    );

    const handleClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;

            onClick();
        },
        [onClick, disabled],
    );

    const handleKeyPress = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            e.preventDefault();
            e.stopPropagation();

            if (disabled) return;
            if (e.key.toLowerCase() === "enter") {
                onClick();
            }
        },
        [onClick, disabled],
    );

    const handleClick_Throttled = useThrottleFunction(handleClick, 50);
    const handleKeyPress_Throttled = useThrottleFunction(handleKeyPress, 50);

    const Checkbox = (
        <FiCheck
            color={LOBBIE_COLORS.white}
            className={checkboxClassName || "mr-2 my-2 border p-1"}
            style={checkboxStyle}
            size={"24px"}
            name="checked"
        />
    );

    const EmptyCheckbox = (
        // * Copied FiCheck SVG from inspector
        <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="0"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            color="#ffffff"
            className={checkboxClassName || "mr-2 my-2 border p-1"}
            name="unchecked"
            height="24px"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
            style={checkboxStyle}
        >
            <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
    );

    if (label && typeof label !== "string") {
        const klass = className
            ? `cy-lobbie-checkbox-v2 wrap ${className} `
            : `p-2 m-1 border rounded wrap row align-items-center ${
                  disabled ? "bg-light text-secondary" : "pointer"
              } cy-lobbie-checkbox-v2`;

        return (
            <div
                id={id}
                onClick={handleClick_Throttled}
                onKeyDown={handleKeyPress_Throttled}
                onDoubleClick={noop}
                onDoubleClickCapture={noop}
                className={klass}
                style={style}
            >
                <div className={checkboxContainerClassName || "col-2 text-left px-0"}>
                    {checked ? Checkbox : EmptyCheckbox}
                </div>
                <div className={labelContainerClassName || "col-10 pl-0 wrap pointer"}>{label}</div>
            </div>
        );
    } else {
        const klass = className
            ? `cy-lobbie-checkbox-v2 wrap ${className} `
            : `pl-2 pr-3 m-1 border rounded ${
                  disabled ? "bg-light text-secondary" : "pointer"
              } cy-lobbie-checkbox-v2 wrap`;

        return (
            <div
                id={id}
                onClick={handleClick_Throttled}
                onKeyDown={handleKeyPress_Throttled}
                onDoubleClick={noop}
                onDoubleClickCapture={noop}
                className={klass}
                style={style}
            >
                {checked ? Checkbox : EmptyCheckbox}
                {label ? (
                    <span className={`wrap ${labelContainerClassName || ""}`}>{label}</span>
                ) : null}
            </div>
        );
    }
};

export default LobbieCheckboxV2;
