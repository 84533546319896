import { IPerson } from "lobbie";
import { ERoleGroup } from "../constants";

const getRoles = (roleGroups?: string | string[] | IPerson): number[] => {
    if (!roleGroups) return [];

    if (typeof roleGroups === "string") {
        return roleGroups.split(",").map(Number);
    }
    if (roleGroups instanceof Array) {
        return roleGroups.map(Number);
    }
    if (
        "roleGroupIds" in roleGroups &&
        roleGroups.roleGroupIds &&
        typeof roleGroups.roleGroupIds === "string"
    ) {
        return roleGroups.roleGroupIds.split(",").map(Number);
    }
    return [];
};

export const includesUserRoleGroup = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.includes(ERoleGroup.User);
};

export const includesPractitionerRoleGroup = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.includes(ERoleGroup.Practitioner);
};

export const includesAdminRoleGroup = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.includes(ERoleGroup.Admin);
};

export const includesDeveloperRoleGroup = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.includes(ERoleGroup.Developer);
};

export const includesSuperAdminRoleGroup = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.includes(ERoleGroup.SuperAdmin);
};

export const isOnlyPractitionerRole = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.length === 1 && roles.includes(ERoleGroup.Practitioner);
};

export const isOnlyDeveloperRole = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return roles.length === 1 && roles.includes(ERoleGroup.Developer);
};

export const isUserPractitionerRole = (roleGroups?: string | string[] | IPerson) => {
    if (!roleGroups) return false;

    const roles = getRoles(roleGroups);

    return (
        roles.length === 2 &&
        roles.includes(ERoleGroup.User) &&
        roles.includes(ERoleGroup.Practitioner)
    );
};
