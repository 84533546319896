/** @format */
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { IValidationResult } from "lobbie";
import { IStreamResult } from "src/components/shared/forms/utils";
import { isPlainObject, localGet, localSet, logDev, notify } from ".";
import {
    BASE_API_URL,
    BASE_AUTH_API_ROUTE,
    BASE_SECURE_ROUTE,
    DEFAULT_ERROR_MESSAGE,
    IS_LOBBIE_PROD,
    IS_PRODUCTION,
} from "../constants";
import { removeAllUserSessionData } from "./users";

const sendToSentry = ({
    error,
    message,
}: {
    error?: Error | AxiosError | GeolocationPositionError;
    message?: string;
}) => {
    if (IS_PRODUCTION) {
        if (error) {
            if (message) {
                Sentry.captureException(error, { extra: { lobbie: message } });
            } else {
                Sentry.captureException(error);
            }
        } else if (message) {
            Sentry.captureMessage(message);
        }
    }
};

// export const INVALID_CHRACTERS = "! # $ % ^ & * ( ) + = [ ] { } ; ' : \" \\ |  , < > / ?";
// eslint-disable-next-line
export const INVALID_CHRACTERS_REGEX = /[!#$%^&*()+\=\[\]{};:"\\|,<>\/?]+/; // NOSONAR

export const isHTTP = (str: any) => typeof str === "string" && str.startsWith("http");

export const isBlobString = (str: any) => typeof str === "string" && str.startsWith("blob:http");

export const isFailedRequest = (
    result:
        | IValidationResult
        | Record<string, any>
        | Record<string, any>[]
        | number
        | string
        | void,
): boolean => {
    return !!(
        result &&
        isPlainObject(result) &&
        "success" in (result as IValidationResult) &&
        !(result as IValidationResult).success
    );
};

export const handleError = (
    error?: Error | GeolocationPositionError,
    options?: {
        title?: string;
        message?: string;
        sendToSentry?: boolean;
        sentryMessage?: string;
    },
): undefined => {
    options?.message && logDev(options?.message);
    if (IS_PRODUCTION) {
        if (options?.sendToSentry === false) {
            logDev(error);
        } else if (options?.sentryMessage) {
            sendToSentry({ error, message: options?.sentryMessage });
        } else {
            sendToSentry({ error });
        }
    } else {
        console.error(error);
    }
    (options?.title || options?.message) &&
        notify({
            level: "error",
            title: options?.title || "Error",
            message: options?.message || DEFAULT_ERROR_MESSAGE,
        });
    return; // NOSONAR
};

export const handleUnauthorized = (options?: { href?: string }) => {
    logDev("handleHttpError - Handling HTTP Status Code 401");
    notify({
        level: "error",
        title: "Session Expired",
        message: "Please sign into Lobbie again.",
        duration: 0,
        onClick: () => (window.location.pathname = options?.href || "/"),
    });
    removeAllUserSessionData(true);
    setTimeout(() => {
        window.location.pathname = options?.href || "/";
    }, 5000);
};

export const handleHttpError = (error: AxiosError, options?: { href?: string }) => {
    if (error.code === "ERR_NETWORK") {
        sendToSentry({ error, message: "handleHttpError ERR_NETWORK" });
        return;
    }

    const statusCode = error?.response?.status;
    logDev("HTTP ERROR -", error);
    logDev("HTTP ERROR - RESPONSE -", error.response);
    if (statusCode === HTTP_STATUS_CODES.BAD_REQUEST) {
        logDev("handleHttpError - Handling HTTP Status Code 400");
        notify({
            level: "error",
            title: "Bad Request",
            message: "There was an error with the request, please try sending it again.",
        });
    } else if (statusCode === HTTP_STATUS_CODES.UNPROCESSABLE_ENTITY) {
        logDev("handleHttpError: Handling HTTP Status Code 422");
        removeAllUserSessionData();
        fetch(`${BASE_AUTH_API_ROUTE}/logout`, { method: "POST", body: JSON.stringify({}) })
            .then(() => {
                notify({
                    level: "warning",
                    title: "Issue signing in.",
                    message: "Please try refreshing the page and signing into Lobbie again.",
                });
            })
            .catch(console.error);
    } else if (statusCode === HTTP_STATUS_CODES.UNAUTHORIZED) {
        handleUnauthorized(options);
    } else if (statusCode === HTTP_STATUS_CODES.FORBIDDEN) {
        logDev("handleHttpError - Handling HTTP Status Code 403");
        /*notify({
            level: "warning",
            title: "Access Denied",
            message:
                "You do not have permission to view this resource. Try a private browser tab and/or contact your administrator.",
        });
        removeAllUserSessionData(true);*/
        sendToSentry(error);
    } else if (statusCode === HTTP_STATUS_CODES.NOT_FOUND) {
        logDev("handleHttpError - Handling HTTP Status Code 404");
        if (!IS_LOBBIE_PROD) {
            notify({
                level: "error",
                title: "Error",
                message: error.message || "Something's missing, we're investigating.",
            });
        }
        sendToSentry(error);
    } else if (statusCode && statusCode >= HTTP_STATUS_CODES.INTERNAL_SERVER_ERROR) {
        logDev("handleHttpError - Handling HTTP Status Code >= 500");
        notify({
            level: "error",
            title: "Lobbie Error",
            message: "Something went wrong because of us. Sit tight, we're investigating.",
        });
        sendToSentry(error);
    } else {
        sendToSentry(error);
    }
};

/*
 * Refresh the page a single time if the time is === 3 AM
 * Do this to load any front end updates
 */
let refreshTimeout: any;
export const withLobbieRefresh = (): void => {
    if (refreshTimeout) {
        return;
    }

    const now = new Date();
    if (now.getHours() === 4) {
        const storageKey = "@lobbie/reloaded/date";
        if (localGet(storageKey) === now.toLocaleDateString("en-US")) {
            return;
        }

        refreshTimeout = setTimeout(() => {
            localSet(storageKey, now.toLocaleDateString("en-US"));
            window.location.pathname = "/";
        }, 10000);

        notify({
            level: "info",
            title: "Loading a new version of Lobbie in 10 seconds.",
            message: "Click/Tap me to cancel. This occurs daily at 4:00 A.M.",
            onClick: () => {
                clearTimeout(refreshTimeout);
                refreshTimeout = undefined;
                localSet(storageKey, now.toLocaleDateString("en-US"));
                notify({
                    level: "warning",
                    title: "Reload canceled.",
                    message: "You are using an old version of Lobbie and may experience issues.",
                });
            },
        });
    }
};

export const getStaffImageFormUrl = (uuid: any) => {
    return `${BASE_API_URL}/${BASE_SECURE_ROUTE}` + "/shared/image/" + uuid;
};

/**
 * Return API endpoint for getting a pre-signed url for an S3 object
 * adds the s3ObjectPath as a query string param with key 's3ObjectPath'
 *
 * @param {String} s3ObjectPath
 * @returns {String}
 */
export const getAPIEndpointForS3SignedUrl = (s3ObjectPath: string): string => {
    if (!s3ObjectPath) return "";

    try {
        let path = s3ObjectPath;
        if (path.includes(";")) {
            const split = path.split(";");
            if (split.length === 1) {
                path = (JSON.parse(split.first()) as IStreamResult)?.s3ObjectPath;
            } else {
                path = (JSON.parse(split.last()) as IStreamResult)?.s3ObjectPath;
            }
        }
        if (path.startsWith("{") && path.endsWith("}")) {
            path = (JSON.parse(path) as IStreamResult).s3ObjectPath;
        }
        if (path) {
            return `/shared/image?s3ObjectPath=${path}`;
        }
    } catch (error) {
        console.error(error);
    }
    return `/shared/image?s3ObjectPath=${s3ObjectPath}`;
};

export const HTTP_STATUS_CODES = {
    CONTINUE: 100,
    SWITCHING_PROTOCOLS: 101,
    PROCESSING: 102,
    EARLY_HINTS: 103,
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NO_CONTENT: 204,
    RESET_CONTENT: 205,
    PARTIAL_CONTENT: 206,
    MULTI_STATUS: 207,
    ALREADY_REPORTED: 208,
    IM_USED: 226,
    MULTIPLE_CHOICES: 300,
    MOVED_PERMANENTLY: 301,
    FOUND: 302,
    SEE_OTHER: 303,
    NOT_MODIFIED: 304,
    USE_PROXY: 305,
    TEMPORARY_REDIRECT: 307,
    PERMANENT_REDIRECT: 308,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    NOT_ACCEPTABLE: 406,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    CONFLICT: 409,
    GONE: 410,
    LENGTH_REQUIRED: 411,
    PRECONDITION_FAILED: 412,
    PAYLOAD_TOO_LARGE: 413,
    URI_TOO_LONG: 414,
    UNSUPPORTED_MEDIA_TYPE: 415,
    RANGE_NOT_SATISFIABLE: 416,
    EXPECTATION_FAILED: 417,
    IM_A_TEAPOT: 418,
    MISDIRECTED_REQUEST: 421,
    UNPROCESSABLE_ENTITY: 422,
    LOCKED: 423,
    FAILED_DEPENDENCY: 424,
    TOO_EARLY: 425,
    UPGRADE_REQUIRED: 426,
    PRECONDITION_REQUIRED: 428,
    TOO_MANY_REQUESTS: 429,
    REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
    UNAVAILABLE_FOR_LEGAL_REASONS: 451,
    INTERNAL_SERVER_ERROR: 500,
    NOT_IMPLEMENTED: 501,
    BAD_GATEWAY: 502,
    SERVICE_UNAVAILABLE: 503,
    GATEWAY_TIMEOUT: 504,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    VARIANT_ALSO_NEGOTIATES: 506,
    INSUFFICIENT_STORAGE: 507,
    LOOP_DETECTED: 508,
    BANDWIDTH_LIMIT_EXCEEDED: 509,
    NOT_EXTENDED: 510,
    NETWORK_AUTHENTICATION_REQUIRED: 511,
};
