/** @format */

import { AxiosError, AxiosResponse } from "axios";
import { IPatient, IPerson, IStaffer, IValidationResult, TotpStaffer } from "lobbie";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BASE_AUTH_API_ROUTE, ROUTES } from "src/constants";
import { useLobbiePatient } from "src/hooks/patients/useLobbiePatient";
import { useAxiosPublicPost } from "src/hooks/useAxios";
import { useCurrentStaffer } from "src/hooks/user";
import { setUser } from "src/redux/actions/userActions";
import {
    handleError,
    isEmptyObject,
    isFailedRequest,
    isOnlyDeveloperRole,
    logDev,
    notify,
    removeAllUserSessionData,
    updateStafferState,
} from "src/utils";
import { retry } from "src/utils/lazy";
import CenteredLoadingSpinner from "../loading/CenteredLoadingSpinner";
import LoginForm from "./LoginForm";
import { ILoginSelectedUser, TLoginReturn } from "./utils";
import FullScreenLoadingSpinner from "src/components/shared/loading/FullScreenLoadingSpinner";

const PrivacyPolicyModal = lazy(() =>
    retry(() => import("src/components/shared/user/PrivacyPolicyModal")),
);
const LoginSelectUserModal = lazy(() => retry(() => import("./LoginSelectUserModal")));
const Person2FASetupModal = lazy(() =>
    retry(() => import("src/components/shared/user/2FA/Person2FASetupModal")),
);

interface IProps {
    isEVV?: boolean;
}

const LOGIN_FAILED_MESSAGE =
    "When logging in as a different user try using a private/incognito browser tab.";

const Login: React.FC<IProps> = ({ isEVV }) => {
    logDev("Login - Loaded Login");
    const { push } = useHistory();
    const dispatch = useDispatch();
    const poster = useAxiosPublicPost();
    const [patient, , getCurrentPatient] = useLobbiePatient();
    // logDev("Login.useLobbiePatient - patient -", patient);

    const [user, dispatchStaffer, getCurrentStaffer, isLoadingUser] = useCurrentStaffer(patient);
    // logDev("Login.useCurrentStaffer - user -", user);

    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [staffer2FA, setTotpStaffer] = useState<IStaffer | undefined>();

    const [possibleUsers, setPossibleUsers] = useState<IPerson[]>([]);

    const handleClose = useCallback(() => setShow(false), []);
    const handleShow = useCallback(() => setShow(true), []);

    const navigate = useCallback(
        (staffer: IStaffer) => {
            if (isOnlyDeveloperRole(staffer.roleGroupIds)) {
                push(ROUTES.homeSettingsDeveloper);
            } else if (isEVV) {
                if (staffer.account?.evv) {
                    push(ROUTES.evvHome);
                }
            } else if (staffer.account?.appointmentScheduling) {
                push(ROUTES.home);
            } else if (staffer.account?.forms) {
                push(ROUTES.staffHomeLobbieForms);
            } else {
                push(ROUTES.homeSocialNPS);
            }
        },
        [push, isEVV],
    );

    const handleLoginSuccess = useCallback(
        (person: IPerson) => {
            if (!person) {
                return;
            } else if (person.isPatient) {
                dispatch(setUser(person as IPatient));
                push(ROUTES.patientHome);
                return;
            }
            const staffer = person as IStaffer;
            dispatchStaffer(staffer);

            logDev("Login - setAccount");
            if (staffer.account) {
                if (isEVV && !staffer.account.evv) {
                    notify({
                        level: "error",
                        title: "No EVV Access",
                        message: "Account has no access to EVV service.",
                    });
                }

                updateStafferState(dispatch, staffer);
            } else {
                console.error(
                    `Login.handleLoginSuccess - Staff member has no .account - ${staffer}`,
                );
            }
            navigate(staffer);
        },
        [dispatch, dispatchStaffer, isEVV, navigate, push],
    );

    const onSubmit = useCallback(
        (result: TLoginReturn | IValidationResult) => {
            if (!result) {
                notify({
                    level: "error",
                    title: "Error logging in.",
                    message: LOGIN_FAILED_MESSAGE,
                });
                return;
            }

            if (Array.isArray(result)) {
                setPossibleUsers(result);
            } else if ((result as IPerson)?.isPatient) {
                logDev("Login - result.patient log in patient");
                dispatch(setUser(result as IPatient));
                push(ROUTES.patientHome);
            } else if ((result as IPerson)?.isPatient === false) {
                const staffer = result as IStaffer;
                if (!staffer) return;

                if (staffer.person2FA?.is2FARequired) {
                    setTotpStaffer(staffer);
                } else {
                    logDev("Login - else log in staffer", staffer);
                    handleLoginSuccess(staffer);
                }
            }
        },
        [dispatch, push, handleLoginSuccess],
    );

    const selectUser = useCallback(
        (selected: ILoginSelectedUser) => {
            setSubmitting(true);

            poster(`${BASE_AUTH_API_ROUTE}/select`, selected, (error: AxiosError) => {
                if (
                    error?.response?.status &&
                    error?.response?.status > 399 &&
                    error?.response?.status < 500
                ) {
                    notify({
                        level: "error",
                        title: "Invalid",
                        message: "Invalid credentials.",
                    });
                }
            })
                .then((response: AxiosResponse | void) => {
                    setSubmitting(false);
                    const result = response && (response.data as TLoginReturn | IValidationResult);
                    if (!result) {
                        response?.status &&
                            response.status > 399 &&
                            notify({
                                level: "error",
                                title: "Error logging in.",
                                message: LOGIN_FAILED_MESSAGE,
                            });
                        removeAllUserSessionData();
                    } else if (isFailedRequest(result)) {
                        notify({
                            level: "error",
                            title: "Failed to log in.",
                            message: (result as IValidationResult).message || LOGIN_FAILED_MESSAGE,
                        });
                        removeAllUserSessionData();
                    } else {
                        onSubmit(result as TLoginReturn);
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    removeAllUserSessionData();
                    handleError(error);
                });
        },
        [poster, onSubmit],
    );

    useEffect(() => {
        if (patient) {
            onSubmit(patient);
        } else if (user) {
            onSubmit(user);
        }
    }, [user, patient, onSubmit]);

    if (isLoadingUser) {
        return null;
    }

    if (staffer2FA) {
        // return <Person2FASetupModal person={staffer2FA as TotpStaffer} callback={navigateTotp} />;
        return (
            <Suspense fallback={<FullScreenLoadingSpinner message="Loading 2FA..." />}>
                <Person2FASetupModal
                    person={staffer2FA as TotpStaffer}
                    callback={staffer2FA ? getCurrentStaffer : getCurrentPatient}
                />
            </Suspense>
        );
    }

    return (
        <div className="container">
            <Suspense fallback={<FullScreenLoadingSpinner />}>
                <PrivacyPolicyModal show={show} handleClose={handleClose} />
            </Suspense>
            <div className="minContainer">
                <div>
                    <div className="mb-4 text-center">
                        <h1>Log in</h1>
                    </div>
                    <LoginForm
                        onSubmit={onSubmit}
                        setSubmitting={setSubmitting}
                        isSubmitting={isSubmitting}
                        isPasswordResetEnabled={true}
                    />
                </div>
                <div className="row">
                    <div className="mt-2 col">
                        <a href="https://www.lobbie.com" target="_blank">
                            <img
                                className="login-logo mt-1"
                                src="/powered_by_lobbie.svg"
                                alt="Lobbie Logo"
                            />
                        </a>
                    </div>
                    <div className="col text-right mt-4">
                        <span className="link" onClick={handleShow}>
                            privacy policy
                        </span>
                    </div>
                </div>

                {isSubmitting && <CenteredLoadingSpinner message={"Loading..."} />}
            </div>
            <Suspense fallback={<FullScreenLoadingSpinner />}>
                <LoginSelectUserModal
                    showModal={!isEmptyObject(possibleUsers)}
                    handleModalShowHide={() => setPossibleUsers([])}
                    users={possibleUsers}
                    selectUser={selectUser}
                />
            </Suspense>
        </div>
    );
};

export default Login;
