/** @format */

import { IAddress, IAddressLite, ILocation, IPatientAddress } from "lobbie";
import { IS_LOBBIE_PROD, IS_LOBBIE_SANDBOX } from "../constants";

import { get, uniqBy } from "lodash";

declare global {
    interface Array<T> {
        first(): T;
        last(): T;
    }
}

export const FALSE_VALUES = [null, "null", NaN, "NaN", undefined, "undefined", false, "false"];

export const noop = () => null;

export const getId = (object: { id: number }): number | undefined => {
    return object?.id;
};

export const isTrue = (object: any) => Boolean(object && ["true", "1", true, 1].includes(object));
export const isFalse = (object: any) =>
    Boolean(object && ["false", "0", false, 0].includes(object));

export const getRandomId = () => {
    return Math.floor(Math.random() * 100000 + 1);
};

export const flatten = <T>(arrays: T[][]): T[] => {
    return ([] as T[]).concat(...arrays);
};

// https://stackoverflow.com/a/57863619/6410635
export const toCamelCaseFromSnakeCase = (string: string): string => {
    if (string) {
        return string.replace(/(_\w)/g, (k) => k[1].toUpperCase());
    } else {
        return "";
    }
};

export const pluralize = (string: string, count?: number): string => {
    if (count && count === 1) {
        return string;
    }

    const lastchar = string?.split("").last();
    if (!string || typeof string !== "string") {
        return string;
    } else if (["s", ")", "}", "]"].includes(lastchar)) {
        return string;
    } else {
        return plur(string);
    }
};

/**
 * https://github.com/sindresorhus/plur
 */
const plur = (word: string) => {
    if (!word) return "";

    const plural = (
        word.replace(/(?:s|x|z|ch|sh)$/i, "$&e").replace(/([^aeiou])y$/i, "$1ie") + "s"
    ).replace(/i?e?s$/i, (match) => {
        const isTailLowerCase = word.endsWith(word.slice(-1).toLowerCase());
        return isTailLowerCase ? match.toLowerCase() : match.toUpperCase();
    });
    return plural;
};

export const dedupe = <T>(array: T[]): T[] => {
    return array.filter((item, index) => {
        return array.indexOf(item) === index;
    });
};

export const dedupeBy = (array: any[], key: string | number): any[] => {
    return uniqBy(array, key);
    // return array.reduce((sum, item) => {
    //     const removed = sum.filter((i: any) => i[key] !== item[key]);
    //     return [...removed, item];
    // }, []);
};

const NUMERIC_TYPES = ["number", "string", "bigint"];
export const isNumber = (value: any) =>
    typeof value === "number" && !isNaN(value) && isFinite(value);
export const isNumeric = (value: any) =>
    NUMERIC_TYPES.includes(typeof value) && isNumber(Number(value));

export const logDev = (...args: any[]) => {
    if (!IS_LOBBIE_PROD && !IS_LOBBIE_SANDBOX) {
        const [message, ...extra] = args;
        console.log(`(dev) ${message}`, ...extra);
    }
};

export const getBooleanFromString = (object: string | boolean) => {
    if (object === undefined || object === null) return object;
    if (typeof object === "boolean") {
        return object;
    }
    if (typeof object === "string") {
        const o = object.toLowerCase().trim();
        return o && o !== "false";
    }
    return !!object;
};

// https://stackoverflow.com/a/52613528/6410635
export const getMinMaxFromNumberArray = (
    array: number[],
): {
    min: number;
    max: number;
} => {
    let min = array.first() || 0;
    let max = array.last() || 0;
    let i = array.length;

    while (i--) {
        const value = array[i];
        if (!value && value !== 0) continue;
        min = value < min ? value : min;
        max = value > max ? value : max;
    }
    return { min, max };
};

export const getMinMaxFromObjectArray = (
    array: Record<string, number>[],
    key: string,
): {
    min: number;
    max: number;
} => {
    if (isEmptyObject(array)) {
        return { min: 0, max: 0 };
    }
    let min = get(array.first(), key);
    let max = get(array.first(), key);
    let i = array.length;

    while (i--) {
        min =
            (get(array, `[${i}].${key}`) || (0 as number)) < min
                ? get(array, `[${i}].${key}`) || (0 as number)
                : min;
        max =
            (get(array, `[${i}].${key}`) || (0 as number)) > max
                ? get(array, `[${i}].${key}`) || (0 as number)
                : max;
    }
    return { min, max };
};

export const isPlainObject = (obj: any): boolean => {
    return obj && typeof obj === "object" && obj.constructor === Object;
};

export const toArrayOfArrays = <T>(
    array: T[],
    { countItemsInSubArray }: { countItemsInSubArray: number },
): T[][] => {
    if (!array) return [];

    const newArray = [] as T[][];
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const subArrayIndex = Math.floor(index / countItemsInSubArray);
        const modulo = index % countItemsInSubArray;
        if (modulo === 0) {
            newArray.push(Array(countItemsInSubArray).fill(null));
        }
        // logDev("toArrayOfArrays.index, subArrayIndex, modulo -", {
        //     index,
        //     subArrayIndex,
        //     modulo,
        //     newArray,
        // });
        newArray[subArrayIndex][modulo] = element;
    }

    return newArray;
};

export const hasKey = (obj: any, key: string): boolean =>
    Object.prototype.hasOwnProperty.call(obj, key);

export const isEmptyObject = (obj: any): boolean => {
    if (FALSE_VALUES.includes(obj)) return true;

    if (isNumber(obj)) return false;

    if (typeof obj === "string" && obj.length === 0) return true;

    if (Array.isArray(obj) && obj.length === 0) return true;

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }
    return true;
};

export const titleize = (
    string: string | undefined | null,
    separator = " ",
    joiner = " ",
    lower = true,
) => {
    if (!string) return "";

    const words = lower ? string.toLowerCase().split(separator) : string.split(separator);
    return words
        .map((word: string) => {
            if (!word) return word;
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(joiner);
};

export const capitalize = (string: string | undefined | null, separator = " ", joiner = " ") => {
    return titleize(string, separator, joiner, false);
};

export const capitalizeFirstWord = (
    string: string | undefined | null,
    separator = " ",
    joiner = " ",
) => {
    if (!string) return "";

    const words = string.toLowerCase().split(separator);
    return words
        .map((word: string, index: number) => {
            if (!word) return word;
            if (index > 0) return word;
            return word[0].toUpperCase() + word.substring(1);
        })
        .join(joiner);
};

export const splitBySnakeCase = (string: string) => string.replace(/([a-z])([A-Z])/g, "$1 $2");

export const removePlusFromEmail = (email: string) => {
    if (!email) return email;
    if (!email.includes("+")) {
        return email;
    }
    const plusIndex = email.indexOf("+");
    const atIndex = email.indexOf("@");
    return `${email.substring(0, plusIndex)}${email.substring(atIndex)}`;
};

type TAddressable = ILocation | IAddressLite | IAddress | IPatientAddress | null | undefined;
export const toAddressString = (address: TAddressable): string => {
    if (!address) return "";

    if ((address as ILocation).address) {
        return toAddressString((address as ILocation).address);
    }

    const _address = address as IAddress | IPatientAddress;
    const { address1, address2, city, stateProvince, postalCode } = _address;

    let string = "";
    if (address1) {
        string = address1;
    }

    if (address1 && address2) {
        string = `${string} ${address2},`;
    } else {
        string = `${string},`;
    }

    if (city) {
        string = `${string} ${city},`;
    }

    if (stateProvince?.shortName) {
        string = `${string} ${stateProvince?.shortName}`;
    }

    if (postalCode) {
        string = `${string} ${postalCode}`;
    }

    return string;
};

export const toTwoLinesAddressStrings = (
    address: ILocation | IAddressLite | IAddress | IPatientAddress | null | undefined,
): string[] => {
    if (!address) return [];

    if ((address as ILocation).address) {
        return toTwoLinesAddressStrings((address as ILocation).address);
    }

    const _address = address as IAddress | IPatientAddress;
    if (_address.address2) {
        return [
            `${_address.address1} ${_address.address2},`,
            `${_address.city}, ${_address.stateProvince.shortName} ${_address.postalCode}`,
        ];
    }
    return [
        `${_address.address1},`,
        `${_address.city}, ${_address.stateProvince.shortName} ${_address.postalCode}`,
    ];
};

export const toThreeLinesAddressStrings = (
    address: ILocation | IAddressLite | IAddress | IPatientAddress | null | undefined,
): string[] => {
    if (!address) return [];

    if ((address as ILocation).address) {
        return toTwoLinesAddressStrings((address as ILocation).address);
    }

    const _address = address as IAddress | IPatientAddress;
    if (_address.address2) {
        return [
            `${_address.address1},`,
            `${_address.address2},`,
            `${_address.city}, ${
                _address.stateProvince?.shortName || _address.stateProvince?.name || ""
            } ${_address.postalCode}`,
        ];
    }
    return [
        `${_address.address1},`,
        `${_address.city}, ${
            _address.stateProvince?.shortName || _address.stateProvince?.name || ""
        } ${_address.postalCode}`,
    ];
};

export const removeNonDigits = (string: string | null | undefined) => {
    if (typeof string === "string") {
        return string.replace(/\D/g, "");
    } else {
        return string || "";
    }
};

export const openURLInNewTab = (url: string) => {
    if (!url) return;
    const win = window.open(url, "_blank");
    win?.focus();
};

// TODO: Determine if this is need or can just be replaced by LobbieDate
// TODO: What about Date object returned from Dayjs?
// @ts-ignore
// eslint-disable-next-line
Date.prototype.calendarFormat = function () {
    // NOSONAR
    const mm = this.getMonth() + 1; // getMonth() is zero-based
    const dd = this.getDate();

    return [(mm > 9 ? "" : "0") + mm, "/", (dd > 9 ? "" : "0") + dd, "/", this.getFullYear()].join(
        "",
    );
};

if (!Array.prototype.first) {
    Array.prototype.first = function () {
        // NOSONAR
        return this[0];
    };
}

if (!Array.prototype.last) {
    Array.prototype.last = function () {
        // NOSONAR
        return this[this.length - 1];
    };
}

AbortSignal.timeout =
    AbortSignal.timeout ||
    function timeout(ms) {
        const ctrl = new AbortController();
        setTimeout(() => ctrl.abort(), ms);
        return ctrl.signal;
    };

/**
 * Polyfill to support browser versions of iOS Safari < 13.4
 * https://caniuse.com/?search=replaceAll
 *
 * TODO: This implementation is INCORRECT and the core-js implementation SHOULD be used instead
 * TODO: https://github.com/zloirock/core-js/blob/master/packages/core-js/modules/es.string.replace-all.js
 *
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 * https://vanillajstoolkit.com/polyfills/stringreplaceall/
 * @author Chris Ferdinandi
 * @license MIT
 */
// @ts-ignore
if (!String.prototype.replaceAll) {
    // @ts-ignore
    String.prototype.replaceAll = function (
        // NOSONAR
        searchValue: string | RegExp,
        replaceValue: string | ((substring: string, ...args: any[]) => string),
    ): string {
        const replacement =
            typeof replaceValue === "string" ? replaceValue : replaceValue(searchValue as string);

        if (Object.prototype.toString.call(searchValue).toLowerCase() === "[object regexp]") {
            return this.replace(searchValue, replacement);
        }

        // If a string
        return this.replace(new RegExp(searchValue, "g"), replacement);
    };
}

if (!Object.fromEntries) {
    // https://gitlab.com/moongoal/js-polyfill-object.fromentries/-/blob/master/index.js
    Object.defineProperty(Object, "fromEntries", {
        value(entries: { [key: string]: any }) {
            if (!entries) {
                return [];
            }
            const o = {} as Record<string, any>;
            Object.keys(entries).forEach((key) => {
                const [k, v] = entries[key];
                o[k] = v;
            });
            return o;
        },
    });
}

export * from "./datetimes";
export * from "./form";
export * from "./http";
export * from "./lobbie_date";
export * from "./notify";
export * from "./roles";
export * from "./storage";
export * from "./users";
