import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { integrations } from "lobbie";
import { IReduxIntegrationWizardSyncableState } from "../store";

export const setIntegrationWizard = createAction<integrations.IIntegrationWizard | undefined>(
    "integrationWizard/set",
) as ActionCreatorWithPayload<integrations.IIntegrationWizard | undefined>;

export const setIntegrationWizardSyncableObjects = createAction<
    integrations.IIntegrationWizard | undefined
>("integrationWizardSyncable/set") as ActionCreatorWithPayload<
    IReduxIntegrationWizardSyncableState | undefined
>;
