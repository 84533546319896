import { Dayjs } from "dayjs";
import {
    IBaseDrawableValue,
    IDrawableBodyValue,
    IDrawableMamogramValue,
    IFileUploadProgress,
    IForm,
    IFormElement,
    IKonvaData,
    ISelectOption,
    ISignatureValue,
} from "lobbie";
import { get } from "lodash";
import React from "react";
import { FileWithPath } from "react-dropzone";
import { IAnnotation } from "src/components/shared/forms/formElements/human_body/FormTypeAnnotatedBody";
import { IAnnotationDot } from "src/components/shared/forms/formElements/human_body/FormTypeBody";
import { EFormElementTypes } from "src/constants/form";
import { EEditGroups } from "src/constants/formelement";
import { EUserType } from "src/constants/user";
import { DEFAULT_MAX_FILES, validateFileUploads } from "src/hooks/file_uploads/validate";
import {
    flatten,
    getBooleanFromString,
    isEmptyObject,
    isNumeric,
    isPlainObject,
    logDev,
} from "src/utils";
import { dedupeById } from "src/utils/deduper";
import {
    isChecked,
    isValidDate,
    isValidEmail,
    isValidOption,
    isValidPhoneNumber,
    isValidSSN,
    isValidSignature,
    isValidTextArea,
    isValidTextInput,
    isValidZipcode,
    isValidZipcodeCanada,
} from "src/utils/form";
import { IBuildFormItem } from "./builder/utils";
import { IThirdPartyAPIFormValue } from "./formElements/FormTypeThirdPartyAPI";

export interface IStreamResult {
    s3ObjectPath: string;
    progress: number;
    uppyProgress?: number;
    responseProgress?: number;
    signedURL?: string;
}

export interface IInsuredInfo {
    memberId: string;
    payerId: string;
    payerName: string;
    payerType: string;
    eligible: string;
    relationshipToPatient: string;
}

export type TFormElementValue =
    | Date
    | Dayjs
    | Blob
    | FileWithPath[]
    | IStreamResult // deprecated
    | ISignatureValue
    | IBaseDrawableValue
    | IAnnotationDot[]
    | IAnnotation[]
    | ISelectOption
    | IFileUploadProgress
    | IFileUploadProgress[]
    | string
    | number
    | boolean
    | IInsuredInfo
    | undefined
    | null;

export type TFormElementSaveOperation = "create" | "update" | "delete";

export interface IFormElementProps {
    formId: number;
    formElement: IFormElement;
    required: boolean;
    name: string;
    value: TFormElementValue;
    setValue: React.Dispatch<React.SetStateAction<TFormElementValue>>;
    disabled: boolean;
    activeValidate: boolean;
    saveElement: (value: TFormElementValue, operation?: TFormElementSaveOperation) => void;
    isChild: boolean;
    isPrint?: boolean;
    isInvalid?: boolean;
    userType: EUserType;
    inputStyle: React.CSSProperties;

    // Hack for Browser Print
    defaultInitialFileName?: string;
}

const DEFAULT_VALUE_FIELDS = [
    EFormElementTypes.Text,
    EFormElementTypes.TextArea,
    EFormElementTypes.Date,
    EFormElementTypes.Time,
    EFormElementTypes.Email,
    EFormElementTypes.PhoneNumber,
    EFormElementTypes.TypedDate,
    EFormElementTypes.YesNo,
    EFormElementTypes.Select,
    EFormElementTypes.AnnotatedBody,
    EFormElementTypes.Body,
    EFormElementTypes.ImagePhotoOrAttachment,
    EFormElementTypes.DrawableImage,
    EFormElementTypes.Number,
    EFormElementTypes.SquarePayment,
    EFormElementTypes.StripePayment,
    EFormElementTypes.Consent,
    EFormElementTypes.ThirdPartyAPI,
];

const FORM_ANSWER_VALIDATORS: {
    [elementType: number]: (
        value: TFormElementValue,
        element: IFormElement,
        options?: { notify: boolean },
    ) => boolean;
} = {
    [EFormElementTypes.FormattedText]: (_v) => true,
    [EFormElementTypes.TextArea]: (v) => isValidTextArea(v),
    [EFormElementTypes.Checkbox]: (v) => isChecked(v),
    [EFormElementTypes.CheckboxGroup]: (v, e) => {
        if (!v) return false;
        const split = ((typeof v === "string" ? v.split(",") : v || []) as string[]).filter(
            Boolean,
        );
        const base = isValidOption(v) && !!split.length;
        if (e?.max && split.length > e.max) {
            return false;
        }
        if (e?.min && split.length < e.min) {
            return false;
        }
        return base;
    },
    [EFormElementTypes.Text]: (v) => isValidTextInput(v),
    [EFormElementTypes.Select]: (v) => isValidOption(v),
    [EFormElementTypes.Radio]: (v) => isValidOption(v),
    [EFormElementTypes.Date]: (v) => isValidDate(v),
    [EFormElementTypes.PhoneNumber]: (v) => isValidPhoneNumber(v, true),
    [EFormElementTypes.Zipcode]: (v) => isValidZipcode(v),
    [EFormElementTypes.ZipCodeCanada]: (v) => isValidZipcodeCanada(v),
    [EFormElementTypes.Email]: (v) => isValidEmail(v),
    [EFormElementTypes.SocialSecurityNumber]: (v) => isValidSSN(v),
    [EFormElementTypes.Signature]: (v) => isValidSignature(v),
    [EFormElementTypes.SignatureDate]: (v) => isValidDate(v),
    [EFormElementTypes.Time]: (v) => isValidDate(v),
    [EFormElementTypes.Initials]: (v) => isValidSignature(v),
    [EFormElementTypes.ImagePhotoOrAttachment]: (v, e, o) =>
        isValidFileUpload(v as IFileUploadProgress[], e, !!o?.notify),
    [EFormElementTypes.Photo]: (v, e, o) =>
        isValidFileUpload(v as IFileUploadProgress[], e, !!o?.notify),
    [EFormElementTypes.FileAttachment]: (v, e, o) =>
        isValidFileUpload(v as IFileUploadProgress[], e, !!o?.notify),
    [EFormElementTypes.MultipleFileUpload]: (v, e, o) =>
        isValidFileUpload(v as IFileUploadProgress[], e, !!o?.notify),
    [EFormElementTypes.YesNo]: (v) => v === "yes" || v === "no",
    [EFormElementTypes.State]: (v) => isValidOption(v),
    [EFormElementTypes.TypedDate]: (v) => isValidDate(v),
    [EFormElementTypes.Consent]: (v) => isChecked(v),
    [EFormElementTypes.AnnotatedBody]: (v) => isValidTextInput(v),
    [EFormElementTypes.Address]: (v) => isValidTextInput(v),
    [EFormElementTypes.Body]: (v) => isValidTextInput(v),
    [EFormElementTypes.PageBreak]: (_v) => true,
    [EFormElementTypes.Invisible]: (_v) => true,
    [EFormElementTypes.DrawableBody]: (v) => isValidDrawableBody(v as string),
    [EFormElementTypes.DrawableMamogram]: (v) => isValidDrawableMammogram(v as string),
    [EFormElementTypes.DrawableSignature]: (v) => isValidDrawable(v as string),
    [EFormElementTypes.DrawableInitials]: (v) => isValidDrawable(v as string),
    [EFormElementTypes.DrawableImage]: (v) => isValidDrawable(v as string),
    [EFormElementTypes.CalculatedSum]: (v) => {
        if (isNumeric(v)) {
            return isNumeric(v) && Number(v) > 0;
        } else {
            return Number((v as ISelectOption)?.value) > 0;
        }
    },
    [EFormElementTypes.ExternalAPISearch]: (v) => isValidExternalAPIResult(v),
    [EFormElementTypes.ExternalAPISelect]: (v) => isValidExternalAPIResult(v),
    [EFormElementTypes.Number]: (v) => isValidTextInput(v) && isNumeric(v),
    [EFormElementTypes.SquarePayment]: (v) => {
        try {
            return !!(v && JSON.parse(v as string)?.squarePaymentId);
        } catch (error) {
            return false;
        }
    }, // TODO: CHECK VALID
    [EFormElementTypes.StripePayment]: (v) => {
        try {
            return !!(v && JSON.parse(v as string)?.paid);
        } catch (error) {
            return false;
        }
    }, // TODO: CHECK VALID
    [EFormElementTypes.EligibilityCheck]: (v) => {
        try {
            return !!(v && (JSON.parse(v as string) as IInsuredInfo).eligible !== "");
        } catch (error) {
            return false;
        }
    },
    [EFormElementTypes.ThirdPartyAPI]: (v) => {
        try {
            return !!(
                v &&
                (JSON.parse(v as string) as IThirdPartyAPIFormValue).result?.isVerified === true
            );
        } catch (error) {
            return false;
        }
    },
    [EFormElementTypes.FormCompleteAction]: (_v) => true,
};

export const isSupportsDefaultValue = (item: IBuildFormItem | IFormElement | EFormElementTypes) => {
    if (!item) return false;
    const type =
        typeof item === "number"
            ? item
            : (item as IFormElement).elementType || (item as IBuildFormItem).type;
    return DEFAULT_VALUE_FIELDS.includes(type);
};

export const FORM_ELEMENT_SAVE_THROTTLE_AMOUNT_MS = 50;
export const IS_VALID = "is-valid";
export const IS_INVALID = "is-invalid";
export type TValidInvalid = "is-valid" | "is-invalid" | "";
export const FORM_ELEMENT_KEY_PREFIX = "fe:";
export const FORM_SELECT_KEY_PREFIX = "fs:";
export const FORM_DATE_KEY_PREFIX = "date:";
export const FORM_ELEMENT_PLACEHOLDER_PREFIX = "Enter";
export const FILE_UPLOAD_MESSAGE = "Drag a file here or click to upload";

const classNameFullWidth = (colClass: number, elementType: EFormElementTypes, isPrint: boolean) => {
    const fullWidth = [
        EFormElementTypes.ImagePhotoOrAttachment,
        // EFormElementTypes.FormattedText,
        // EFormElementTypes.TextArea,
    ];
    if (isPrint && fullWidth.includes(elementType)) {
        return "col-print-12";
    }
    return `col-print-${colClass}`;
};

export const formElementClassName = (
    colClass: number,
    elementType: EFormElementTypes,
    isPrint: boolean,
    condensed: boolean,
) => {
    return `col-sm-${isPrint && condensed && colClass > 1 ? colClass - 1 : colClass}
        ${classNameFullWidth(colClass, elementType, isPrint)} col-12`;
};

export const getFormElementKey = (formId: number | string, formElementId: number | string) => {
    return `${FORM_ELEMENT_KEY_PREFIX}${formId}:${formElementId}`;
};

export const getFormElementPlaceholder = ({ label }: { label: string }) => {
    return `${FORM_ELEMENT_PLACEHOLDER_PREFIX} ${label}`;
};

export const getFormElementValue = (
    formId: number,
    formElementAnswers: Record<number, TFormElementValue>,
    formElementId: number | string,
): TFormElementValue => {
    return get(formElementAnswers, `${formId}.${formElementId}`);
};

const isSelectableElement = (element: IFormElement) => {
    const { elementType } = element;
    return elementType === EFormElementTypes.Select || elementType === EFormElementTypes.Radio;
};

const isEmptySelectElement = (element: IFormElement) => {
    return (
        isSelectableElement(element) &&
        isEmptyObject(element.formSelectOptions) &&
        isEmptyObject(element.childFormElements)
    );
};

const IS_NEVER_REQUIRED_ELEMENT_TYPES = [
    EFormElementTypes.SignatureDate,
    EFormElementTypes.PageBreak,
    EFormElementTypes.Container,
    EFormElementTypes.Invisible,
    EFormElementTypes.Null,
    EFormElementTypes.FormattedText,
    EFormElementTypes.FormCompleteAction,
];

export const isElementRequired = (element: IFormElement, userType: EUserType) => {
    if (!element.required) return false;

    if (IS_NEVER_REQUIRED_ELEMENT_TYPES.includes(element.elementType)) {
        return false;
    }

    if (
        (element.editGroup === EEditGroups.StaffOnly ||
            element.editGroup === EEditGroups.StaffOnlyLocking) &&
        userType === EUserType.Patient
    ) {
        return false;
    }

    if (element.required && !isSelectableElement(element)) {
        return true;
    }
    if (element.required && isEmptySelectElement(element)) {
        console.error(
            "Sent user a required form element with no available options. Setting element not required.",
            element,
        );
        return false;
    }

    return element.required;
};

export const getMaxFileUploads = (formElement: IFormElement | number | undefined) => {
    const max = typeof formElement === "number" ? formElement : formElement?.max;
    return isNumeric(max) && Number(max) ? Number(max) || DEFAULT_MAX_FILES : DEFAULT_MAX_FILES;
};

export const getMinFileUploads = (min: number | undefined, required: boolean) => {
    return isNumeric(min) && Number(min) ? Number(min) : required ? 1 : 0;
};

const isValidFileUpload = (
    value: IFileUploadProgress[],
    formElement: IFormElement,
    isNotify: boolean,
): boolean => {
    if (!value) {
        // logDev("isValidFileUpload - No value - NOT VALID", formElement?.id, value);
        return false;
    }

    const minFiles = getMinFileUploads(formElement.min, formElement.required);
    const maxFiles = getMaxFileUploads(formElement);

    return validateFileUploads(value, minFiles, maxFiles, isNotify);
};

export const isValidDrawable = (value: string | IBaseDrawableValue): boolean => {
    if (!value) {
        return false;
    }

    if (typeof value === "string" && !isValidTextInput(value)) {
        return false;
    }

    try {
        const parsed: IBaseDrawableValue = typeof value === "string" ? JSON.parse(value) : value;
        const lines =
            get(parsed, "lines.signature") ||
            get(parsed, "lines.initials") ||
            get(parsed, "lines.image");

        return isValidDrawableLines(lines);
    } catch (error) {
        console.error(error);
        return false;
    }
};

const isValidDrawableBody = (value: TFormElementValue) => {
    if (!isValidTextInput(value)) {
        return false;
    }
    const parsed: IDrawableBodyValue = JSON.parse(value as string);
    return isValidDrawableLines(parsed.linesFrontBack) && isValidDrawableLines(parsed.linesSides);
};

const isValidDrawableMammogram = (value: TFormElementValue) => {
    if (!isValidTextInput(value)) {
        return false;
    }
    const parsedMamogram: IDrawableMamogramValue = JSON.parse(value as string);
    return isValidDrawableLines(parsedMamogram.linesMamogram);
};

const isValidDrawableLines = (lines: IKonvaData[] | null | undefined) => {
    if (!Array.isArray(lines) || lines.length === 0) {
        return false;
    }

    const points = flatten(lines.map((line) => line.points));
    return Array.isArray(points) && points.length > 2; // length of 2 === a single point, x-coord followed by y-coord
};

const isValidExternalAPIResult = (value: TFormElementValue): boolean => {
    try {
        return Array.isArray(JSON.parse(value as string));
    } catch (e) {
        return false;
    }
};

/**
 * Set forms with only non-required formatted-text in them
 * to 100% complete by default.
 *
 * @param {IFormElement[]} elements
 * @param {IFormElement[]} required
 * @return {*}  {boolean}
 */
export const isNoneRequired = (required: IFormElement[]): boolean => {
    return isEmptyObject(required);
};

export const getValidator = (elementType: EFormElementTypes) => {
    return FORM_ANSWER_VALIDATORS[elementType];
};

/**
 * Return the form element if it is required
 * and any required child elements, if the element has any
 *
 * @param {IFormElement} formElement
 * @param {EUserType} userType
 * @return {IFormElement[]}
 */
export const getRequiredFormElements = (
    formId: number,
    element: IFormElement,
    userType: EUserType,
    answers: Record<number, TFormElementValue>,
): IFormElement[] => {
    const initial = isElementRequired(element, userType) ? [element] : [];
    // fix
    const value = get(answers, element.id) || get(answers, `${formId}.${element.id}`);
    return dedupeById(
        (element?.childFormElements || [])?.reduce((sum: IFormElement[], child: IFormElement) => {
            if (
                child &&
                isShowChildElements(element.elementType, value, child.displayFormSelectOptions)
            ) {
                return sum.concat(getRequiredFormElements(formId, child, userType, answers));
            } else if (
                !isEmptyObject(child.childFormElements) &&
                isShowChildElements(child.elementType, value, child.displayFormSelectOptions)
            ) {
                return [...sum, child];
            } else {
                return sum;
            }
        }, initial),
    );
};

export const isOnlyFormElement = (form: IForm): boolean => {
    return (
        (form?.formTemplateVersion?.formTemplateSections || []).length === 1 &&
        form?.formTemplateVersion?.formTemplateSections?.first()?.formElements?.length === 1
    );
};

export const isFirstFormElement = (form: IForm, formElement: IFormElement): boolean => {
    return (
        (
            (form?.formTemplateVersion?.formTemplateSections || []).first()?.formElements || []
        ).first()?.id === formElement?.id
    );
};

/**
 *
 *
 * Check Form completion percentage
 *
 *
 *
 */

/**
 * Gets the value of the form element from form.answers
 *
 * @param form
 * @param formElementId
 * @returns
 */
const getFormElementValueFromForm = (
    form: IForm,
    formElementId: number | string,
): TFormElementValue => {
    return get(form?.answers, formElementId);
};

const isCompleteFormElementForm = (
    element: IFormElement,
    form: IForm,
    userType: EUserType,
): boolean => {
    const value = getFormElementValueFromForm(form, element.id);
    if (!isElementRequired(element, userType)) {
        return true;
    }

    const validator = getValidator(element.elementType);
    if (validator) {
        const isValid = validator(value, element, { notify: false });
        if (!isValid) return false;

        for (const childElement of element.childFormElements || []) {
            if (
                isShowChildElements(
                    element.elementType,
                    value,
                    childElement.displayFormSelectOptions,
                ) &&
                !isCompleteFormElementForm(childElement, form, userType)
            ) {
                return false;
            }
        }

        return isValid;
    } else {
        console.error(
            `RETURN DEFAULT TRUE. NO isCompleteFormElementForm CHECK FOR FORM ELEMENT TYPE/ID - ${element.elementType}/${element.id} in FORM - ${form.id}`,
        );
        return true;
    }
};

export const calculateCompletionPercentageForm = (
    form: IForm | undefined,
    answers: Record<string, any>,
    userType: EUserType,
) => {
    if (
        !form?.formTemplateVersion?.formTemplateSections ||
        isEmptyObject(form?.formTemplateVersion?.formTemplateSections)
    )
        return { percentage: 0, missing: 0 };

    const elements = (form.formTemplateVersion?.formTemplateSections || []).reduce((sum, s) => {
        return sum.concat(s.formElements || []);
    }, [] as IFormElement[]);

    const required: IFormElement[] = elements.reduce(
        (sum: IFormElement[], element: IFormElement) => {
            return sum.concat(getRequiredFormElements(form.id, element, userType, answers));
        },
        [],
    );

    if (isNoneRequired(required)) {
        logDev(
            "calculateCompletionPercentage - Form has NO REQUIRED elements. Returning 100% complete.",
        );
        return { percentage: 100, missing: 0 };
    }

    const formContainsElementValue = (element: IFormElement) => {
        return isCompleteFormElementForm(element, form, userType);
    };

    const completedRequired = required.filter(formContainsElementValue);

    // logDev("calculateCompletionPercentageForm.required/completedRequired", {
    //     elements,
    //     required,
    //     completedRequired,
    // });

    return {
        percentage: Math.round((completedRequired.length / required.length) * 100) || 0,
        missing: required.length - completedRequired.length,
    };
};

export const isShowChildElements = (
    parentElementType: number,
    parentElementValue: TFormElementValue,
    displayFormSelectOptions?: ISelectOption[],
): boolean => {
    if (parentElementType === EFormElementTypes.YesNo) {
        return !!(parentElementValue && (parentElementValue as string) === "yes");
    } else if (parentElementType === EFormElementTypes.Checkbox) {
        return !!(parentElementValue && getBooleanFromString(parentElementValue as string));
    } else if (
        parentElementType === EFormElementTypes.Select ||
        parentElementType === EFormElementTypes.Radio ||
        parentElementType === EFormElementTypes.CheckboxGroup
    ) {
        if (!parentElementValue) {
            return false;
        } else if (isNumeric(parentElementValue)) {
            const value = parseInt(parentElementValue as string);
            if (!displayFormSelectOptions) {
                return true;
            }
            for (const option of displayFormSelectOptions) {
                if (option.value === value) {
                    return true;
                }
            }
            return false;
        } else if (isPlainObject(parentElementValue)) {
            return isShowChildElements(
                parentElementType,
                (parentElementValue as ISelectOption).value,
                displayFormSelectOptions,
            );
        } else if (
            parentElementType === EFormElementTypes.CheckboxGroup &&
            typeof parentElementValue === "string"
        ) {
            if (!displayFormSelectOptions) return true;

            const v = parentElementValue.split(",").map(Number);
            for (const option of displayFormSelectOptions) {
                if (v.includes(Number(option.value))) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    } else {
        return true;
    }
};
