/** @format */

import { scheduling } from "lobbie";
import { ROUTES } from "src/constants/routes";
import { LOBBIE_COLORS } from "src/utils/colors";

export const IS_PRODUCTION = import.meta.env.MODE === "production";
export const IS_NOT_PRODUCTION = import.meta.env.MODE !== "production";

const hostname = window.location.hostname;
export const IS_LOBBIE_LOCAL =
    hostname === "localhost" || hostname === "127.0.0.1" || hostname.includes(".local");
export const IS_LOBBIE_DEV = hostname === "dev.lobbie.com";
export const IS_LOBBIE_DEMO = hostname === "demo.lobbie.com";
export const IS_LOBBIE_SANDBOX = hostname === "sandbox.lobbie.com";
export const IS_LOBBIE_PROD = hostname === "my.lobbie.com";
export const IS_LOBBIE_ADMIN = hostname === "admin.lobbie.com";
export const IS_LOBBIE_CYPRESS = import.meta.env.VITE_IS_CYPRESS === "1";

export const UNKNOWN = "UNKNOWN";

export const DEFAULT_ERROR_MESSAGE = "Please refresh the page and try again.";

export const IS_COMPUTER_WIDTH = window.innerWidth >= 960;
export const IS_MOBILE_PHONE: boolean = (() => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return toMatchItem.exec(window.navigator.userAgent);
    });
})();

// https://stackoverflow.com/a/26358856/6410635 - in use
// https://stackoverflow.com/a/9851769/6410635 - not used
// https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs - not used
// @ts-ignore
export const IS_BRAVE = async () => (navigator.brave && (await navigator.brave.isBrave())) || false;
export const IS_FIREFOX = navigator.userAgent.indexOf("Firefox") !== -1;
export const IS_SAFARI = navigator.userAgent.indexOf("Safari") !== -1;
export const IS_CHROME = navigator.userAgent.indexOf("Chrome") !== -1;
export const IS_EDGE_CHROMIUM = IS_CHROME && navigator.userAgent.indexOf("Edg") !== -1;

// https://stackoverflow.com/a/53210158/6410635
// export const PHONE_NUMBER_VALID_REGEX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// https://stackoverflow.com/a/55866098/6410635
export const PHONE_NUMBER_VALID_REGEX =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/; // NOSONAR

// https://stackoverflow.com/a/9682781/6410635
export const HEX_COLOR_REGEX = /^#[0-9a-f]{6}$/i;
export const LOGO_NAME_REGEX = /^\S.*$/i;

export const NAME_MAX_LENGTH = 40;

export const DEFAULT_DEBOUNCE_DELAY_MS = 500;

export const APPOINTMENT_STATUS: {
    Active: 0;
    Canceled: 1;
    NoShow: 2;
} = {
    Active: 0,
    Canceled: 1,
    NoShow: 2,
};

export const APPOINTMENT_STATUS_NAMES = {
    [APPOINTMENT_STATUS.Active]: "Active",
    [APPOINTMENT_STATUS.Canceled]: "Canceled",
    [APPOINTMENT_STATUS.NoShow]: "NoShow",
};

// https://github.com/typescript-eslint/typescript-eslint/issues/2447
// eslint-disable-next-line
export enum ERoleGroup {
    User = 1,
    Practitioner = 2,
    Admin = 3,
    SuperAdmin = 4,
    Developer = 5,
}

export const ROLE_GROUP_IDS: {
    User: 1;
    Practitioner: 2;
    Admin: 3;
    SuperAdmin: 4;
    Developer: 5;
} = {
    User: 1,
    Practitioner: 2,
    Admin: 3,
    SuperAdmin: 4,
    Developer: 5,
};

export const ROLE_GROUP_NAMES_BY_IDS = {
    1: "User",
    2: "Practitioner",
    3: "Admin",
    4: "SuperAdmin",
    5: "Developer",
} as Record<number, string>;

export const FORM_TEMPLATE_IDS: {
    NewPatientForm: 1;
    Patient: 2;
    Insurance: 3;
} = {
    NewPatientForm: 1,
    Patient: 2,
    Insurance: 3,
};

export const DEFAULT_PATIENT_SCHEDULING = {
    animate: true,
    account: null,
    appointments: null,
    trackingUuid: null,
    accountPatient: null,
    selectedAccountPatient: null,
    expiresOn: null,
    appointmentType: null,
    location: null,
    practitioner: null,
    date: null,
    time: null,
    isNotifyEarlierAppointment: false,
    email: null,
    isPhoneConfirmed: false,
    isEmailConfirmed: false,
    selfSchedulingSetting: {} as scheduling.ISelfSchedulingSetting,
    route: ROUTES.patientSchedulingApptType,
    routes: [ROUTES.patientSchedulingApptType],
    phone: "",
    homeVisitAddress: null,
} as scheduling.patient.IPatientScheduling;

export const DEFAULT_LOCATION_COLORS = {
    0: LOBBIE_COLORS.primary,
    1: LOBBIE_COLORS.primary,
    2: LOBBIE_COLORS.danger,
    3: LOBBIE_COLORS.success,
    4: LOBBIE_COLORS.secondary,
    5: LOBBIE_COLORS.warning,
};
export const LOCATION_COLORS = [
    LOBBIE_COLORS.primaryDark,
    LOBBIE_COLORS.dangerDark,
    LOBBIE_COLORS.successDark,
    LOBBIE_COLORS.secondaryDark,
    LOBBIE_COLORS.warningDark,
];

export const LOBBIE_ACCOUNT_UUID = "uuid";
export const ACCOUNT_PUBLIC_UUID = "accountPublicUuid";
// export const SELF_SCHEDULING_SETTING_PUBLIC_UUID = "selfSchedulingPublicUuid";
export const SELF_SCHEDULING_SETTING_PUBLIC_UUID = "selfSchedulingSettingPublicUuid";
export const GOOGLE_MAP_SEARCH_BASE_URL = "https://www.google.com/maps/search/?api=1&query=";

export const ONE_SECOND_IN_MS = 1000;
export const TEN_SECONDS_IN_MS = ONE_SECOND_IN_MS * 10;
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60;
export const TEN_MINUTES_IN_MS = ONE_MINUTE_IN_MS * 10;

export const OBJECT_OBJECT = "[object Object]";

// https://github.com/typescript-eslint/typescript-eslint/issues/2447
// eslint-disable-next-line
export enum EFileUploadStatus {
    NULL = 0,
    IN_PROGRESS = 1,
    SUCCESS = 2,
    ERROR = 3,
}

export * from "./api";
export * from "./appointments";
export * from "./datetimes";
export * from "./routes";
export * from "./storage";
export * from "./user";
