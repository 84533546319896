import { createSelector } from "@reduxjs/toolkit";
import { ILocation } from "lobbie";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useBrandingRedux } from "src/hooks/branding/useBrandingRedux";
import { IReduxLocationState, LOCATIONS_REDUX_STATE } from "src/hooks/locations/constants";
import { useLobbieLocationsRedux } from "src/hooks/locations/useLobbieLocations";

import { setLocation, setLocationId } from "src/redux";
import { setBranding } from "src/redux/actions/brandingActions";
import { setStoredLocationId, setStoredLocationTimeZone } from "src/utils";

const locationSelector = createSelector(
    [LOCATIONS_REDUX_STATE],
    (locations: IReduxLocationState) => {
        return locations.location;
    },
);

export const useLobbieReduxLocation = (): ILocation | undefined => {
    return useSelector(locationSelector);
};

export const useLobbieLocation = (): [
    ILocation | null,
    (newLocationId: number | undefined, newTimeZone: string | undefined) => void,
] => {
    const dispatch = useDispatch();
    const location = useSelector(locationSelector);
    const [, , fetchBranding] = useBrandingRedux();

    const { locations } = useLobbieLocationsRedux();

    const _dispatch = useCallback(
        (newLocationId: number | undefined, newTimeZone: string | undefined) => {
            if (!newLocationId) return;

            const newLocation = locations.find((l) => l.id === newLocationId);
            if (newLocation) {
                if (newTimeZone || newLocation?.timeZone) {
                    setStoredLocationTimeZone(newTimeZone || newLocation?.timeZone);
                }
                dispatch(setLocation(newLocation));
                dispatch(setLocationId(newLocation?.id));
                setStoredLocationId(newLocation?.id);
                if (newLocation?.branding && newLocation.branding.isLocationBrandingActive) {
                    dispatch(setBranding(newLocation.branding));
                } else if (newLocation?.branding) {
                    // noop - location has branding but it is not active
                } else if (newLocation?.id) {
                    fetchBranding(
                        newLocation?.id,
                        !!newLocation?.branding?.isLocationBrandingActive,
                    );
                }
            }
        },
        [dispatch, locations, fetchBranding],
    );

    return useMemo(() => {
        if (location) {
            return [location, _dispatch];
        } else {
            return [null, _dispatch];
        }
    }, [location, _dispatch]);
};
