/** @format */

import { Modal } from "react-bootstrap";
import MultiLineString from "../useForm/MultiLineString";
import LobbieSpinner from "./LobbieSpinner";

const FullScreenLoadingSpinner = ({ message }: { message?: string | string[] }) => {
    return (
        <Modal
            show={true}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            style={{
                backgroundColor: "transparent",
                textAlign: "center",
                margin: "0 auto",
            }}
            contentClassName={"transparent-modal"}
        >
            <Modal.Title>
                <MultiLineString
                    stringLines={
                        message
                            ? typeof message === "string"
                                ? [message]
                                : message
                            : ["Loading Lobbie..."]
                    }
                />
            </Modal.Title>
            <Modal.Body style={{ backgroundColor: "transparent", border: "none" }}>
                <LobbieSpinner className="m-3" />
            </Modal.Body>
        </Modal>
    );
};

export default FullScreenLoadingSpinner;
