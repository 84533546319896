import { IFileUpload, IFileUploadProgress } from "lobbie";
import { isPlainObject } from "lodash";
import { useCallback } from "react";
import { TFormElementValue } from "src/components/shared/forms/utils";
import { OBJECT_OBJECT } from "src/constants";
import { useLobbieAccountPublicUuid } from "src/hooks/accounts/accounts";
import { useAxiosPost } from "src/hooks/useAxios";
import { isFailedRequest } from "src/utils";

export type TFormFileUpload = IFileUploadProgress & { isDefault?: boolean };

export const useDefaultFormFileUpload = () => {
    const accountPublicUuid = useLobbieAccountPublicUuid();
    const { post: copyS3File } = useAxiosPost<IFileUploadProgress>("/shared/file_uploads/copy");

    const copier = useCallback(
        (s3ObjectPath: string): Promise<TFormFileUpload | void> => {
            return copyS3File({
                s3ObjectPath,
                accountPublicUuid,
            })
                .then((f) => {
                    if (f && !isFailedRequest(f)) {
                        return {
                            ...(f as IFileUploadProgress),
                            isDefault: true,
                        };
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        },
        [accountPublicUuid, copyS3File],
    );

    return useCallback(
        (defaultValue: TFormElementValue) => {
            const defaultFileUploads = parseFileUploads(defaultValue);

            return Promise.all(
                defaultFileUploads.map(async (fileUpload) => {
                    if (fileUpload.s3ObjectPath && !fileUpload.signedURL) {
                        const copied = await copier(fileUpload.s3ObjectPath);
                        if (copied) {
                            return copied;
                        }
                    } else {
                        return { ...fileUpload, isDefault: true };
                    }
                }),
            ).then((uploads) => uploads.filter(Boolean) as TFormFileUpload[]);
        },
        [copier],
    );
};

export const isImageFile = (fileUpload: IFileUpload): boolean => {
    if (fileUpload.mimeType) {
        const mime = fileUpload.mimeType.toLowerCase().trim();
        return mime.includes("image/");
    } else {
        const name = fileUpload.fileName?.toLowerCase();
        return !!(
            name &&
            (name.includes(".jpg") ||
                name.includes(".png") ||
                name.includes(".gif") ||
                name.includes(".jpeg") ||
                name.includes(".heic") ||
                name.includes(".heif"))
        );
    }
};

export const parseFileUploads = (value: TFormElementValue): IFileUploadProgress[] => {
    if (!value) return [];

    if (Array.isArray(value)) {
        return value as IFileUploadProgress[];
    } else if (isPlainObject(value)) {
        return [value as IFileUploadProgress];
    } else if (typeof value === "string") {
        try {
            if (isImageS3ObjectPath(value) || !isStringJSON(value)) {
                return [
                    {
                        s3ObjectPath: value,
                        progress: 100,
                        fileName: value.slice(value.lastIndexOf("/") + 1),
                    } as IFileUploadProgress,
                ];
            }

            const parsed = JSON.parse(value);
            if (Array.isArray(parsed)) {
                return parsed;
            } else if (isPlainObject(parsed)) {
                return [parsed];
            } else {
                return [];
            }
        } catch (error) {
            console.error(error);
            return [];
        }
    } else {
        return [];
    }
};

const isImageS3ObjectPath = (s3ObjectPath: string) => {
    return [".jpg", ".jpeg", ".png", ".gif", ".heif", ".heic"].some((suffix) =>
        s3ObjectPath?.trim()?.toLocaleLowerCase("en-US")?.endsWith(suffix),
    );
};

export const isStringJSON = (_s: string | undefined) => {
    if (!_s || !_s.trim()) return false;

    const s = _s.trim();
    if (s === OBJECT_OBJECT) {
        return false;
    }

    if (s.startsWith("{") || s.startsWith("[")) {
        return true;
    }

    try {
        JSON.parse(s);
    } catch (error) {
        return false;
    }
};
