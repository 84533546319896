/** @format */
import { ErrorMessage } from "@hookform/error-message";
import { Suspense, lazy, useCallback, useMemo, useRef } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
import LobbieErrorMessage from "src/components/shared/LobbieErrorMessage";
import { useLobbieAccountRedux } from "src/hooks/accounts/accounts";
import { useBrandingEmailReset } from "src/hooks/branding/useBrandingEmailReset";
import { handleError, pluralize, titleize } from "src/utils";
import { DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS } from "../ConfigBranding";
import { retry } from "src/utils/lazy";
import FullScreenLoadingSpinner from "src/components/shared/loading/FullScreenLoadingSpinner";

const ResetBrandingWarningModal = lazy(() => retry(() => import("./ResetBrandingWarningModal")));

interface IProps {
    getBranding: () => void;
}

const ConfigBrandingEmail: React.FC<IProps> = ({ getBranding }) => {
    const fromEmailRef = useRef<HTMLInputElement | null>(null);
    const { patientFieldName, lowerPatientFieldName } = useLobbieAccountRedux();

    const { isEmailBrandingReset, handleSetEmailBrandingReset, handleBrandingEmailReset } =
        useBrandingEmailReset({
            getBranding,
        });

    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    const watchFromEmailAddress = watch("fromEmailAddress");

    const handleChangeFromEmailAddress = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value.split("@").first();
            setValue("fromEmailAddress", `${value}@lobbie.com`);
            if (fromEmailRef?.current) {
                try {
                    // https://sentry.io/organizations/lobbie/issues/3251189358/?project=5584261
                    // Failed to execute 'setSelectionRange' on 'HTMLInputElement': The input element's type ('email') does not support selection.
                    // https://stackoverflow.com/questions/33406169/failed-to-execute-setselectionrange-on-htmlinputelement-the-input-elements
                    fromEmailRef.current.type = "text";
                    fromEmailRef.current.setSelectionRange(value.length, value.length);
                    fromEmailRef.current.type = "email";
                } catch (error) {
                    handleError(error as Error);
                }
            }
        },
        [setValue],
    );

    const renderFromEmail = useMemo(() => {
        const { ref, ...rest } = register("fromEmailAddress");

        return (
            <Form.Control
                ref={(e: any) => {
                    ref(e);
                    if (fromEmailRef) {
                        fromEmailRef.current = e;
                    }
                }}
                {...rest}
                type="email" // * WATCH: https://sentry.io/organizations/lobbie/issues/3251189358/?project=5584261
                value={watchFromEmailAddress || DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS}
                onChange={handleChangeFromEmailAddress}
            />
        );
    }, [handleChangeFromEmailAddress, register, watchFromEmailAddress]);

    return (
        <div>
            <div className="row user-select-none mb-2">
                <div className="col">
                    <h4 className="normal bold">Email Identity</h4>
                    <h6 className="gray3 bold">
                        Define how you would like emails sent from Lobbie to appear to your{" "}
                        {pluralize(lowerPatientFieldName)}:
                    </h6>
                </div>
            </div>

            <div>
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="row">
                            <div className="col">
                                <div className="bold">Practice name: *</div>
                                <div className="mt-2 gray1">
                                    {`The friendly name that ${pluralize(
                                        lowerPatientFieldName,
                                    )} see when they receive an email.`}
                                </div>
                                <div className="mt-2 gray1">
                                    {`For example a ${lowerPatientFieldName} would see "From: Taco <taco@lobbie.com>" if this was changed to "Taco" and the 'from' email address was "taco@lobbie.com"`}
                                </div>
                                <div className="mt-2 gray1">{`Defaults to: 'Lobbie'`}</div>
                                <Form.Group className="mt-2">
                                    <Form.Control
                                        {...register("fromEmailName")}
                                        type="text"
                                        name="fromEmailName"
                                    />
                                    <ErrorMessage
                                        as={LobbieErrorMessage}
                                        errors={errors}
                                        name={"fromEmailName"}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col">
                                <div className="bold">Practice email 'from' address: *</div>
                                <div className="mt-2 gray-1">
                                    {`The 'from' address that ${pluralize(
                                        lowerPatientFieldName,
                                    )} see when they receive an email.`}
                                </div>
                                <div className="mt-2 gray-1">
                                    {`For example a ${lowerPatientFieldName} would see "From: Taco <taco@lobbie.com>" if this was changed to "taco@lobbie.com" and the practice name was changed to 'Taco'."`}
                                </div>
                                <div className="mt-2 gray-1">
                                    {`Defaults to: ${DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS}`}
                                </div>
                                <Form.Group className="mt-2">
                                    {renderFromEmail}
                                    <ErrorMessage
                                        as={LobbieErrorMessage}
                                        errors={errors}
                                        name={"fromEmailAddress"}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-6">
                        <div className="row">
                            <div className="col">
                                <div className="bold">Reply-to email address: *</div>
                                <div className="mt-2 gray1">
                                    {`Whenever a ${lowerPatientFieldName} receives an email from Lobbie, if they reply to it their response will be sent to this email address.`}
                                </div>
                                <div className="mt-2 gray1">
                                    {`For example, if a ${lowerPatientFieldName} replies to an email from Lobbie and this is set to taco@taco.com, the response will be sent to taco@taco.com.`}
                                </div>
                                <div className="mt-2 gray1">Defaults to: 'noreply@lobbie.com'</div>
                                <Form.Group className="mt-2">
                                    <Form.Control
                                        {...register("replyToEmailAddress")}
                                        type="email"
                                        name="replyToEmailAddress"
                                    />
                                    <ErrorMessage
                                        as={LobbieErrorMessage}
                                        errors={errors}
                                        name={"replyToEmailAddress"}
                                    />
                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-0 py-2">
                    <div className="col-8">&nbsp;</div>
                    <div className="col-4 text-right">
                        <span>
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={handleSetEmailBrandingReset}
                            >
                                Reset Email Branding
                            </Button>
                        </span>
                    </div>
                </div>
            </div>
            {isEmailBrandingReset && (
                <Suspense fallback={<FullScreenLoadingSpinner />}>
                    <ResetBrandingWarningModal
                        showModal={isEmailBrandingReset}
                        handleModalShowHide={handleSetEmailBrandingReset}
                        handleContinue={handleBrandingEmailReset}
                        message={
                            <>
                                <div className="row">
                                    <div className="col">
                                        This will reset your email branding for all notifications
                                        throughout Lobbie.
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        {`${titleize(
                                            patientFieldName,
                                        )}s will receive emails from "Lobbie <${DEFAULT_ACCOUNT_FROM_EMAIL_ADDRESS}>"`}
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Suspense>
            )}
        </div>
    );
};

export default ConfigBrandingEmail;
