/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { setIntegrators } from "../actions/integratorActions";
import { IAppState } from "../store";

const initialState: any = {
    integrators: undefined,
};

export const integratorsReducer = createReducer(initialState, (builder) => {
    builder.addCase(setIntegrators, (_state: IAppState, action) => {
        return {
            integrators: action?.payload,
        };
    });
});
