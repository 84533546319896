/** @format */

import { createReducer } from "@reduxjs/toolkit";
import { LOBBIE_STORAGE } from "src/constants";
import { sessionSet } from "src/utils";
import { setActiveFormGroup, updateActiveFormGroupPatient } from "../actions/formGroupActions";

const INITIAL_STATE: any = {
    formGroup: {},
};

export const formGroupReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(setActiveFormGroup, (state, action) => {
            if (action.payload.id) {
                sessionSet(
                    LOBBIE_STORAGE.Session.Patients.Forms.ActiveFormGroupId,
                    action.payload.id.toString(),
                );
            }

            return {
                ...state,
                ...action.payload,
            };
        })
        .addCase(updateActiveFormGroupPatient, (state, action) => {
            return {
                ...state,
                accountPatient: {
                    ...state.accountPatient,
                    ...action.payload,
                },
            };
        });
});
