import { ActionCreatorWithPayload, createAction } from "@reduxjs/toolkit";
import { forms, patients } from "lobbie";

export const setActiveFormGroup = createAction<Partial<forms.IFormGroup> | undefined>(
    "formGroup/set",
) as ActionCreatorWithPayload<Partial<forms.IFormGroup>>;

export const updateActiveFormGroupPatient = createAction<
    Partial<patients.IAccountPatientLite> | undefined
>("formGroupPatient/update") as ActionCreatorWithPayload<Partial<patients.IAccountPatientLite>>;
