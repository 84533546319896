import { HEX_COLOR_REGEX } from "src/constants";
import * as yup from "yup";

export const CONFIG_BRANDING_VALIDATION_SCHEMA = yup.object().shape({
    fromEmailAddress: yup.string().email("Please enter a valid email 'from' address.").max(255),
    replyToEmailAddress: yup
        .string()
        .email("Please enter a valid reply-to email address.")
        .max(255),
    fromEmailName: yup
        .string()
        .max(255, "Please enter a 'from' email name of no more than 255 characters."),
    isUsingCustomLogoImage: yup.boolean(),
    logoName: yup
        .string()
        .nullable()
        .notRequired()
        .transform((_, originalValue) => {
            return !originalValue ? null : originalValue;
        })
        .when("isUsingCustomLogoImage", (isUsingCustomLogo, schema) => {
            if (
                (Array.isArray(isUsingCustomLogo) && isUsingCustomLogo.first()) ||
                isUsingCustomLogo
            ) {
                return schema.notRequired();
            } else {
                return schema.required();
            }
        })
        .max(40, "Name on the logo cannot be more than 40 characters."),
    logoCircleColor: yup
        .string()
        .nullable()
        .notRequired()
        .transform((_, originalValue) => {
            return !originalValue ? null : originalValue;
        })
        .when("isUsingCustomLogoImage", (isUsingCustomLogo, schema) => {
            if (
                (Array.isArray(isUsingCustomLogo) && isUsingCustomLogo.first()) ||
                isUsingCustomLogo
            ) {
                return schema.notRequired();
            } else {
                return schema
                    .required()
                    .matches(HEX_COLOR_REGEX, "Invalid hex color code, please try again.");
            }
        }),
    logoLetterColor: yup
        .string()
        .nullable()
        .notRequired()
        .transform((_, originalValue) => {
            return !originalValue ? null : originalValue;
        })
        .when("isUsingCustomLogoImage", {
            is: false,
            then: (schema) =>
                schema.matches(HEX_COLOR_REGEX, "Invalid hex color code, please try again."),
        }),
});
