import { useMemo } from "react";
import { FiX } from "react-icons/fi";
import { ToastOptions } from "react-toastify";
import Bootstrap5Row from "src/components/shared/elements/Bootstrap5Row";

const LobbieToast = ({
    closeToast,
    title,
    Message,
}: {
    closeToast: () => void;
    toastProps: ToastOptions;
    title: string;
    Message?: string | React.ReactNode;
}) => {
    const renderMessage = useMemo(() => {
        if (!Message) {
            return null;
        } else if (typeof Message === "string") {
            return (
                <div className="row">
                    <div className="col">{Message}</div>
                </div>
            );
        } else {
            return Message;
        }
    }, [Message]);

    return (
        <div className="col" id="lobbie-toast">
            <Bootstrap5Row className="justify-content-between">
                <div className="bold" style={{ fontSize: 15 }}>
                    {title}
                </div>
                {!!closeToast && (
                    <div>
                        <FiX size="1.5em" />
                    </div>
                )}
            </Bootstrap5Row>
            {renderMessage}
        </div>
    );
};

export default LobbieToast;
