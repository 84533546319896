/** @format */

import { createReducer } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { EUserType } from "src/constants";
import { dedupeBy, logDev, setUserStorage } from "src/utils";
import {
    addFormElementAnswer,
    resetForm,
    setForm,
    setFormAnonymousAccountPatient,
    setFormElementAnswers,
    setFormStorageKey,
    setFormTemplateSectionIndex,
    setFormTemplateSectionScrolledId,
    updateFormElementAnswerArray,
} from "../actions/formActions";

const INITIAL_STATE: any = {
    formElementAnswers: {},
};

export const formReducer = createReducer(INITIAL_STATE, (builder) => {
    builder
        .addCase(resetForm, (_state, _action) => {
            return {
                form: undefined,
                formElementAnswers: {},
                storageKey: undefined,
                anonymousAccountPatient: undefined,
                formTemplateSectionIndex: undefined,
                formTemplateSectionScrolledId: undefined,
            };
        })
        .addCase(setForm, (state, action) => {
            return {
                ...state,
                form: action.payload,
            };
        })
        .addCase(addFormElementAnswer, (state, action) => {
            return {
                ...state,
                formElementAnswers: {
                    ...state.formElementAnswers,
                    [action.payload.formId]: {
                        ...state.formElementAnswers[action.payload.formId],
                        [action.payload.formElementId]: action.payload.value,
                    },
                },
            };
        })
        .addCase(updateFormElementAnswerArray, (state, action) => {
            return {
                ...state,
                formElementAnswers: {
                    ...state.formElementAnswers,
                    [action.payload.formId]: {
                        ...state.formElementAnswers[action.payload.formId],
                        [action.payload.formElementId]: Array.isArray(
                            state.formElementAnswers[action.payload.formId],
                        )
                            ? dedupeBy(
                                  [
                                      ...state.formElementAnswers[action.payload.formId],
                                      ...action.payload.value,
                                  ],
                                  "s3ObjectPath",
                              )
                            : action.payload.value,
                    },
                },
            };
        })
        .addCase(setFormElementAnswers, (state, action) => {
            return {
                ...state,
                formElementAnswers: action.payload,
            };
        })
        .addCase(setFormStorageKey, (state, action) => {
            logDev("formReducer.setFormStorageKey - settings new storageKey -", action.payload);
            return {
                ...state,
                storageKey: action.payload,
            };
        })
        .addCase(setFormAnonymousAccountPatient, (state, action) => {
            if (action?.payload) {
                const now = dayjs();
                setUserStorage(EUserType.Patient, action.payload.id || Number.MAX_SAFE_INTEGER, {
                    defaultTTL: now.add(3, "hours").diff(now),
                });
            }

            return {
                ...state,
                anonymousAccountPatient: action.payload,
            };
        })
        .addCase(setFormTemplateSectionIndex, (state, action) => {
            return {
                ...state,
                formTemplateSectionIndex: action.payload,
            };
        })
        .addCase(setFormTemplateSectionScrolledId, (state, action) => {
            return {
                ...state,
                formTemplateSectionScrolledId: action.payload,
            };
        });
});
