import { createSelector } from "@reduxjs/toolkit";
import { IPerson } from "lobbie";
import { useSelector } from "react-redux";
import { IAppState } from "src/redux";

const userState = (state: IAppState) => state?.user;

const userSelector = createSelector(
    [userState],
    (user: { user: IPerson | undefined }) => user.user,
);

export const useReduxUser = () => {
    return useSelector(userSelector);
};
