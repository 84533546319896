import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BASE_AUTH_API_ROUTE } from "src/constants";
import { useAxiosPublicPost } from "src/hooks/useAxios";
import { useThrottleAsyncFunction } from "src/hooks/useThrottle";
import { setLocation, setLocationId, setLocations } from "src/redux";
import { setAccount } from "src/redux/actions/accountActions";
import {
    setForm,
    setFormAnonymousAccountPatient,
    setFormElementAnswers,
    setFormStorageKey,
} from "src/redux/actions/formActions";
import { setUser } from "src/redux/actions/userActions";
import { handleError, logDev, removeAllUserSessionData } from "src/utils";

const useClearFrontendOnLogout = (isClearAllStorage?: boolean) => {
    const dispatch = useDispatch();
    return useCallback(() => {
        removeAllUserSessionData(isClearAllStorage);

        dispatch(setUser(undefined));
        dispatch(setAccount(undefined));
        dispatch(setLocation(undefined));
        dispatch(setLocationId(undefined));
        dispatch(setLocations([]));
        dispatch(setForm(undefined));
        dispatch(setFormElementAnswers({}));
        dispatch(setFormStorageKey(undefined));
        dispatch(setFormAnonymousAccountPatient(undefined));
    }, [dispatch, isClearAllStorage]);
};

export const useLogoutNoRedirect = (isClearAllStorage?: boolean) => {
    const poster = useAxiosPublicPost();
    const clear = useClearFrontendOnLogout(isClearAllStorage);
    const logout = useCallback(async () => {
        logDev("useLogoutNoRedirect.logout");
        return poster(`${BASE_AUTH_API_ROUTE}/logout`, {})
            .then(() => clear())
            .catch((error) => {
                handleError(error);
                clear();
            });
    }, [poster, clear]);
    return useThrottleAsyncFunction(logout, 1000);
};

export const useLogoutUser = (redirectRoute = "/", isClearAllStorage?: boolean) => {
    const poster = useAxiosPublicPost();
    const clear = useClearFrontendOnLogout(isClearAllStorage);
    const logout = useCallback(() => {
        logDev("useLogoutUser.logout");
        return poster(`${BASE_AUTH_API_ROUTE}/logout`, {})
            .then(() => {
                logDev("useLogoutUser - THEN Remove logged-in user cookie and session data.");
                clear();
                window.location.pathname = redirectRoute;
            })
            .catch((e) => {
                console.error(e);
                logDev("useLogoutUser - CATCH Remove logged-in user cookie and session data.");
                clear();
                window.location.pathname = redirectRoute;
            });
    }, [poster, redirectRoute, clear]);

    return [useThrottleAsyncFunction(logout, 1000)];
};

export const useLogoutOnPageClose = (isClearAllStorage?: boolean) => {
    const [logout] = useLogoutUser("", isClearAllStorage);
    const [logoutEVV] = useLogoutUser("/evv", isClearAllStorage);

    useEffect(() => {
        if (window.location.pathname.startsWith("/evv/")) {
            logDev(
                "useLogoutOnPageClose.useEffect - setting log out of EVV when this window/tab is closed.",
            );
            window.addEventListener("unload", logoutEVV);
            window.addEventListener("beforeunload", logoutEVV);
            return () => {
                window.removeEventListener("unload", logoutEVV);
                window.removeEventListener("beforeunload", logoutEVV);
            };
        } else {
            logDev(
                "useLogoutOnPageClose.useEffect - setting log out when this window/tab is closed.",
            );
            window.addEventListener("unload", logout);
            window.addEventListener("beforeunload", logout);
            return () => {
                window.removeEventListener("unload", logout);
                window.removeEventListener("beforeunload", logout);
            };
        }
    }, [logout, logoutEVV]);
};
