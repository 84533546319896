import { AxiosResponse } from "axios";
import { IBranding } from "lobbie";
import { useCallback, useState } from "react";
import { useLobbieLocationId } from "src/hooks/locations/useLobbieLocationId";
import { useAxiosGetter, useAxiosPoster } from "src/hooks/useAxios";
import { useIsSuperAdmin } from "src/hooks/user";
import { handleError } from "src/utils";

export const useBranding = (): {
    isLoading: boolean;
    getBranding: (isLocationBrandingActive: boolean) => Promise<IBranding | void>;
    createUpdateBranding: (newBranding: IBranding, toggle?: boolean) => Promise<IBranding | void>;
} => {
    const isSuperAdmin = useIsSuperAdmin();
    const getter = useAxiosGetter();
    const poster = useAxiosPoster();
    const locationId = useLobbieLocationId();
    const [isLoading, setLoading] = useState<boolean>(false);

    const getBranding = useCallback(
        async (isLocationBrandingActive: boolean) => {
            if (!locationId) return;

            setLoading(true);
            const route = isSuperAdmin
                ? `/admin/branding?locationId=${locationId}&isLocationBrandingActive=${isLocationBrandingActive}`
                : `/staff/branding?locationId=${locationId}&isLocationBrandingActive=${isLocationBrandingActive}`;

            return getter(route)
                .then((response: AxiosResponse | void) => {
                    setLoading(false);
                    return response && (response.data as IBranding);
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [getter, isSuperAdmin, locationId],
    );

    const createUpdate = useCallback(
        (data: IBranding) => {
            setLoading(true);
            const route = isSuperAdmin ? "/admin/branding/create" : "/staff/branding/create";

            return poster(route, data as Record<string, any>)
                .then((response: AxiosResponse | void) => {
                    setLoading(false);
                    const result = response && (response.data as IBranding);
                    if (result) {
                        return result;
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    handleError(e);
                });
        },
        [poster, isSuperAdmin],
    );

    return {
        isLoading,
        getBranding,
        createUpdateBranding: createUpdate,
    };
};
