/** @format */

export const API_PREFIX = "api";

const API_PUBLIC = "public";
const API_SECURE = "secure";
const API_ACTION = "action";
const API_AUTH = "authentication";

const API_VERSION = "v1";
const API_NO_AUTH_ACTION = "action";

// const PORT = 8500; // nginx/haproxy
const PORT = import.meta.env.VITE_IS_CYPRESS === "1" ? 8843 : 8443; // tomcat
// const PORT = 8444;

export const BASE_API_URL =
    import.meta.env.VITE_BASE_API_ROUTE ||
    ({
        localhost: `https://localhost:${PORT}/lobbie`,
        "dev.lobbie.com": "https://api-dev.lobbie.com/lobbie",
        "dev-admin.lobbie.com": "https://api-dev.lobbie.com/lobbie",
        "demo.lobbie.com": "https://api-demo.lobbie.com/lobbie",
        "sandbox.lobbie.com": "https://api-sandbox.lobbie.com/lobbie",
        "sandbox-admin.lobbie.com": "https://api-sandbox.lobbie.com/lobbie",
        "my.lobbie.com": "https://api-prod.lobbie.com/lobbie",
        "admin.lobbie.com": "https://api-prod.lobbie.com/lobbie",
    }[window.location.hostname] as string);

export const BASE_PUBLIC_API_ROUTE = `${API_PREFIX}/${API_PUBLIC}/${API_VERSION}`;
export const BASE_SECURE_ROUTE = `${API_PREFIX}/${API_SECURE}/${API_VERSION}`;
export const BASE_SHARED_SECURE_ROUTE = `${BASE_API_URL}/${BASE_SECURE_ROUTE}`;

export const BASE_ACTION_API_ROUTE = `${API_PREFIX}/${API_ACTION}`;
export const BASE_AUTH_API_ROUTE = `${API_PREFIX}/${API_AUTH}`;
export const BASE_NO_AUTH_API_ROUTE = `${API_PREFIX}/${API_NO_AUTH_ACTION}`;

export const SUPERADMIN_DOMAINS = [
    "admin.lobbie.com",
    "dev-admin.lobbie.com",
    "sandbox-admin.lobbie.com",
    "localhost:3003",
];

export const OAUTH_ROUTE_BASE = {
    localhost: "auth-dev.lobbie.com",
    "dev.lobbie.com": "auth-dev.lobbie.com",
    "dev-admin.lobbie.com": "auth-dev.lobbie.com",
    "demo.lobbie.com": "auth-demo.lobbie.com",
    "sandbox.lobbie.com": "auth-sandbox.lobbie.com",
    "sandbox-admin.lobbie.com": "auth-sandbox.lobbie.com",
    "my.lobbie.com": "auth.lobbie.com",
    "admin.lobbie.com": "auth.lobbie.com",
}[window.location.hostname as string];
