import { IPlainObject } from "lobbie";

interface IProps {
    stringLines?: string[];
    style?: IPlainObject;
    className?: string;
}

const MultiLineString: React.FC<IProps> = ({ stringLines, style, className }) => {
    return (
        <div className="col">
            {stringLines?.map((stringLine, index: number) => {
                return (
                    <div className={className} style={style} key={index}>
                        {stringLine}
                    </div>
                );
            })}
        </div>
    );
};

export default MultiLineString;
