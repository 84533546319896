import { createSelector } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IBranding } from "lobbie";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAxiosGetter } from "src/hooks/useAxios";
import { useIsSuperAdmin } from "src/hooks/user";
import { IAppState } from "src/redux";
import { setBranding } from "src/redux/actions/brandingActions";
import { handleError } from "src/utils";

const state = (s: IAppState) => {
    return s;
};

const brandingSelector = createSelector(state, (s) => {
    return s?.branding;
});

export const useBrandingRedux = (): [
    IBranding,
    (newBranding: IBranding) => void,
    (lid: number, active: boolean) => void,
] => {
    const dispatch = useDispatch();
    const getter = useAxiosGetter();
    const isSuperAdmin = useIsSuperAdmin();

    const dispatchBranding = useCallback(
        (newBranding: IBranding) => {
            dispatch(setBranding(newBranding));
        },
        [dispatch],
    );

    const fetchBranding = useCallback(
        (locationId: number, isLocationBrandingActive: boolean) => {
            const route = isSuperAdmin
                ? `/admin/branding?locationId=${locationId}&isLocationBrandingActive=${isLocationBrandingActive}`
                : `/staff/branding?locationId=${locationId}&isLocationBrandingActive=${isLocationBrandingActive}`;

            return getter(route)
                .then((response: AxiosResponse | void) => {
                    const result = response && (response.data as IBranding);
                    if (result) {
                        dispatch(setBranding(result));
                    }
                })
                .catch((e) => {
                    handleError(e);
                });
        },
        [isSuperAdmin, getter, dispatch],
    );

    return [useSelector(brandingSelector), dispatchBranding, fetchBranding];
};
