import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {
    className?: string;
    style?: React.CSSProperties;
}

/**
 * https://stackoverflow.com/questions/71409670/why-are-my-buttons-full-width-after-a-bootstrap-5-migration
 */
const Bootstrap5Row: React.FC<IProps> = ({ children, ...props }) => {
    const { className, ...rest } = props;
    return (
        <div className={`col d-flex flex-row align-items-center ${className || ""}`} {...rest}>
            {children}
        </div>
    );
};

export default Bootstrap5Row;
