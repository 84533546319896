import { AxiosResponse } from "axios";
import { IValidationResult } from "lobbie";
import { useCallback, useState } from "react";
import { useAxiosPutter } from "src/hooks/useAxios";
import { useIsSuperAdmin } from "src/hooks/user";
import { handleError, isFailedRequest, notify } from "src/utils";

interface IProps {
    getBranding: () => void;
}

export const useBrandingEmailReset = ({ getBranding }: IProps) => {
    const isSuperAdmin = useIsSuperAdmin();
    const putter = useAxiosPutter();

    const [isEmailBrandingReset, setEmailBrandingReset] = useState<boolean>(false);
    const handleSetEmailBrandingReset = useCallback(() => {
        setEmailBrandingReset((current) => !current);
    }, []);

    const handleBrandingEmailReset = useCallback(() => {
        const route = isSuperAdmin ? "/admin/branding/email/reset" : "/staff/branding/email/reset";
        putter(route, {})
            .then((response: AxiosResponse | void) => {
                const result = response && (response.data as IValidationResult);
                if (!result) {
                    response?.status &&
                        response.status > 399 &&
                        notify({
                            level: "error",
                            title: "Error resetting email branding.",
                        });
                } else if (isFailedRequest(result)) {
                    notify({
                        level: "error",
                        title: "Failed to reset email branding.",
                        message: result.message || "",
                    });
                } else {
                    notify({
                        level: "success",
                        title: "Reset email branding.",
                        message: result.message || "",
                    });
                    getBranding();
                }
            })
            .catch(handleError);
    }, [getBranding, isSuperAdmin, putter]);

    return {
        isEmailBrandingReset,
        handleSetEmailBrandingReset,
        handleBrandingEmailReset,
    };
};
